import React from 'react';
import {Profissional} from "../../../../../DAO/clienteDAO";
import InputText from "../../../../../assets/component/inputText/input";
import moment from "moment";
import LabelSelect from "../../../../../assets/component/LabelSelect/LabelSelect";
import Button from "../../../../../assets/component/button/button";
import HorarioAtendimento from "./HorarioAtendimento/HorarioAtendimento";
import {UserPasswordAuthProviderClient} from "mongodb-stitch-browser-sdk";
import {generateOptions, onSubmitDados } from './utils';
import ModalTrocarFoto from "../ModalTrocarFoto/ModalTrocarFoto";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux/storeTypes";

interface Props {
    profissional: Profissional,
    isUserLogged?: boolean,
}

const BoxProfissional: React.FC<Props> = ({ profissional, isUserLogged }) => {

    const [loading, setLoading] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [bio, setBio] = React.useState('');

    const { mongoClient } = useSelector((state : RootState) => state.general);
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            <ModalTrocarFoto show={showModal} close={() => setShowModal(false)} />
            <div className={'configuracoes_body'}>
            <form onSubmit={(e) => onSubmitDados(e, setLoading, profissional, isUserLogged, dispatch)}>
                <div>
                    <div
                        onClick={() => setShowModal(true)}
                        className={'foto_changer'}
                        style={{
                            backgroundImage: 'url(' + profissional.foto_url + ')',
                        }}>
                        <div>Trocar Foto</div>
                    </div>
                    <InputText
                        required
                        name={'nome'}
                        placeholder={"Seu nome completo para registro."}
                        style={{ flexGrow: 1.5, marginRight: 15 }}
                        defaultValue={profissional.nome}
                        label={'Nome Completo'}
                    />
                    <InputText
                        name={'nomeProfissional'}
                        placeholder={"O nome que aparecerá para os pacientes."}
                        style={{ flexGrow: 1.5, marginRight: 15 }}
                        defaultValue={(profissional.nomeProfissional === null ||
                            profissional.nomeProfissional === '' ) ? profissional.nomeProfissional : ""}
                        label={'Nome Profissional'}
                    />
                    <InputText
                        name={'dataNascimento'} //@ts-ignore
                        defaultValue={moment(new Date(profissional.dataNascimento))
                            .add(4, 'hours')
                            .format('YYYY-MM-DD')}
                        type={'date'}
                        style={{ flexGrow: 0.3, marginRight: 15 }}
                        label={'Data de Nascimento'}
                    />
                    <LabelSelect
                        label={'Profissão'}
                        style={{ flexGrow: 1.3, marginRight: 15 }}
                        options={generateOptions()}
                    />
                    <InputText
                        name={'registro'}
                        defaultValue={profissional.registro}
                        label={'Nº de Registro'}
                        style={{ flexGrow: 0.3, marginRight: 15 }}
                    />
                </div>
                <div className={'more_data'}>
                    <InputText
                        name={'cpf'}
                        defaultValue={profissional.cpf}
                        style={{ flexGrow: 0.5, marginRight: 15 }}
                        label={'CPF'}
                    />
                    <InputText
                        name={'telefone'}
                        defaultValue={profissional.telefone}
                        style={{ flexGrow: 0.5, marginRight: 15 }}
                        label={'Telefone'}
                    />
                    <InputText
                        name={'endereco'}
                        defaultValue={profissional.endereco}
                        style={{ flexGrow: 2, marginRight: 15 }}
                        label={'Endereço'}
                    />
                    <InputText
                        name={'cep'}
                        defaultValue={profissional.cep}
                        label={'CEP'}
                        style={{ flexGrow: 0.5, marginRight: 15 }}
                    />
                </div>
                <h2>Mídias Sociais</h2>
                <div className={'more_data'}>
                    <InputText
                        name={'instagram'}
                        style={{ marginRight: 15 }}
                        decoration={"@"}
                        defaultValue={profissional.instagram}
                        label={'@ do Instagram'}
                    />
                    <InputText
                        name={'facebook'}
                        style={{ marginRight: 15 }}
                        defaultValue={profissional.facebook}
                        label={'@ do Facebook'}
                    />
                    <InputText
                        name={'whatsapp'}
                        defaultValue={profissional.whatsapp}
                        label={'WhatsApp'}
                        decoration={"+55"}
                        style={{ flexGrow: 0.2, marginRight: 15 }}
                        placeholder={'9290000-0000'}
                    />
                    <InputText
                        name={'url'}
                        defaultValue={profissional.url_site}
                        label={'URL para o Site'}
                        style={{ flexGrow: 0.4 }}
                        placeholder={'Exemplo: marcelalobo'}
                    />
                </div>
                {/*<h2>Alterar Bio</h2>
          <p>
            Sua Bio é seu currículo, você pode incluir o que você faz, sua
            formação acadêmica, e quanto tempo de experiência na área que você
            atua.
          </p>
          <p style={{marginBottom: 0}}>
            <textarea
              name={'bio'}
              defaultValue={profissional.descricao}
              required
              onChange={(e) => setBio(e.target.value)}
              maxLength={300}
              placeholder={'Crie sua nova Biografia aqui.'}
              style={{ width: '100%', height: 45, padding: 15 }}
            />
            <p style={{marginTop: 0}}><span style={{fontSize: 13, color: '#AAA'}}>{300 - bio.length}</span></p>
          </p> */}
                <h2>Alterar Descrição</h2>
                <p>
                    Sua Descrição fica na segunda página, aqui você coloca coisas como sua área de atuação,
                    seus alcances e quem você ajuda.
                </p>
                {/* <InputText
            required
            name={'titulo'}
            defaultValue={profissional.titulo}
            label={'Título'}
            style={{ flexGrow: 0.3, marginRight: 15 }}
          /> */}
                <p style={{ marginBottom: 0 }}>
            <textarea
                name={'descricao'}
                defaultValue={profissional.descricao}
                onChange={(e) => setBio(e.target.value)}
                maxLength={300}
                placeholder={'Sua nova descrição vai aqui.'}
                style={{ width: '100%', height: 45, padding: 15 }}
            />
                    <p style={{ marginTop: 0 }}><span style={{ fontSize: 13, color: '#AAA' }}>{300 - bio.length}</span></p>
                </p>
                <Button loading={loading} text={'Confirmar'} width={'250px'} />
            </form>
            <h2 className={"horarios_text"}>Disponibilidade de Horários para Atendimento</h2>
            <HorarioAtendimento profissional={profissional} />
            <form
                onSubmit={async (e) => {
                    e.preventDefault();
                    await mongoClient.auth
                        .getProviderClient(UserPasswordAuthProviderClient.factory)
                        .sendResetPasswordEmail(profissional.email);
                    alert(
                        'Por favor, cheque seu e-mail, enviamos um link para redefinição de senha.'
                    );
                }}
            >
                <h2>Recuperar Senha</h2>
                <p>
                    Enviaremos um e-mail para seu e-mail cadastrado para redefinir a sua
                    senha.
                </p>
                <Button loading={loading} text={'Enviar e-mail'} width={'350px'} />
            </form>
        </div>
        </React.Fragment>
    );
};

export default BoxProfissional;
