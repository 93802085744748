import React from 'react';
import ClienteTopbar from "../../../assets/component/cliente_topbar/topbar";
import ProximosAgendamentos from "./components/ProximosAgendamentos/ProximosAgendamentos";
import "./Dashboard.sass"
import AlternatingTab from "../../../assets/component/alternating_tab/alt_tab";
import Creditos from "./components/Creditos/Creditos";
import ChartAgendamentos from "./components/ChartAgendamentos/ChartAgendamentos";
import {connect, useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/storeTypes";
import moment from "moment";
import reservaDAO from "../../../DAO/reservaDAO";
import salaDAO from "../../../DAO/salaDAO";
import Actions, {ActionsFn} from "../../../redux/actions/actions";
import clienteDAO from "../../../DAO/clienteDAO";
import sala_bloqueioDAO from "../../../DAO/sala_bloqueioDAO";
import NotificacaoDAO from "../../../DAO/NotificacaoDAO";
import {sortSalas} from "../agendamentos";
import {Redirect} from "react-router";
import Snack from "../../../assets/component/Snack/snack";

const DashboardPage = (props) => {

    const [selectedTab, selectTab] = React.useState(2);
    const {userLogged, mongoClient} = useSelector((state: RootState) => state.general);
    const d = useDispatch();

    const watchItems = () => {
        const streamPromise = props.database.collection('reservas').watch();
        const getStream = () => streamPromise;
        const closeStream = () => streamPromise.then((stream) => stream.close);
        return [getStream, closeStream];
    }

    React.useEffect(() => {
        if (props.database) {
            const [getStream, closeStream] = watchItems();
            getStream().then((stream) => { stream.onNext(() => { getReservasFromDb() })});
            return closeStream;
        }
    }, [props.database]);



    const getReservasFromDb = () => {
        let today = moment();
        console.time("Get in client");
        Promise.all([
            reservaDAO
                .findQuery({data: {$gt: today.subtract(20, "days").toDate()}}),
            salaDAO.findAll()])
            .then(([reservas, salas]) => {
                d({type: Actions.setFetchedAgendamentos, payload: true});
                for (let i = 0; i < reservas.length; i++) {
                    reservas[i]["sala"] = salas.find(sala => sala._id.toString() === reservas[i].sala_id.toString());
                }
                props.setAgendamentos(reservas)
                props.setProfissionalReservas(
                    reservaDAO.findReservaDeCliente(props.userLogged._id, reservas)
                );
                console.timeEnd("Get in client");
            })
            .catch((e) => {
                alert(e);
            });
    }

    if (userLogged) {
        return (
            <div className={"dashboard_cliente_container"}>
                <ClienteTopbar/>
                <div className={"info_container"}>
                    <AlternatingTab selectedIndex={selectedTab} elements={[
                        { name: 'Reserva por dia',
                            onClick: () => selectTab(0)},
                        {
                            name: 'Minhas Reservas',
                            onClick: () => selectTab(1),
                        }, {
                            name: "Relatórios",
                            onClick: () => selectTab(2),
                        }]}/>
                    <Snack/>
                </div>

                <div className={"dashboard_content"}>
                    <div className={"left"}>
                        <ProximosAgendamentos/>
                        <ChartAgendamentos />
                    </div>
                    <div className={"right"}>
                        <Creditos />
                    </div>
                </div>
            </div>
        );
    } else { return <Redirect to={"/"} /> }

};

const mapStateToProps = (state) => ({
    showModal: state.general.showModal,
    modalType: state.general.modalType,
    salas: state.salas.salas,
    database: state.general.database,
    client: state.general.mongoClient,
    userLogged: state.general.userLogged,
    agendamentos: state.agendamentos.agendamentos,
    salaBloqueios: state.salas.salaBloqueios,
});

const mapDispatchToProps = (dispatch) => ({
    openModal: (open) => dispatch({type: Actions.showModal, payload: open}),
    closeModal: () => dispatch({type: Actions.closeModal}),
    setSalas: (salas) => dispatch({type: Actions.setSalas, payload: salas}),
    selectDate: (date) => dispatch({type: Actions.selectDate, payload: date}),
    setBloqueiosSalas: (bloqueios) =>
        dispatch({type: Actions.setBloqueiosSalas, payload: bloqueios}),
    selectSala: (sala) => dispatch({type: Actions.selectSala, payload: sala}),
    setAgendamentos: (agendamentos) =>
        dispatch({type: Actions.setAgendamentos, payload: agendamentos}),
    setProfissionalReservas: (reservas) =>
        dispatch({type: Actions.setProfissionalReservas, payload: reservas}),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardPage);
