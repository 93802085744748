import clienteDAO from "../../../DAO/clienteDAO";
import {ActionsFn} from "../../../redux/actions/actions";

export const updateAndGetProfissionais = (profissionalSelected, bloqueadoTarde, bloquearVisualizacao, dispatch) => {
    clienteDAO.editCliente(profissionalSelected._id, {
        bloqueadoTarde: bloqueadoTarde,
        bloquearVisualizacao: bloquearVisualizacao
    }).then(r => {
        clienteDAO.findAll().then(clientes => {
            dispatch(ActionsFn.setProfissionais(clientes))
            dispatch(ActionsFn.selectProfissional(clienteDAO.getProfissionalById(clientes, profissionalSelected._id)));
        })
    });
}
