import * as React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { ActionsFn } from '../../../../../redux/actions/actions';
import { RootState } from '../../../../../redux/storeTypes';
import "./BirthdayCard.sass";

const BirthdayCard = () => {

    const { userLogged, birthdayOK } = useSelector((state: RootState) => state.general);

    const dispatch = useDispatch();

    return(
        <div className={birthdayOK ? "birthday_card hidden" : "birthday_card" }>
            <img src={require('./birthday_pic.png')} alt="">
            </img>
            <div>
                <h1>Feliz Aniversário, {userLogged.nome.split(' ')[0]}</h1>
                <h3>A equipe da Integra lhe deseja mil felicidades e que sua vida seja repleta de sucesso!</h3>
                <button onClick={() => {
                    dispatch(ActionsFn.setBirthdayOK(true))
                }}>Obrigado(a)! ❤</button>
            </div>
        </div>
    )
}

export default BirthdayCard;