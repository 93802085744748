import axios from "axios";

const COLLECTION = "logs";

const logDAO = {
  db: null,
  AWSAccess: false,
  setAccess(access) {
    this.AWSAccess = access;
  },
  setDb(db) {
    this.db = db;
  },
  create(log) {
    if (this.AWSAccess) {
      return axios.post(
        process.env.REACT_APP_AWS_SIDEBRIDGE + "log/create",
        log
      );
    } else {
      return this.db.collection(COLLECTION).insertOne(log);
    }
  },
  async findAll() {
    console.time("pegar logs e ordenar");
    let logs = [];
    if (this.AWSAccess) {
      const { data } = await axios.post(
        process.env.REACT_APP_AWS_SIDEBRIDGE + "log/find_all"
      );
      logs = data;
    } else {
      logs = await this.db
        .collection(COLLECTION)
        .find({}, { sort: { data_hora: -1 } })
        .toArray();
    }
    console.timeEnd("pegar logs e ordenar");
    return logs;
  },
};
export default logDAO;
