import React from 'react';

interface Props {profissionais, agendamentos, administradores, agendamentosTotais, agendamentosPendentes}

const QuickDetails: React.FC<Props> = ({profissionais, agendamentos,
                                           administradores, agendamentosTotais, agendamentosPendentes}) => {
    return (
        <div className={'quickdetails_container'}>
            <h2>Informações</h2>
            <h3>{profissionais.length} Novos Profissionais</h3>
            <h3>{agendamentos.length} agendamentos</h3>
            <h3>
                {profissionais.length + administradores.length} usuários
                cadastrados
            </h3>
            <h3>{agendamentosPendentes.length} agendamentos até o momento</h3>
            <h3>5 pacientes para profissionais</h3>
            <h3>{agendamentos
                .filter(agendamento => agendamento.cancelado)
                .length} cancelamentos</h3>
            <h3>Baixar via JSON. &nbsp;
                <a
                    href={`data:text/json;charset=utf-8,${encodeURIComponent(
                        JSON.stringify(agendamentos)
                    )}`}
                    download="filename.json"
                >
                    {`(Backup).`}
                </a>
                &nbsp;
                <a
                    href={`data:text/json;charset=utf-8,${encodeURIComponent(
                        JSON.stringify(agendamentosTotais)
                    )}`}
                    download="filename.json"
                >
                    {`(Atual).`}
                </a>
            </h3>
        </div>
    );
};

export default QuickDetails;
