import React, {useRef} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/storeTypes";
import LeadDAO, {Lead} from "../../../../DAO/LeadDAO";
import moment from "moment";
import {adms} from "../../financeiro/Financeiro";
import Select from "react-select";
import clienteDAO from '../../../../DAO/clienteDAO';
import {onChangeEstado, onChangeProfissional} from "./utils";

interface LeadProps {
    lead: Lead;
    setLeads: any;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const LeadComponent : React.FC<LeadProps> = ({ lead, setLeads}) => {

    const { userLogged } = useSelector((state: RootState) => state.general);
    const { profissionais } = useSelector((state: RootState) => state.profissionais);
    const [firstUpdate, firstUpdate1] = [useRef(true), useRef(true)];

    const options = [{value: "Em atendimento", label: "Em atendimento"},
        {value: "abandonado", label: "Abandonado"},
        {value: "convertido", label: "Convertido"},
        {value: "pendente", label: "Pendente"}];

    const [estadoAtual, setEstadoAtual] = React.useState(lead.estado);
    const [profissionalAssignado, setProfissionalAssignado] = React.useState(lead.profissional_id?.toString());

    React.useEffect(() => onChangeEstado(firstUpdate, lead, estadoAtual, setLeads), [estadoAtual]);
    React.useEffect(() => onChangeProfissional(firstUpdate1, lead, profissionalAssignado, setLeads), [profissionalAssignado]);

    const colorScheme = (type : "pendente" | "Em atendimento" | "abandonado" | "convertido") => {
        return " "+type.replace(" ", "");
    }

    return (<div className={"lead" + colorScheme(lead.estado)}>
        <div>
            <h2>{lead.nome}</h2>
            <p>{lead.tipo}</p>
            <p>{lead.data ? moment(lead.data).format("DD/MM/YYYY HH:mm") : <i>Data Desconhecida</i>}</p>
        </div>
        <div>
            <h4 style={{marginBottom: 0}}>{lead.email}</h4>
            <h4 style={{marginTop: 0}}>{lead.telefone}</h4>
        </div>
        <div style={{marginRight: 24}}>
            {/* @ts-ignore */ }
            <Select defaultValue={profissionalAssignado} value={{value: profissionalAssignado, label:
                        clienteDAO.getProfissionalById(profissionais, profissionalAssignado) ?
                    clienteDAO.getProfissionalById(profissionais, profissionalAssignado).nome : ""}}
                placeholder={"Selecionar Profissional"}
                onChange={(e: any) => setProfissionalAssignado(e.value)}
                options={profissionais.map((prof) => ({label: prof.nome, value: prof._id.toString()}))} />
        </div>
        <div> {/* @ts-ignore */ }
            {/* @ts-ignore */ }
            <Select defaultValue={estadoAtual} options={options} value={{value: estadoAtual, label: capitalizeFirstLetter(estadoAtual)}}
                onChange={(e: any) => {
                    setEstadoAtual(e.value)
                }} />
        </div>
        {
            adms.includes(userLogged.nome) ? <p style={{marginLeft: 20}}><i
                onClick={async () => {
                    if (window.confirm("Você tem certeza que deseja remover esse registro?\nEssa ação é irreversível.")) {
                        await LeadDAO.removeLead(lead._id);
                        let leads = await LeadDAO.findAll();
                        setLeads(leads)
                    }
                }}
                className={'fas fa-trash'} /></p> : <></>
        }
    </div>)
}


export default LeadComponent;
