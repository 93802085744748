import React from 'react';
import './reservas.sass';
import {connect, useSelector} from "react-redux";
import ClienteTopbar from "../../../assets/component/cliente_topbar/topbar";
import AlternatingTab from "../../../assets/component/alternating_tab/alt_tab";
import Snack from "../../../assets/component/Snack/snack";
import ReservaCliente from "../../../assets/component/reserva_cliente/reserva_cliente";
import Actions from "../../../redux/actions/actions";
import {useHistory} from "react-router";
import Button from "../../../assets/component/button/button";
import CancelCheckbox from "../../../assets/component/cancel_checkbox/CancelCheckbox";
import {removeElementFromArray} from "../../../assets/AuxFunctions";
import reservaDAO, {Reserva} from "../../../DAO/reservaDAO";
import moment from 'moment'
import {RootState} from "../../../redux/storeTypes";
import {ToastContainer} from "react-toastify";
import {
    showToastError,
    showToastSuccess
} from "../../../assets/component/modals/administrativo/modal_edit_agendamento/utils";

const MinhasReservasPage = props => {

    const [selectedTab, selectTab] = React.useState(1)
    const [cancelando, setCancelando] = React.useState(false);
    const [selectedReservas, selectReservas] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [loadingCarregamento, setLoadingCarregamento] = React.useState(true);

    const { mongoClient, userLogged } = useSelector((state: RootState) => state.general);

    const hist = useHistory();

    React.useEffect(() => {
        if (!('nome' in userLogged)) {
            hist.push('/');
        }
    });

    const renderHistorico = () => {
        let arrayExecutados: Array<Reserva> = [];
        if (props.profissionalReservas) {
            props.profissionalReservas.forEach(reserva => {
                if (reserva.executado)
                    arrayExecutados.push(reserva);
            });
            if (arrayExecutados.length > 0) {
                return arrayExecutados.map((reserva, index) => (
                    <ReservaCliente reserva={reserva} executado key={index}/>
                ))
            } else {
                return <h2 className={'empty_array'}>Não há agendamentos executados até o momento.</h2>
            }
        }
    };

    const checaSeNaoEDaquiA2Horas = () => {
        for (let reserva of selectedReservas as Array<Reserva>) {
            if (moment(new Date()).isSame(new Date(reserva.data), 'day')
                && (reserva.hora_inicio - new Date().getHours()) <= 2) {
                return false
            }
        }
        return true;
    }

    const handleConfirmCancelamento = async () => {
        if (window.confirm("Você tem certeza que deseja cancelar esses agendamentos?")) {
            setLoading(true);
            if (!checaSeNaoEDaquiA2Horas()) {
                 alert("Um dos seus agendamentos selecionados será em menos de 2 horas, para " +
                     "realizar esse cancelamento, por favor, fale com nossos administradores.")
            } else {
                try {
                        await reservaDAO.cancelaMuitasReservas(selectedReservas, userLogged, mongoClient);
                        const reservas = await reservaDAO.findThisMonth(mongoClient);
                        props.setAgendamentos(reservas);
                        props.setProfissionalReservas(reservaDAO.findReservaDeCliente(userLogged._id, reservas));
                        showToastSuccess("Cancelamento realizado com sucesso!");
                } catch (err) {
                    showToastError("Ocorreu uma falha ao realizar o cancelamento.");
                    alert("Erro! Informações: "+err);
                }
            }
            setLoading(false);
            setCancelando(false);
            selectReservas([]);
        }
    };

    React.useEffect(() => {
        if (props.profissionalReservas) {
            setLoadingCarregamento(false)
        }
    }, [props.profissionalReservas])

    const renderReservas = () => {

        let reservasAvailable = [];
        if (props.profissionalReservas) {
            reservasAvailable = props.profissionalReservas.filter(reserva => {
                return (!reserva.executado && !reserva.cancelado)
            })
        }

        reservasAvailable.sort((a : any, b : any) => {
            if (moment(new Date(a.data)).isAfter(new Date(b.data), 'day')) { return 1; }
            else { return -1; }
        })

        if (reservasAvailable.length > 0) {
            return reservasAvailable.map((reserva, index) => {
                    return (
                        <div className={cancelando ? 'flex flex_margin' : 'flex'}>
                            {cancelando ? <CancelCheckbox onCheck={checked => {
                                if (!checked) { selectReservas([...selectedReservas, reserva]); }
                                else { selectReservas(removeElementFromArray(selectedReservas, reserva)); }
                            }}/> : <></>}
                            <ReservaCliente reserva={reserva} key={index}/>
                        </div>
                    );
            })
        } else if (loadingCarregamento) {
            return <h2 className={'empty_array'}>Carregando...</h2>
        } else {
            return <h2 className={'empty_array'}>Não há agendamentos marcados até o momento.</h2>
        }
    };

    return (
        <div className={'reservas_page_container'}>
            <ClienteTopbar/>
            <div className={'info_container'}>
                <AlternatingTab selectedIndex={selectedTab} elements={[
                    { name: 'Reserva por dia',
                        onClick: () => selectTab(0)},
                    {
                        name: 'Minhas Reservas',
                        onClick: () => selectTab(1),
                    }, {
                        name: "Relatórios",
                        onClick: () => selectTab(2),
                    }]}/>
                <Snack/>
            </div>
            <div className={'container_reservas'}>
                <div className={'flex'}>
                    <h1 className={'title'}>Minhas Reservas</h1>
                    <Button
                        onClick={() => {
                            setCancelando(!cancelando)
                        }}
                        className={'cancelar_agendamentos'}
                        text={cancelando ? 'Parar Cancelamento' : 'Cancelar Agendamentos'}/>
                </div>
                { renderReservas() }
                {cancelando ? <Button
                    onClick={handleConfirmCancelamento}
                    text={'Confirmar Cancelamento'}
                    loading={loading}
                    className={'cancelar_button'} />: <></>}
                <h1 className={'title'}>Histórico</h1>
                { renderHistorico() }
            </div>
            <ToastContainer />
        </div>
    )
};

const mapStateToProps = state => ({
    profissionalReservas: state.profissionais.profissionalReservas,
});
const mapDispatchToProps = dispatch => ({
    setAgendamentos: agnds => dispatch({type: Actions.setAgendamentos, payload: agnds}),
    setProfissionalReservas: reservas => dispatch({type: Actions.setProfissionalReservas, payload: reservas}),
});

export default connect(mapStateToProps, mapDispatchToProps)(MinhasReservasPage);
