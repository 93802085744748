import React from 'react';
import AdministradorTopbar from "../../../assets/component/adm_topbar/adm_topbar";
import Paginator, { calculatePerPage } from "../../../assets/component/paginator/paginator";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/storeTypes";

const Posts: React.FC = () => {

    const [pages, setPages] = React.useState<Array<number>>([])
    const [selectedPage, setSelectedPage] = React.useState(1);

    const { posts, postSelected } = useSelector((state: RootState) => state.posts);

    React.useEffect(() => { calculatePerPage(posts,  6, setPages) }, [posts])

    return (
        <div>
            <AdministradorTopbar pageSelected={"blog"} />
            <div className={"profissionais_list_container"}>
                <h1>Últimos Posts</h1>
                { posts.map(post => <></>)}
                <Paginator pages={pages} selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
            </div>
        </div>
    );
};

export default Posts;
