import {toast} from "react-toastify";

export const populateSalas = (salas) => {
    let arr = [];
    // @ts-ignore
    salas.forEach((sala) => arr.push({ label: sala.nome, value: sala._id }));
    return arr;
};

export const showToastLoading = (text) => {
    toast.warn(text, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

export const showToastSuccess = (text) => {
    toast.success(text, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

export const showToastError = (text) => {
    toast.error(text, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}
export const populateHorasArray = (horaInicio, horaFim) => {
    let arr = [];
    for (let i = horaInicio; i < horaFim; i++)
        { // @ts-ignore
            arr.push({ label: i + ':00', value: i });
        }
    return arr;
};
