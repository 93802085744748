import React from 'react';
import './Notification.sass';
import ProfissionalSnack from '../ProfissionalSnack/ProfissionalSnack';
import NotificacaoDAO, { Notificacao } from '../../../../../DAO/NotificacaoDAO';
import moment from 'moment';
import { Node } from 'slate';
import {useDispatch, useSelector} from 'react-redux';
import { RootState } from '../../../../../redux/storeTypes';
import clienteDAO from '../../../../../DAO/clienteDAO';
import {ActionsFn} from "../../../../../redux/actions/actions";

const Notification = (props: { data: Notificacao }) => {
  const [open, setOpen] = React.useState(false);

  const { profissionais } = useSelector(
    (state: RootState) => state.profissionais
  );

  const dispatch = useDispatch()

  const serialize = (nodes) => nodes.map((n) => Node.string(n)).join('\n');

  return (
    <React.Fragment>
      <div className={'notification'}>
        <div className={'right_side'}>
          <h1>{props.data.titulo}</h1>
          <p className={'texto'}>{serialize(props.data.texto)}</p>
          <p style={{ fontSize: 10, color: '#AAA' }}>
            Criado dia:{' '}
            {moment(new Date(props.data.criadoAs)).format('DD/MM/YYYY')}
          </p>
        </div>
        <div className={'left_side'}>
          <h3>Enviado a:</h3>
          <div style={{ display: 'flex' }}>
            {props.data.enviar_para === 'all' ? (
              'Todos'
            ) : props.data.enviar_para.length > 0 ? (
              props.data.enviar_para.map((user_id) => (
                <ProfissionalSnack
                  key={props.data._id.toString() + ' ' + user_id}
                  profissional={clienteDAO.getProfissionalById(
                    profissionais,
                    user_id
                  )}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          className={'indicator'}
          onClick={async () => {
            if (
              window.confirm('Tem certeza que deseja excluir essa Notificação?')
            ) {
              await NotificacaoDAO.delete(props.data._id);
              const notificacoes = await NotificacaoDAO.findAll();
              dispatch(ActionsFn.setNotifications(notificacoes));
            }
          }}
        >
          <i className={'fas fa-trash'} />
        </div>
        <div
          className={open ? 'indicator opened' : 'indicator'}
          onClick={() => setOpen(!open)}
        >
          <i className={'fas fa-chevron-down'} />
        </div>
      </div>
      <div
        className={open ? 'notification_details open' : 'notification_details'}
      >
        <h3>Visto por:</h3>
        <div className={'vistopor_container'}>
          {props.data.visto_por.length === 0 ? (
            <p style={{ fontSize: 12, color: '#888' }}>
              Ninguém ainda viu essa notificação...
            </p>
          ) : (
            props.data.visto_por.map((user: any) => (
              <ProfissionalSnack
                key={user.toString()}
                profissional={clienteDAO.getProfissionalById(
                  profissionais,
                  user
                )}
              />
            ))
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Notification;
