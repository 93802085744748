import React from 'react';
import './Configuracoes.sass';
import ClienteTopbar from '../../../assets/component/cliente_topbar/topbar';
import { Link } from 'react-router-dom';
import BoxProfissional from "./components/BoxProfissional/BoxProfissional";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/storeTypes";

const Configuracoes = (props) => {

    const {userLogged} = useSelector((state: RootState) => state.general);

  return (
    <div className={'configuracoes_container'}>
      <ClienteTopbar />
      <div style={{ marginTop: 35 }}>
        <Link to={'/agendamentos'}>
          <i className={'fas fa-chevron-left'} /> &nbsp; Voltar para Página
          Inicial
        </Link>
      </div>
        <BoxProfissional profissional={userLogged} isUserLogged={true} />
    </div>
  );
};

export default Configuracoes;
