import React from 'react';
import ModalParent from "../../../../../assets/component/modals/modal_parent/modal";
import {ToastContainer} from "react-toastify";
import moment from "moment";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/storeTypes";
import Cancelamento
    from "../../../../../assets/component/modals/administrativo/modal_edit_agendamento/components/Cancelamento/Cancelamento";

interface Props {
    dateSelected: Date,
    close: () => void,
    show: boolean,
}

const ModalEditarAgendamento : React.FC<Props> = ({dateSelected, close, show}) => {

    const { agendamentoSelected } = useSelector((state: RootState) => state.agendamentos)

    const setSubtitle = () => {
        if ('profissional_id' in agendamentoSelected) {
            return (
                moment(dateSelected).locale('pt-BR').format('DD MMMM [de] YYYY') +
                ' - ' +
                agendamentoSelected.sala.nome
            );
        }
        return '';
    };

    return (
        <React.Fragment>
            <ModalParent
                show={show}
                header={
                    <header>
                        <div>
                            <h1>Editar Reserva</h1>
                            <h3>{setSubtitle()}</h3>
                        </div>
                        <div className={'close_container'} onClick={close}>
                            <i className={'fa fa-times'} />
                        </div>
                    </header>
                }
                body={
                    <div className={'body_edit_agendamento'}>
                        {
                            agendamentoSelected &&  <Cancelamento tipo={"cliente"} close={() => close()} />
                        }
                    </div>
                }
                footer={<div className={'footer footer_edit_agendamento'} />}
            />
            <ToastContainer />
        </React.Fragment>
    );
};

export default ModalEditarAgendamento;
