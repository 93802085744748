import {
  AnonymousCredential,
  UserPasswordAuthProviderClient,
  UserPasswordCredential,
} from 'mongodb-stitch-browser-sdk';
import axios from 'axios';

const COLLECTION = 'administradores';

/*
    foto_url: string,
    login: string,
    nome: string,
    senha: string,
 */

const administradorDAO = {
  db: null,
  AWSAccess: false,
  setAccess(access) {
    this.AWSAccess = access
  },
  setDb(db) {
    this.db = db;
  },
  create(adm) {
    if (this.AWSAccess) {
      throw Error("Você não pode criar um administrador usando AWS, troque para MongoDB Realm na Tela de Login.");
    } else {
      return this.db.collection(COLLECTION).insertOne(adm);
    }
  },
  update(query, changes) {
    if (this.AWSAccess) {
      return axios.post(process.env.REACT_APP_AWS_SIDEBRIDGE, "administrador/update", 
      {query: query, changes: changes});    
    }
    return this.db.collection(COLLECTION).updateMany(query, { $set: changes });
  },
  delete(query) {
    if (this.AWSAccess) {
      return axios.post(process.env.REACT_APP_AWS_SIDEBRIDGE, "administrador/delete", 
        {query: query});
    }
    return this.db.collection(COLLECTION).deleteMany(query);
  },
  findAll() {
    if (this.AWSAccess) {
      let { data } = axios.post(process.env.REACT_APP_AWS_SIDEBRIDGE, "administrador/find_all");
      return data;
    }
    return this.db.collection(COLLECTION).find({}).toArray();
  },
  async addUser(client, email, password, clienteData) {
    if (this.AWSAccess) {
      throw Error("Você não pode criar um administrador usando AWS, troque para MongoDB Realm na Tela de Login.");
    } else {
      const emailPasswordClient = client.auth.getProviderClient(
      UserPasswordAuthProviderClient.factory
    );
    try {
      const array = await administradorDAO.find({ email: email });
      if (array.length > 0) {
        alert('Administrador já existe com esse email.');
      } else {
        await emailPasswordClient.registerWithEmail(email, password);
        return this.create({ ...clienteData, email });
      }
    } catch (e) {
      alert(
        'ATENÇÃO: Já existe um profissional com esse e-mail, ' +
          'por favor, considere a SENHA do profissional já cadastrado. Essa conta agora terá ' +
          'privilégios administrativos.'
      );
      return this.create({ ...clienteData, email });
    }
    }
  },
  anonymousLogin(client) {
    if (this.AWSAccess) {
      throw Error("This doesn't work.");
    } else {
      return client.auth.loginWithCredential(new AnonymousCredential());
    }
  },
  find(query) {
    if (this.AWSAccess) {
      let { data } = axios.post(process.env.REACT_APP_AWS_SIDEBRIDGE, {query: query});
      return data;
    }
    return this.db.collection(COLLECTION).find(query).toArray();
  },
  userPasswordLogin(client, user, password) {
    console.log('User Password Login');
    const credentials = new UserPasswordCredential(user, password);
    return client.auth.loginWithCredential(credentials);
  },
};

export default administradorDAO;
