import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/storeTypes";
import AdministradorTopbar from "../../../assets/component/adm_topbar/adm_topbar";
import BoxProfissional from "../../cliente/configuracoes/components/BoxProfissional/BoxProfissional";
import "./ProfissionalEdit.sass"
import CheckBox from "../../../assets/component/checkbox/checkbox";
import {updateAndGetProfissionais} from "./utils";

const ProfissionalEdit: React.FC = () => {

    const { profissionalSelected, sele } = useSelector((state: RootState) => state.profissionais);
    const [bloqueadoTarde, setBloqueadoTarde] = React.useState(profissionalSelected.bloqueadoTarde);
    const [bloquearVisualizacao, setBloquearVisualizacao] = React.useState(profissionalSelected.bloquearVisualizacao);

    const dispatch = useDispatch();

    React.useEffect(() => {
        updateAndGetProfissionais(profissionalSelected, bloqueadoTarde, bloquearVisualizacao, dispatch)}, [bloqueadoTarde, bloquearVisualizacao]);

    React.useEffect(() => {
        setBloqueadoTarde(profissionalSelected.bloqueadoTarde);
        setBloquearVisualizacao(profissionalSelected.bloquearVisualizacao);
    }, [profissionalSelected]);

    return (
        <div>
            <AdministradorTopbar pageSelected={"profissional"} />
            <div style={{display: "flex", marginBottom: 20, marginLeft: 80, marginTop: 20}}>
                <div style={{width: "100%"}}>
                    <CheckBox label={'Bloquear Reservas Verspetinas'}
                              onCheck={(checked) => setBloqueadoTarde(!bloqueadoTarde)}
                              check={bloqueadoTarde} />
                </div>
                <div style={{width:"100%"}}>
                    <CheckBox label={'Bloquear Visualização no Site e Indicação'}
                              onCheck={(checked) => setBloquearVisualizacao(!bloquearVisualizacao)}
                              check={bloquearVisualizacao} />
                </div>
            </div>
            <BoxProfissional profissional={profissionalSelected} />
        </div>
    );
};

export default ProfissionalEdit;
