import React from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../../../../redux/storeTypes";
import {checaSeFaltaPagamento, checkHasPendencias} from "./utils";
import {transformStringToReais} from "../../../../../../../../AuxFunctions";
import moment from "moment"
import reservaDAO from "../../../../../../../../../DAO/reservaDAO";
import ModalPagarPendencia from "../ModalPagarPendencia/ModalPagarPendencia";

const Pendencias = () => {

    const { agendamentoSelected, agendamentos } = useSelector((state: RootState) => state.agendamentos);
    const [pendenciasPgto, setPendenciasPgto] = React.useState<Array<any>>([]);
    const [modalOpen, setModalOpen] = React.useState(false)
    const [pendenciaSelected, selectPendencia] = React.useState({pendencia: null, index: null});

    React.useEffect(() => {
        const reservasComProblemas = reservaDAO.getReservasAnterioresComProblemas(agendamentos);
        let faltas = checaSeFaltaPagamento(agendamentoSelected, reservasComProblemas)
        console.log(faltas)
        setPendenciasPgto(faltas);
    }, [agendamentoSelected, agendamentos])


    const returnByType = (pendencia) => {
        if (pendencia.tipo === "atraso") {
            return "Taxa de atraso pendente de pagamento: " + transformStringToReais(pendencia.quantidade)
        } else {
            return "Taxa de cancelamento pendente de pagamento: " +
                moment(pendencia.agendamento.data).format("DD/MM/YYYY")
                + ` ${pendencia.agendamento.hora_inicio}h-${pendencia.agendamento.hora_fim}h`
            + " na " + pendencia.agendamento.sala.nome
        }
    }

    return (
            <React.Fragment>
                <ModalPagarPendencia
                            open={modalOpen}
                            close={() => setModalOpen(false)}
                            pendenciaIndex={pendenciaSelected.index}
                            pendenciaSelected={pendenciaSelected.pendencia} />
                {checkHasPendencias(agendamentoSelected) ?
                    agendamentoSelected.profissional.pendencias.map((pendencia, i) => (
                        <p style={{color: "red"}}>{returnByType(pendencia)} <span
                            onClick={() => {
                                selectPendencia({pendencia: pendencia, index: i})
                                setModalOpen(true)
                            }}
                            style={{
                                cursor: "pointer",
                                color: "blue",
                                textDecoration: "underline"
                            }}>Pagar</span>
                        </p>
                    )) : <></>}
                {
                    pendenciasPgto.map((pendencia, i) => <p style={{color: "red"}}>
                        Pagamento de reserva pendente em: {moment(pendencia.data).format("DD/MM/YYYY ")}
                        na {pendencia.sala.nome}
                    </p>)
                }
            </React.Fragment>
        );
};

export default Pendencias;
