import { RemoteMongoDatabase } from "mongodb-stitch-browser-sdk";

export interface Titulo {
    _id?: any,
    titulo: string,
    valor: number,
    creditos?: number,
}
const COLLECTION = "financeiro_titulos";

export interface TituloDAO {
    db: RemoteMongoDatabase | null,
    create: (titulo: Titulo) => Promise<any> | undefined
    [propName : string] : any
}

const FinanceiroTitulosDAO : TituloDAO = {
    db: null,
    setDb (db : RemoteMongoDatabase | null) {
        this.db = db;
    },
    create(titulo: Titulo) {
        if(this.db) {
            return this.db.collection(COLLECTION).insertOne(titulo);
        }
    },
    findAll() {
        if (this.db) {
            return this.db.collection(COLLECTION).find({}).toArray();
        }
    },
    edit(id, edits) {
        if (this.db) {
            return this.db.collection(COLLECTION).updateOne({_id: id}, {$set: edits});
        }
    }
}

export default FinanceiroTitulosDAO;
