import React from 'react';
import AdministradorTopbar from "../../../assets/component/adm_topbar/adm_topbar";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/storeTypes";
import {Redirect} from "react-router-dom";
import "./Leads.sass"
import moment from "moment";
import { CSVLink } from 'react-csv'
import LeadComponent from "./Lead/Lead";
import {getLeadsAndSetToState, sortLeadByDateFunc} from "./utils";
import Paginator, {calculatePerPage} from "../../../assets/component/paginator/paginator";

const LeadsPage = () => {

    const { userLogged } = useSelector((state : RootState) => state.general)
    let [leads, setLeads] = React.useState([]);
    const [pages, setPages] = React.useState<Array<number>>([])
    const [selectedPage, setSelectedPage] = React.useState(1);

    const leadsPerPage = 25;

    React.useEffect(() => { getLeadsAndSetToState(setLeads); }, []);
    React.useEffect(() => { calculatePerPage(leads,  leadsPerPage, setPages) }, [leads])

    if ("nome" in userLogged) {
        return (<div className={"leads_container"}>
                <AdministradorTopbar pageSelected={"leads"} />
                <div className={"profissionais_list_container"}>
                    <h2>Vindos do Site</h2>
                    <CSVLink
                        data={leads} filename={"leads_" + moment().format('YYYY-MM-DD') + ".csv"}>Exportar CSV </CSVLink>
                    { leads.map((lead, index) => (
                        index < (leadsPerPage * selectedPage) && (index >= (leadsPerPage * (selectedPage - 1))) ?
                            <LeadComponent key={index} setLeads={setLeads} lead={lead} />: <></>
                    )) }
                    <Paginator pages={pages} selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
                </div>
            </div>
        )
    } else {
        return <Redirect to={"/"} />
    }

};

export default LeadsPage;
