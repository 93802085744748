import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { transformStringToReais } from '../../../../../AuxFunctions';

const HoraAvulsa = ({
  profissionais,
  selectProf,
  salaSelected,
  dateSelected,
}) => {
  const eSabado = () => { return dateSelected.getDay() === 6;};

  const populateHoraFinal = (selectFinal : boolean) => {
    let horaFinal = eSabado() ? 18 : 20;
    if (selectFinal) { horaFinal++ }
    return horaFinal;
  }

  const selectOptions = (horaInicial, isHoraFinal = false) => {
    let array : Array<{label: string, value: number}> = [];
    let final = populateHoraFinal(isHoraFinal);
    for (let i = horaInicial; i < final; i++) { array.push({ label: i + ':00', value: i }) }
    return array;
  };

  const [profissionaisOptions, setProfissionaisOptions] = React.useState<any>([]);
  const [horaInicial, setHoraInicial] = React.useState(0);
  const [horaFinal, setHoraFinal] = React.useState(0);
  const [horasFinais, setHorasFinais] = React.useState(selectOptions(9, true));

  React.useEffect(() => setProfissionaisOptions(
      profissionais.map(prof => ({label: prof.nome, value: prof}))) , [profissionais]);

  React.useEffect(() => setHorasFinais(selectOptions(9, true)), [dateSelected]);

  return (
    <div>
      <div
          style={{ marginLeft: '5%', marginRight: '5%' }}
          className={'select_profissionais_container'}>
        <Select
          required
          name={'profissional'}
          onChange={(e : any)  => {
            selectProf(e.value);
          }}
          placeholder={'Profissional'}
          options={profissionaisOptions}
        />
      </div>
      <div className={'horas_intervalo'}>
        <div>
          <h2>Hora Inicial</h2>
          <Select
            name={'hora_inicio'}
            onChange={(e : any) => {
              setHorasFinais(selectOptions(e.value + 1, true));
              setHoraInicial(e.value);
            }}
            classNamePrefix={'Select'}
            options={selectOptions(dateSelected.getDay() === 6 ? 8 : 9)}
          />
        </div>
        <div>
          <h2>Hora Final</h2>
          <Select
            onChange={(e : any) => setHoraFinal(e.value)}
            name={'hora_fim'}
            classNamePrefix={'Select'}
            options={horasFinais}
          />
        </div>
      </div>
      <div className={'resume_container'}>
        <div>
          <h2>Valor/Hora</h2>
          <h3>{transformStringToReais(salaSelected.valor_hora)}</h3>
        </div>
        <div>
          <h2>Valor Total:</h2>
          <h3>
            {transformStringToReais(horaFinal === 0 ? 0 : salaSelected.valor_hora * (horaFinal - horaInicial))}
          </h3>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  mongoClient: state.general.mongoClient,
  profissionais: state.profissionais.profissionais,
  salaSelected: state.agendamentos.salaSelected,
  dateSelected: state.general.dateSelected,
});

//@ts-ignore
export default connect(mapStateToProps)(HoraAvulsa);
