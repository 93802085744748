import React from 'react';
import InputText from '../../../../inputText/input';
import "./Pagamento.sass"

interface valoresPagamento {
    debito: number,
    credito: number,
    especie: number,
    transf: number,
    cortesia: number,
    totalAcumulado: number,
    saldo: number,
}

interface Props {
    valorTotal: number,
    setValor: (valoresPagamento) => void,
    allowCortesia?: boolean,
}

const Pagamento : React.FC<Props> = (props) => {

    const transformFloatToStringReais = (floatNumber) => {
        let string = floatNumber.toFixed(2).toString();
        return "R$ " + string.replace(".", ",");
    }

    const [valor, setValor] = React.useState<valoresPagamento>({
        debito: 0,
        credito: 0,
        especie: 0,
        transf: 0,
        cortesia: 0,
        totalAcumulado: 0,
        saldo: 0,
    });

    React.useEffect(() => {
        const somaValores = valor.debito + valor.credito
                            + valor.especie + valor.transf
                            + valor.cortesia;
        if (somaValores !== valor.totalAcumulado) {
            setValor({...valor, totalAcumulado: somaValores});
            props.setValor({...valor, totalAcumulado: somaValores});
        }
    }, [valor])

    React.useEffect(() => {
        setValor({
            debito: 0,
            credito: 0,
            especie: 0,
            transf: 0,
            cortesia: 0,
            totalAcumulado: 0,
            saldo: 0,
        });
    }, [props.valorTotal]);

    return (
        <React.Fragment>
        <div className="flex">
            <p>Valor a Receber: <b>
                {transformFloatToStringReais(props.valorTotal)}</b>
            </p>
            <p>Total Acumulado: <b>
                {transformFloatToStringReais(valor.totalAcumulado)}
            </b>
            </p>
        </div>
    <div className="flex" style={{ marginBottom: 0 }}>
        <InputText
            onChange={(e : any) => setValor({ ...valor, especie: +e.target.value.replace(",", ".") })}
            label="Espécie" decoration="R$" step="0.01" />
        <InputText
            onChange={(e : any) => setValor({ ...valor, credito: +e.target.value.replace(",", ".") })}
            label="Crédito"
            decoration="R$" step="0.01" />
        <InputText
            onChange={(e: any) => setValor({ ...valor, debito: +e.target.value.replace(",", ".") })}
            label="Débito"
            decoration="R$" type="number" step="0.01" />
        <InputText
            onChange={(e: any) => setValor({ ...valor, transf: +e.target.value.replace(",", ".") })}
            label="Transf./PIX"
            decoration="R$" type="number" step="0.01" />
        {/* Pega da props se pode ou não pode adicionar cortesia no pagamento de taxas. */}
        {
            props.allowCortesia === undefined || props.allowCortesia ? <InputText
                onChange={(e : any) => setValor({ ...valor, cortesia: +e.target.value.replace(",", ".") })}
                label="Cortesia"
                decoration="R$" type="number" step="0.01" /> : <></>
        }

    </div>
        </React.Fragment>
    );
}

export default Pagamento;
