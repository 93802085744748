import React from 'react';
import Select from "react-select";
import "./Filtro.sass"

interface Props {
    options: Array<string>,
    onChange: (e) => void,
    name: string,
}

const Filtro : React.FC<Props> = ({name, options, onChange}) => {

    let opts = options.map((opt) => ({option: opt, label: opt}))
    const [selected, setSelected] = React.useState({option: "todos", label: "todos"})

    return (
        <div className={"filtro_contrainer"}>
            <h3>{name}</h3>
            <p><Select
                onChange={(e : any) => {
                    console.log(e);
                    setSelected(e)
                    onChange(e);
                }}
                value={selected}
                options={opts} /></p>
        </div>
    );
};

export default Filtro;
