import React from 'react';
import AdministradorTopbar from '../../../assets/component/adm_topbar/adm_topbar';
import CardProfissional from '../../../assets/component/card_profissional/cardProfissional';
import './profissionais.sass';
import Fab from '../../../assets/component/Fab/Fab';
import Actions from '../../../redux/actions/actions';
import {connect, useSelector} from 'react-redux';
import ModalNewProfissional
    from '../../../assets/component/modals/administrativo/modal_new_profissional/modal_new_profissional';
import ModalTypes from '../../../assets/modal_types';
import clienteDAO, {profissionaisAtivos, Profissional} from '../../../DAO/clienteDAO';
import {useHistory} from 'react-router';
import CsvDownload from 'react-json-to-csv';
import ProfissionalList from './components/ProfissionalList';
import ModalAddCredito from '../../../assets/component/modals/administrativo/modal_add_credito/ModalAddCredito';
import {ToastContainer} from 'react-toastify';
import moment from "moment";
import CheckBox from "../../../assets/component/checkbox/checkbox";

const ProfissionaisPage = (props) => {
    const hist = useHistory();
    //@ts-ignore
    const { agendamentos } = useSelector((state) => state.agendamentos); //@ts-ignore
    const { profissionais } = useSelector((state) => state.profissionais);

    const [indicationOnly, setIndicationOnly] = React.useState(true);
    const [view, setView] = React.useState(
        true
    ); /* false -> grid | true -> list */
    const [openModalCreditos, setOpenModalCreditos] = React.useState(false);
    const [profsAtivos, setProfsAtivos] = React.useState([]);

    React.useEffect(() => {
        if ('ocupacao' in props.userLogged) { hist.push('/') }
        if (clienteDAO.db) { clienteDAO.findAll().then((res) => props.setProfissionais(res) ); }
    }, [props.client]);

    const prepareToExport = ( profs : Array<Profissional> ) => {
        let array : Array<Profissional> = [];
        profs.forEach((prof : Profissional) => {
            array.push({
                nome: prof.nome,
                email: prof.email,
                telefone: prof.telefone,
                cpf: prof.cpf,
                nomeProfissional: prof.nomeProfissional,
                dataNascimento: prof.dataNascimento,
                registro: prof.registro,
                creditos: prof.creditos,
                endereco: prof.endereco,
            } as Profissional) });
        return array;
    }

    React.useEffect(() => {
        setProfsAtivos(profissionaisAtivos(profissionais, agendamentos.filter(agendamento =>
                moment(new Date(agendamento.data)).isAfter(moment().subtract(1, "month")))))
    }, [props.profissionais]);

    const bloquearViz = (profsAtivos, prof) => {
        let isInAtivos = profsAtivos.find(profissional => profissional.nome === prof.nome);
        return !isInAtivos;
    }

    return (
        <div>
            <ModalAddCredito
                close={() => setOpenModalCreditos(false)} show={openModalCreditos}/>
            <ModalNewProfissional
                close={() => props.closeModal()}
                show={props.showModal && props.modalType === ModalTypes.adicionarProfissional}/>
            <AdministradorTopbar pageSelected={'profissionais'}/>
            <CsvDownload
                style={{width: 220, marginTop: 20, marginLeft: 20}}
                className={'button'}
                data={indicationOnly ? prepareToExport(profsAtivos) : prepareToExport(profissionais) }
                filename={'profissionais.csv'}>
                Exportar Dados
            </CsvDownload>
            <div className={'visualizacao'}>
                <p>
                    Visualização em{' '}
                    <span className={'grid'}>{view ? 'Lista' : 'Grade'}</span>
                    <br/>
                    <span onClick={() => setView(!view)} className={'change_list'}>
                        Trocar para {view ? 'Grade' : 'Lista'}.
                     </span>
                </p>
            </div>
            <div style={{marginLeft: 50}}>
                <CheckBox
                    check={indicationOnly}
                    onCheck={(val) => setIndicationOnly(val)}
                    label={"Apenas profissionais para indicação."}/>
            </div>
            {view ? (
                <div className={'profissionais_list_container'}>
                    <table>
                        <thead>
                        <td></td>
                        <td>Profissional</td>
                        <td>Contato</td>
                        <td>Disponibilidade</td>
                        </thead>
                        {
                            indicationOnly ? (profsAtivos.map((prof : Profissional) => (<ProfissionalList
                                profissional={prof}
                                key={prof._id}
                                openModalCreditos={() => setOpenModalCreditos(true)}
                            />))) : (profissionais.map((prof => (<ProfissionalList
                                profissional={prof}
                                blocked={bloquearViz(profsAtivos, prof)}
                                key={prof._id}
                                openModalCreditos={() => setOpenModalCreditos(true)}
                            />))))

                        }
                    </table>
                </div>
            ) : (
                <div className={'profissionais_container'}>
                    {props.profissionais.map((profissional) => (
                        <CardProfissional profissional={profissional}/>
                    ))}
                </div>
            )}
            <Fab
                onClick={() => {
                    props.openModal(ModalTypes.adicionarProfissional);
                }}
            />
            <ToastContainer position={'bottom-right'}/>
        </div>
    );
};

const mapStateToProps = (state) => ({
    showModal: state.general.showModal,
    modalType: state.general.modalType,
    client: state.general.mongoClient,
    userLogged: state.general.userLogged,
    profissionais: state.profissionais.profissionais,
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch({type: Actions.closeModal}),
    openModal: (open) => dispatch({type: Actions.showModal, payload: open}),
    setProfissionais: (profs) =>
        dispatch({type: Actions.setProfissionais, payload: profs}),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfissionaisPage);
