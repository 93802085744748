import moment from "moment";

export const days = [
    'Domingo',
    'Segunda-Feira',
    'Terça-Feira',
    'Quarta-Feira',
    'Quinta-Feira',
    'Sexta-Feira',
    'Sábado',
];

export const fillHoras = (sabado, dateSelected?) => {
    let array : Array<{label: string, value: any}> = [];
    let [horaInicial, horaFinal] = [9, 20];
    if (sabado) {
        [horaInicial, horaFinal] = [8, 12];
        if (sabado && moment(dateSelected).isAfter(new Date('2021-01-15'))) {
            horaFinal = 17;
            if (sabado && moment(dateSelected).isAfter(new Date('2021-02-17'))) {
                horaFinal = 18;
            }
        }
    }
    for (let i = 0; i < horaFinal - horaInicial; i++)
        array.push({ label: i + horaInicial + ':00', value: i + horaInicial });
    return array;
};
