import moment from "moment";
import reservaDAO from "../../../../../DAO/reservaDAO";
import {SalaBloqueio} from "../../../../../DAO/sala_bloqueioDAO";


export const checkIfBlocked = (salaSelected, salaBloqueios, dateSelected) : Array<SalaBloqueio> | null => {
    let bloqueios : Array<SalaBloqueio> = [];
    if (Array.isArray(salaBloqueios) && salaSelected) {
        for (let bloqueio of salaBloqueios) {
            if (bloqueio.sala && salaSelected._id) {
                for (let sala of bloqueio.sala) {
                    if (
                        sala.toString() === salaSelected._id.toString() &&
                        moment(new Date(bloqueio.dia))
                            .add(1, 'day')
                            .isSame(dateSelected, 'day')
                    )
                        //@ts-ignore
                        bloqueios.push(bloqueio);
                }
            }
        }
    }
    return bloqueios.length > 0 ? bloqueios : null;
};

export const getAgendamentosDiaSala = (agendamentos, salaSelected, dateSelected) => {
    let agendamentosSala = reservaDAO.getAgendamentosFromSala(
        agendamentos,
        salaSelected
    );
    return agendamentosSala.filter(
        (agendamento) =>
            moment(agendamento.data).isSame(dateSelected, 'day') &&
            !agendamento.cancelado
    );
}

export const OverlappingRanges = (r1_start, r1_finish, r2_start, r2_finish) => {
    let [arr1, arr2] = [[], []];
    for (let i = r1_start; i < r1_finish; i++) {
        //@ts-ignore
        arr1.push(i);
    }
    for (let i = r2_start; i < r2_finish; i++) {
        //@ts-ignore
        arr2.push(+i);
    }
    for (let el of arr1) {
        if (arr2.includes(el)) {
            return true;
        }
    }
    return false;
};
