import React from 'react';
import "./styles.sass";

const ModoPaisagem = () => {
    return (
        <div className={'modo_paisagem'}>
            <h2>Por favor, vire o seu celular! :(</h2>
        </div>
    )
}

export default ModoPaisagem;