import * as React from 'react';
import InputText from '../../../../../inputText/input';
import "./Pagamento.sass";
import Button from "../../../../../button/button";
import {ToastContainer} from 'react-toastify';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from '../../../../../../../redux/storeTypes';
import reservaDAO from '../../../../../../../DAO/reservaDAO';
import FinanceiroDAO from '../../../../../../../DAO/financeiroDAO';
import clienteDAO from "../../../../../../../DAO/clienteDAO";
import {ActionsFn} from "../../../../../../../redux/actions/actions";
import {calculaTroco, checkHasCredits, checkHasSaldo, transformFloatToStringReais} from './utils';
import {showToastLoading, showToastSuccess} from '../../utils';
import Pendencias from "./components/Pendencias/Pendencias";
import logDAO from "../../../../../../../DAO/logDAO";
import {VALOR_1_CREDITO} from "../../../../../../../constants";
import moment from "moment";

export interface Valor {
    debito: number,
    credito: number,
    especie: number,
    transf: number,
    cortesia: number,
    totalAcumulado: number,
    saldo: number,
}

interface Props {
    setIsLoading: (any) => void,
    close: () => void,
    isLoading: boolean,
    hasCortesia?: boolean,
}

const Pagamento : React.FC<Props> = (props) => {

    const dispatch = useDispatch();

    const [quantidade, setQuantidade] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [hasCredits, setHasCredits] = React.useState<boolean | undefined | number>(false);
    const [hasSaldo, setHasSaldo] = React.useState<boolean | undefined | number>(false);
    const [clicado, setClicado] = React.useState(false);
    const [valor, setValor] = React.useState({
        debito: 0,
        credito: 0,
        especie: 0,
        transf: 0,
        cortesia: 0,
        totalAcumulado: 0,
        saldo: 0,
    });
    const [saldoAdicionado, setSaldoAdicionado] = React.useState(false);
    const { agendamentoSelected } = useSelector((state: RootState) => state.agendamentos);
    const { userLogged } = useSelector((state : RootState) => state.general);
    const { loadingPagamento } = useSelector((state : RootState) => state.financeiro);
    const [saldoAtual, setSaldoAtual] = React.useState(0);

    React.useEffect(() => {
        setValor({
            debito: 0,
            credito: 0,
            especie: 0,
            transf: 0,
            cortesia: 0,
            totalAcumulado: 0,
            saldo: 0,
        });
        setQuantidade(0);
        setClicado(false);
        props.setIsLoading(false);
        setSaldoAdicionado(false);
        if (agendamentoSelected._id) {
            let response = loadingPagamento.includes(agendamentoSelected._id.toString())
            setLoading(response);
        }
        setHasSaldo(checkHasSaldo(agendamentoSelected));
        setHasCredits(checkHasCredits(agendamentoSelected));
    }, [agendamentoSelected]);

    React.useEffect(() => {
        if (valor.totalAcumulado !== (valor.especie + valor.debito + valor.credito +
                valor.transf + valor.cortesia + (quantidade * VALOR_1_CREDITO) + valor.saldo)) {
            setValor({ ...valor, totalAcumulado:
                    (valor.especie + valor.debito + valor.credito +
                        valor.transf + valor.cortesia + valor.saldo + (quantidade * VALOR_1_CREDITO)) })
        }
    }, [valor, quantidade]);

    if ('sala' in agendamentoSelected) {
        if (agendamentoSelected.pago) {
            return (<div>
                <ToastContainer />
                <h2>Pagamento</h2>
                <h3>Reserva já foi paga.</h3>
            </div>)
        }
        return (<div className="pgmtos">
            <ToastContainer />
            <h2>Pagamento</h2>
            { (hasCredits || hasSaldo) ? <div className="flex">
                {hasCredits ?
                    <p>Saldo:
                        <b>{agendamentoSelected.profissional.creditos} Crédito(s)</b>
                    </p> :
                    <></>}
                {hasSaldo ? <p>Saldo Financeiro: R$ {agendamentoSelected.profissional.saldo}
                    &nbsp;&nbsp; <button type={"button"} className='btn_secondary' onClick={() => {
                        if (agendamentoSelected.profissional.saldo > agendamentoSelected.valorTotal) {
                            setValor({...valor, saldo: agendamentoSelected.valorTotal})
                        } else {
                            setValor({...valor, saldo: agendamentoSelected.profissional.saldo})
                        }
                    }}>
                        Usar Saldo
                        </button>
                </p> : <></>}
            </div> : <></> }
            <div className="flex">
                <p>Valor a Receber: <b>
                    {transformFloatToStringReais(agendamentoSelected.valorTotal)}</b>
                    </p>
                <p>Total Acumulado: <b>
                    {transformFloatToStringReais(valor.totalAcumulado)}
                    </b>
                </p>
            </div>
            <div>
                <Pendencias />
            </div>
            <div className="flex" style={{ marginBottom: 0 }}>
                <InputText
                    onChange={e => setValor({ ...valor, especie: +e.target.value.replace(",", ".") })}
                    label="Espécie" decoration="R$" step="0.01" />
                <InputText
                    onChange={e => setValor({ ...valor, credito: +e.target.value.replace(",", ".") })}
                    label="Crédito"
                    decoration="R$" step="0.01" />
                <InputText
                    onChange={e => setValor({ ...valor, debito: +e.target.value.replace(",", ".") })}
                    label="Débito"
                    decoration="R$" type="number" step="0.01" />
                <InputText
                    onChange={e => setValor({ ...valor, transf: +e.target.value.replace(",", ".") })}
                    label="Transf./PIX"
                    decoration="R$" type="number" step="0.01" />
                { props.hasCortesia === undefined || props.hasCortesia ? <InputText
                    onChange={e => setValor({ ...valor, cortesia: +e.target.value.replace(",", ".") })}
                    label="Cortesia"
                    decoration="R$" type="number" step="0.01" /> : <></>}

            </div>
            {hasCredits ? <div className={'counter'}>
                <p>Usar </p>
                <h2>
                    <i
                        className={'fa fa-minus'}
                        onClick={() => { if (quantidade > 0) { setQuantidade(quantidade - 1) } }} />
                </h2>
                <h1>{quantidade}</h1>
                <h2>
                    <i
                        className={'fa fa-plus'}
                        onClick={() => {
                            if (quantidade < agendamentoSelected.profissional.creditos) {
                                setQuantidade(quantidade + 1)
                            }
                        }}
                    />
                </h2>
                <p> créditos</p>
            </div>
             : <></>}
             {
                 ((calculaTroco(valor, agendamentoSelected.valorTotal) > 0) && (!saldoAdicionado) ?
                    <p>Troco: <b>{
                        transformFloatToStringReais(
                            calculaTroco(valor, agendamentoSelected.valorTotal))}</b>
                            &nbsp; &nbsp;
                            <button type={'button'} onClick={async () => {
                                if (window.confirm("Você realmente deseja adicionar saldo? Nota: A" +
                                    " entrada será aceita apesar de ser maior que o total acumulado.")) {
                                    try {
                                        setSaldoAtual(agendamentoSelected.profissional.saldo ?
                                            calculaTroco(valor, agendamentoSelected.valorTotal) +
                                            agendamentoSelected.profissional.saldo :
                                            calculaTroco(valor, agendamentoSelected.valorTotal))
                                      setSaldoAdicionado(true);
                                    } catch(e) {
                                        alert("Erro ao adicionar saldo.");
                                    }
                                }
                            }} className='btn_secondary'>
                                Adicionar saldo
                                </button>
                            </p> : <></>)
             }
            <div>
                {
                    (clicado && valor.totalAcumulado !== agendamentoSelected.valorTotal ) &&
                    !saldoAdicionado ? <h3 style={{color: "#FF3344"}}>O total acumulado não é igual ao valor a receber.</h3>
                        : <></>
                }
                <Button
                    onClick={async () => {
                        if (!props.isLoading) {
                            props.setIsLoading(true);
                            setClicado(true);
                            dispatch(ActionsFn
                                .setLoadingPagamento([...loadingPagamento, agendamentoSelected._id.toString()]))
                            if ((valor.totalAcumulado === agendamentoSelected.valorTotal) || saldoAdicionado) {
                                showToastLoading(
                                    'Carregando: Pagamento da Reserva de: ' +
                                    agendamentoSelected.profissional.nome
                                );
                                if (quantidade > 0) {
                                    //await clienteDAO.editCliente(agendamentoSelected.profissional._id, {
                                    //    creditos: agendamentoSelected.profissional.creditos - quantidade,
                                    //})
                                    await logDAO.create({
                                        usuario: userLogged,
                                        log: `${agendamentoSelected.profissional.nome} pagou a reserva ${moment(agendamentoSelected.data).format("DD-MM-YYYY")} ${agendamentoSelected.sala.nome} ${agendamentoSelected.hora_inicio}h às ${agendamentoSelected.hora_fim}h com ${Math.abs(quantidade)} créditos. Novo saldo: ${agendamentoSelected.profissional.creditos - Math.abs(quantidade)}`,
                                        data_hora: new Date(),
                                    });
                                    await clienteDAO.updateInc(agendamentoSelected.profissional._id, {
                                        creditos: -quantidade,
                                    })
                                    let profs = await clienteDAO.findAll()
                                    dispatch(ActionsFn.setProfissionais(profs))
                                }
                                //Verifica o que foi pra conta bancária
                                if (valor.debito > 0) {
                                    await FinanceiroDAO.create({
                                        data: new Date(),
                                        formaPgto: 'debito',
                                        pagadorRecebedor: agendamentoSelected.profissional.nome,
                                        quantidade: 1,
                                        tipo: 'ENTRADA',
                                        titulo: 'HORA AVULSA',
                                        valor: valor.debito,
                                    })
                                }
                                if (valor.credito > 0) {
                                    await FinanceiroDAO.create({
                                        data: new Date(),
                                        formaPgto: 'credito',
                                        pagadorRecebedor: agendamentoSelected.profissional.nome,
                                        quantidade: 1,
                                        tipo: 'ENTRADA',
                                        titulo: 'HORA AVULSA',
                                        valor: valor.credito,
                                    })
                                }
                                if (valor.transf > 0) {
                                    await FinanceiroDAO.create({
                                        data: new Date(),
                                        formaPgto: 'transf',
                                        pagadorRecebedor: agendamentoSelected.profissional.nome,
                                        quantidade: 1,
                                        tipo: 'ENTRADA',
                                        titulo: 'HORA AVULSA',
                                        valor: valor.transf,
                                    })
                                }
                                if (valor.cortesia > 0) {
                                    await FinanceiroDAO.create({
                                        data: new Date(),
                                        formaPgto: 'cortesia',
                                        pagadorRecebedor: agendamentoSelected.profissional.nome,
                                        quantidade: 0,
                                        tipo: 'ENTRADA',
                                        titulo: 'HORA AVULSA',
                                        valor: valor.cortesia,
                                    })
                                }
                                //verifica o que foi pro caixa (mais simples)
                                if (valor.especie > 0) {
                                    await FinanceiroDAO.create({
                                        data: new Date(),
                                        formaPgto: 'especie',
                                        pagadorRecebedor: agendamentoSelected.profissional.nome,
                                        quantidade: 1,
                                        tipo: 'ENTRADA',
                                        titulo: 'HORA AVULSA',
                                        valor: valor.especie,
                                    })
                                }

                                if (valor.saldo > 0) {
                                    if (agendamentoSelected.valorTotal < agendamentoSelected.profissional.saldo) {
                                        await clienteDAO.editCliente(agendamentoSelected.profissional._id, {
                                            saldo: agendamentoSelected.profissional.saldo - agendamentoSelected.valorTotal
                                        })
                                    } else {
                                        await clienteDAO.editCliente(agendamentoSelected.profissional._id, {
                                            saldo: 0
                                        })
                                    }
                                    let profs = await clienteDAO.findAll()
                                    dispatch(ActionsFn.setProfissionais(profs))
                                }

                                if (saldoAdicionado) {
                                    await clienteDAO.editCliente(agendamentoSelected.profissional._id, {
                                        saldo: saldoAtual,
                                    })
                                }

                                await reservaDAO.pagaReserva(agendamentoSelected, true,  userLogged);
                                let index = loadingPagamento.findIndex(elem => agendamentoSelected._id.toString() === elem);
                                let aux = [...loadingPagamento];

                                aux.splice(index, 1);
                                dispatch(ActionsFn.setLoadingPagamento(aux));
                                showToastSuccess(
                                    'Reserva de: ' +
                                    agendamentoSelected.profissional.nome +
                                    ' paga!'
                                );
                            } else {
                                props.setIsLoading(false);
                            }
                        }
                        }}
                    loading={props.isLoading}
                    type={'button'}
                    text={'Pagar'}
                    width={'45%'}
                />
            </div>
        </div>)
    } else {
        return <></>
    }
}

export default Pagamento;
