import React from 'react';
import "./paginator.sass";

interface Props {
    pages: Array<number>,
    selectedPage: number,
    setSelectedPage: (n : number) => void,
}

export const calculatePerPage = (fullArray, amountPerPage, setFn) => {
    setFn(Array.from(Array(Math.ceil(fullArray.length / amountPerPage)),
        (_, i) => i + 1))
}

const Paginator: React.FC<Props> = ({pages, selectedPage, setSelectedPage}) => {
    return (
        <div className={'pages_paginator'}>
            {pages.map((page, index) => ((index < selectedPage + 5 && (index > selectedPage - 5)) || index === pages.length - 1) ? <div
                className={page === selectedPage ? 'page selected': 'page'}
                key={index}
                onClick={() => {
                    setSelectedPage(page)
                }}>
                {page}
            </div> : <></>)}
        </div>
    );
};

export default Paginator;
