import React from 'react';
import "./PendenciasPage.sass"
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/storeTypes";
import AdministradorTopbar from "../../../assets/component/adm_topbar/adm_topbar";
import Button from "../../../assets/component/button/button";
import moment from "moment";
import ModalPagarPendencia
    from "../../../assets/component/modals/administrativo/modal_edit_agendamento/components/Pagamento/components/ModalPagarPendencia/ModalPagarPendencia";
import {apagarPendencia, checkIfAdm } from './utils';

const PendenciasPage : React.FC = () => {

    const { userLogged } = useSelector((state: RootState) => state.general);
    const { profissionais } = useSelector((state: RootState) => state.profissionais);
    const [profsComPendencias, setProfsComPendencias] = React.useState([]);
    const [openModal, setOpenModal] = React.useState(false);
    const [pendenciaSelected, selectPendencia] = React
        .useState<{pendencia :any, index: number | null}>({pendencia: null, index: null});

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (profissionais.length > 0) {
            setProfsComPendencias(profissionais.filter((prof) => {
                if (prof.pendencias) {
                    console.log(prof.pendencias);
                    return prof.pendencias.length > 0;
                }
                return false;
            }));
        }
    }, [profissionais]);

    return (
        <div className={"pendencias_container"}>
            <AdministradorTopbar pageSelected={"pendencias"} />
            <ModalPagarPendencia
                open={openModal}
                close={() => setOpenModal(false)}
                pendenciaSelected={pendenciaSelected.pendencia} pendenciaIndex={pendenciaSelected.index} />
            {
                profsComPendencias.map((prof : any) => (
                    <div className={"prof_pendencias"}>
                        <h2>{prof.nome}</h2>
                        {prof.pendencias.map((pendencia, index) => (
                            <div className={"pendencia"}>
                                <h4>Taxa de {pendencia.tipo} - {
                                  moment(pendencia.agendamento.data).format("DD/MM/YYYY")
                                } {pendencia.agendamento.sala.nome
                                } às {pendencia.agendamento.hora_inicio}h até
                                    às {pendencia.agendamento.hora_fim}h
                                    <Button
                                        onClick={() => {
                                            selectPendencia({
                                                pendencia: pendencia,
                                                index: index,
                                            });
                                            setOpenModal(true);
                                        }}
                                        className={"button_pagar"}
                                        text={"Pagar"}
                                        width={"130px"}/>
                                    {
                                        checkIfAdm(userLogged) ? <Button
                                            onClick={() => apagarPendencia(index, prof, dispatch)}
                                            className={"button_pagar"}
                                            text={"Apagar"}
                                            width={"130px"}
                                        /> : <></>
                                    }
                                </h4>
                            </div>
                        ))}
                    </div>
                ))
            }
        </div>
    );
};

export default PendenciasPage;
