import React from 'react';
import ModalParent from '../../../../../assets/component/modals/modal_parent/modal';
import InputText from '../../../../../assets/component/inputText/input';
import Button from '../../../../../assets/component/button/button';
import FinanceiroDAO, { Financeiro } from '../../../../../DAO/financeiroDAO';
import {toast, ToastContainer} from 'react-toastify';
import {useDispatch} from 'react-redux';
import { ActionsFn } from '../../../../../redux/actions/actions';

const ModalTransferir = (props: {show: boolean, close: () => void}) => {

    const dispatch = useDispatch();

    const showToastLoading = (text) => {
        toast.warn(text, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };



    const showToastSuccess = (text) => {
        toast.success(text, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const onSubmit = async e => {
        e.preventDefault();
        const form = e.target;
        let dados : Financeiro = {
            data: new Date(),
            tipo: 'SAIDA',
            formaPgto: 'especie',
            quantidade: 1,
            titulo: "TRANSF. CAIXA",
            pagadorRecebedor: "Clínica Integra",
            valor: Number(form.valor.value),
        }

        showToastLoading("Carregando: Transferência de Espécie para Caixa Eletrônico")

        props.close();

        try {
            await FinanceiroDAO.create({ ...dados, tipo: "SAIDA", formaPgto: "especie" });
            await FinanceiroDAO.create({ ...dados, tipo: "ENTRADA", formaPgto: "transf" });
            let transacoes = await FinanceiroDAO.findAll();
            dispatch(ActionsFn.setTransacoes(transacoes));
            showToastSuccess("Sucesso: Transferência de Espécie para Caixa Eletrônico")
        } catch (e) {
            showToastLoading("ERRO: Falha ao realizar Transferência");
        }
    }

    return (
        <ModalParent
            onSubmit={onSubmit}
            show={props.show}
            header={
                <header>
                    <div>
                        <h1>Transferir para Caixa Eletrônico</h1>
                    </div>
                    <div className={'close_container'} onClick={props.close}>
                        <i className={'fa fa-times'} />
                    </div>
                </header>
            }
            body={
                <div>
                    <ToastContainer />
                    <InputText
                        label={"Valor a Transferir"}
                        name="valor"
                        step={"0.01"}
                        decoration="R$"
                        type="number" />
                </div>
            }
            footer={<div className="footer">
                <Button text="Confirmar" type="submit"></Button>
            </div>}
        />
    );
};

export default ModalTransferir;
