import LeadDAO from "../../../DAO/LeadDAO";
import moment from "moment";

export const getLeadsAndSetToState = (setLeads) => {
    LeadDAO.findAll().then(res => {
        for (let i = 0; i < res.length; i++) {
            if (!("estado" in res[i])) {
                res[i]["estado"] = "pendente"
            }
        }
        setLeads(res.sort(sortLeadByDateFunc));
    })
}

export const sortLeadByDateFunc = (a : any, b : any) => {
    if ("data" in b && "data" in a) {
        if (moment(a.data).isBefore(b.data)) {
            return 1;
        } else {
            return -1;
        }
    }
    return -1;
}
