import React from 'react';
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from  "@amcharts/amcharts5/xy"
import {getProfessionalsQuantity} from "../utils";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/storeTypes";
import moment from "moment";
import am5_Animated from "@amcharts/amcharts5/themes/Animated";

const log = console.log

const ChartProfessionals: React.FC = () => {

    const agendamentosTotais = useSelector((state : RootState) => state.agendamentos.agendamentos);

    const [data, setData] = React.useState({inicio: moment().subtract(30, "days"), fim: moment()})

    React.useEffect(() => {

        if (agendamentosTotais.length > 0) {
            let chartData = getProfessionalsQuantity(agendamentosTotais, data.inicio, data.fim)
                .sort((a, b) => {
                    if (a.value > b.value) {
                        return 1
                    } else {
                        return -1
                    }
                })

            let root = am5.Root.new("chart_professionals");

            root.setThemes([am5_Animated.new(root)]);

            let chart = root.container.children.push(am5xy.XYChart.new(root, {}));
            let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 5 });
            xRenderer.labels.template.setAll({
                rotation: -90,
                centerY: am5.p100,
                centerX: am5.p100,
                paddingRight: 10,
                fontSize: 12,
            });
            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, { //@ts-ignore
                    renderer: am5xy.AxisRendererY.new(root, {}),
                    min: 0,
                    tooltip: am5.Tooltip.new(root, {})
                })
            );
            let xAxis = chart.xAxes.push(
                am5xy.CategoryAxis.new(root, { //@ts-ignore
                    renderer: xRenderer,
                    categoryField: "professional",
                    tooltip: am5.Tooltip.new(root, {})
                })
            );
            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineY.set("visible", false);
            chart.set("scrollbarX", am5xy.XYChartScrollbar.new(root, { orientation: "horizontal" }));
            xAxis.data.setAll(chartData);
            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: "series",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "professional",
                tooltip: am5.Tooltip.new(root, { labelText: "{professional}\nHoras: {value}"})
            }));
            series.columns.template.setAll({ cornerRadiusTL: 2, cornerRadiusTR: 2 });
            series.data.setAll(chartData)
            return () => { root.dispose() }
        }
    }, [agendamentosTotais, data]);

    const onSubmit = (e) => {
        e.preventDefault()
        const form = e.target;
        let [inicio, fim] = [moment(form.data_inicio.value), moment(form.data_final.value)];
        setData({inicio, fim});
    }

    return (
        <div className={"chart_professionals_agendamentos"}>
            <form onSubmit={onSubmit}>
                <div>
                    <label>Data Inicial</label>
                    <input name={"data_inicio"} defaultValue={data.inicio.format("YYYY-MM-DD")} type={"date"} />
                </div>
                <div>
                    <label>Data Final</label>
                    <input name={"data_final"} defaultValue={data.fim.format('YYYY-MM-DD')} type={"date"} />
                </div>
                <button className={"button"}>Filtrar</button>
            </form>
            <div id={"chart_professionals"} style={{width: "100%"}}/>
        </div>
    );
};

export default ChartProfessionals;
