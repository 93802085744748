import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import InputText from "../../../../../assets/component/inputText/input";
import Select from "react-select"
import "./DiaAtendimento.sass";

const DiaAtendimento = ({dia, atendimentos, setAtendimentos}) => {

    const [horaInicio, setHoraInicio] = React.useState('');
    const [horaFim, setHoraFim] = React.useState('');

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: 120
        }),
        menu: (provided) => ({...provided})
    }

    const d = (horaInicio) => {
        if (horaInicio) {
            let val = 20 - horaInicio.option.split(":")[0]
            let arr = new Array(val).fill(0)
            let aux = arr.map((_, i) => {
                let vai = (+horaInicio.option.split(":")[0] + i + 1) + ":00";
                return {label: vai, option: vai};
            });
            return aux;
        } else {
            return [];
        }

    }

    return (
        <React.Fragment>

            {
                atendimentos[dia].map((valor, index) => (
                    <div style={{display: 'flex', marginLeft: "60px", alignItems: "center"}}>
                        <InputText
                            type={'text'}
                            onChange={(e) => setHoraInicio(e.target.value)}
                            style={{width: '10%'}}
                            disabled={true}
                            value={valor.inicio}
                            defaultValue={valor.inicio}
                            inputStyle={{textAlign: 'center', fontSize: '19px'}} label={''} />
                        &nbsp; às &nbsp; <InputText style={{width: '10%'}}
                                                    type={'text'}
                                                    defaultValue={valor.fim}
                                                    disabled={true}
                                                    value={valor.fim}
                                                    onChange={(e) => setHoraFim(e.target.value)}
                                                    inputStyle={{textAlign: 'center', fontSize: '19px'}} label={''}/>
                        <i className={'fa fa-trash'} onClick={() => {
                            let newAtendimentos = {...atendimentos};
                            newAtendimentos[dia].splice(index, 1);
                            setAtendimentos(newAtendimentos);
                            setHoraInicio('')
                            setHoraFim('')
                        }}/>
                    </div>
                ))
            }

            <div style={{display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 15, paddingBottom: 25, borderBottom: "1px solid #CCC"}}>
                <h2 style={
                    {
                        marginBottom: 0,
                        marginTop: 0,
                        width: 60,
                        textTransform: "uppercase",
                        fontSize: '1em'
                    }
                }>
                    {dia}
                </h2>
                <div style={{display: 'flex', alignItems: "center"}}>
                    <Select
                        styles={customStyles}
                        value={horaInicio}
                        onChange={e => {
                            console.log(e.option)
                            setHoraInicio(e)
                            d(e)
                        }}
                        options={new Array(13).fill(0).map((_, i) => ({
                            label: (i + 8) + ":00", option: (i + 8) + ":00"
                        }))}/> &nbsp; às &nbsp;
                    <Select
                        styles={customStyles}
                        value={horaFim}
                        onChange={e => {
                            setHoraFim(e)
                            let newAtendimentos = {...atendimentos};
                            newAtendimentos[dia].push({inicio: horaInicio.option, fim: e.option});
                            setAtendimentos(newAtendimentos);
                            console.log(newAtendimentos);
                            setHoraInicio(null);
                            setHoraFim(null);
                        }}
                        options={ d(horaInicio)
                            // new Array(13).fill(0).map((_, i) => ({
                            // label: (i + 8) + ":00", option: (i + 8) + ":00"}))
                        }/>
                </div>

            </div>
        </React.Fragment>
    )
}

DiaAtendimento.propTypes = {
    dia: PropTypes.string,
    atendimentos: PropTypes.object,
    setAtendimentos: PropTypes.func,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(DiaAtendimento)
