import { RemoteMongoDatabase } from 'mongodb-stitch-browser-sdk';
import {ObjectId} from "mongodb-stitch-browser-sdk/dist/browser/stitch";

const COLLECTION = 'salas';

export interface Sala {
  _id?: string | ObjectId,
  nome: string;
  descricao: string;
  valor_hora: number;
  fotos: Array<string>;
}

interface salaDAO {
  db: RemoteMongoDatabase | null;
  AWSAccess: boolean;
  setAccess: any;
  setDb: any;
  create: any;
  update: any;
  delete: any;
  findAll: any;
  getSalaById: (id: ObjectId, salas: Array<Sala>) => Sala | null;
  setSalasInAgendamentos: any;
}

const salaDAO: salaDAO = {
  db: null,
  AWSAccess: false,
  setAccess(access) {
    //@ts-ignore
    this.AWSAccess = access
  },
  setDb(db) {
    this.db = db;
  },
  create(sala) {
    if (this.db) {
      return this.db.collection(COLLECTION).insertOne(sala);
    }
  },
  update(query, changes) {
    if (this.db) {
      return this.db
        .collection(COLLECTION)
        .updateMany(query, { $set: changes });
    }
  },
  delete(query) {
    if (this.db) {
      return this.db.collection(COLLECTION).deleteMany(query);
    }
  },
  findAll() {
    if (this.db) {
      return this.db.collection(COLLECTION).find({}).toArray();
    }
  },
  getSalaById(id, salas) {
    for (const sala of salas) {
      if (sala._id.toString() === id.toString()) {
        return sala;
      }
    }
    return null;
  },
  setSalasInAgendamentos(salas, agendamentos) {
    for (const agendamento of agendamentos) {
      for (const sala of salas) {
        if (sala._id.toString() === agendamento.sala_id.toString()) {
          agendamento['sala'] = sala;
        }
      }
    }
  },
};
export default salaDAO;
