import React from 'react'
import {createChartData} from "../utils";
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy";
import am5_Animated from "@amcharts/amcharts5/themes/Animated";

const Chart = props => {

    React.useEffect(() => {
        //Cria array com datas
        let array = [];
        let result = createChartData(array, props.agendamentos);
        console.log(result);
        let root = am5.Root.new("chart_agendamentos");
        root.setThemes([am5_Animated.new(root)]);
        let chart = root.container.children.push(am5xy.XYChart.new(root, {}));
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        let scrollbarX = am5xy.XYChartScrollbar.new(root, { orientation: "horizontal" })
        chart.set("scrollbarX", scrollbarX);

        cursor.lineY.set("visible", false);
        let xAxis = chart.xAxes.push(
            am5xy.GaplessDateAxis.new(root, {
                baseInterval: { timeUnit: "day", count: 1 },
                renderer: am5xy.AxisRendererX.new(root, {})
            })
        );
        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {}),
            }));
        let series = chart.series.push(am5xy.LineSeries.new(root, {
            name: "series",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
                labelText: "{valueY}\n{date.formatDate('dd/MM/yyyy')}"
            })
        }))
        series.data.setAll(result);
        series.appear()
        chart.appear()

        return (() => root.dispose());
    }, [props.agendamentos]);

    return (
        <div className={'agendamentos_chart_container'}>
            <h2>Agendamentos</h2>
            <div id={'chart_agendamentos'}/>
        </div>
    )
}

export default Chart;
