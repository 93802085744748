import React from 'react';
import './styles.sass';
import {Redirect} from 'react-router-dom';

interface Props {
    selectedIndex: number,
    elements: Array<{ name: string, onClick: () => void }>
}

const AlternatingTab: React.FC<Props> = ({selectedIndex, elements}) => {

    React.useEffect(() => {
        console.log(selectedIndex)
    }, [ selectedIndex ])
    return (
        <div className={'alternating_tabs'}>
            {elements.map((element, index) => (
                <div
                    key={index}
                    className={index === selectedIndex ? 'selected' : ''}
                    onClick={element.onClick}
                >
                    <h2>{element.name}</h2>
                    {index === 0 && <div className={"beta_tag"}>BETA</div>}
                </div>
            ))}
            {selectedIndex === 0 ? <Redirect to="reserva_dia"/> : <></>}
            {selectedIndex === 1 ? <Redirect to="minhas_reservas"/> : <></>}
            {selectedIndex === 2 ? <Redirect to="dash"/> : <></>}
        </div>
    );
};

export default AlternatingTab;
