import React from 'react';
import ModalParent from '../../modal_parent/modal';
import {connect, useDispatch, useSelector} from 'react-redux';
import Moment from 'moment';
import "moment/locale/pt-br"
import './EditAgendamento.sass';
import reservaDAO from '../../../../../DAO/reservaDAO';
import Actions from '../../../../../redux/actions/actions';
import {extendMoment} from 'moment-range';
import {ToastContainer} from 'react-toastify';
import Pagamento from './components/Pagamento/Pagamento';
import TrocarSala from "./components/TrocarSala/TrocarSala";
import Execucao from "./components/Execucao/Execucao";
import Cancelamento from "./components/Cancelamento/Cancelamento";

const moment = extendMoment(Moment);

const ModalEditAgendamento = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [primeiro, setPrimeiro] = React.useState(false);

  const dispatch = useDispatch();

  const agendamentoSelected = useSelector(
      (state) => state.agendamentos.agendamentoSelected
  );

  const setSubtitle = () => {
    if ('profissional' in agendamentoSelected) {
      return (
          agendamentoSelected.profissional.nome +
        ' - ' +
        moment(props.dateSelected).locale('pt-BR').format('DD MMMM [de] YYYY') +
        ' - ' +
        agendamentoSelected.sala.nome
      );
    }
    return '';
  };

  const agendamentos = useSelector((state) => state.agendamentos.agendamentos);

  React.useEffect(() => {
    if (agendamentoSelected && agendamentos) {
      if (agendamentos.length > 0 && '_id' in agendamentoSelected) {
        let reservasDoProfissional = agendamentos.filter((agendamento) => {
          if (agendamento.cancelado) {
            return false;
          }
          return agendamento.profissional_id.toString() === agendamentoSelected.profissional_id.toString();
        });
        setPrimeiro(reservasDoProfissional.length === 1);
        dispatch({
          type: Actions.selectAgendamentos,
          payload: reservaDAO.getAgendamentosById(
              agendamentos,
              agendamentoSelected._id
          ),
        });
      }

    }
  }, [agendamentos, agendamentoSelected]);

  //Modal para fazer trocar pagamento, execução ou cancelar.
  if (props.agendamentoSelected) {
    return (
        <React.Fragment>
          <ModalParent
          show={props.show}
          header={
            <header>
              <div>
                <h1>Editar Reserva</h1>
                <h3>{setSubtitle()}</h3>
              </div>
              <div className={'close_container'} onClick={props.close}>
                <i className={'fa fa-times'} />
              </div>
            </header>
          }
          body={
            <div className={'body_edit_agendamento'}>
              {
                props.isClient ? <React.Fragment>
                  <Cancelamento tipo={"cliente"} close={props.close}/>
                </React.Fragment> : <React.Fragment>
                  {
                    (props.userLogged.nome === "Brian Ito de Oliveira Moura") && ('_id' in agendamentoSelected) ?
                        <React.Fragment>
                          <h2>Id:</h2>
                          <p>{agendamentoSelected._id.toString()}</p></React.Fragment> : <></>
                  }
                  {primeiro && <p style={{color: "red"}}>É o primeiro agendamento deste profissional.</p>}
                  <TrocarSala/>
                  <Execucao/>
                  <Cancelamento tipo={"adm"} close={props.close}/>
                  <Pagamento close={props.close} isLoading={loading} setIsLoading={setLoading}/>
                </React.Fragment>
              }

            </div>
          }
          footer={<div className={'footer footer_edit_agendamento'} />}
        />
        <ToastContainer />
      </React.Fragment>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state) => ({
  agendamentoSelected: state.agendamentos.agendamentoSelected,
  dateSelected: state.general.dateSelected,
  mongoClient: state.general.mongoClient,
  salas: state.salas.salas,
  userLogged: state.general.userLogged,
  agendamentos: state.agendamentos.agendamentos,
});

const mapDispatchToProps = (dispatch) => ({
  setProfissionais: (prof) =>
    dispatch({ type: Actions.setProfissionais, payload: prof }),
  setSalas: (salas) => dispatch({ type: Actions.setSalas, payload: salas }),
  setAgendamentos: (agnds) =>
    dispatch({ type: Actions.setAgendamentos, payload: agnds }),
  selectAgendamentos: (agnd) =>
    dispatch({ type: Actions.selectAgendamentos, payload: agnd }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalEditAgendamento);
