import React from 'react';
import AdministradorTopbar from '../../../assets/component/adm_topbar/adm_topbar';
import ModalAddFinanceiro from '../../../assets/component/modals/administrativo/modal_add_financeiro/ModalAddFinanceiro';
import Fab from './components/Fab';
import "./Financeiro.sass";
import {useSelector, useDispatch} from 'react-redux';
import { ActionsFn } from '../../../redux/actions/actions';
import FinanceiroTitulosDAO, { Titulo } from '../../../DAO/financeiroTitulosDAO';
import { RootState } from '../../../redux/storeTypes';
import {Redirect} from 'react-router-dom';
import FinanceiroDAO, { Financeiro } from '../../../DAO/financeiroDAO';
import moment from 'moment';
import EntradaSaida from "./components/EntradaSaida";
import Button from '../../../assets/component/button/button';
import ModalAddCredito from '../../../assets/component/modals/administrativo/modal_add_credito/ModalAddCredito';
import ModalTransferir from './components/ModalTransferir/ModalTransferir';
import { CSVLink } from 'react-csv'
import Filtros from "./components/Filtros/Filtros";

const transformFloatToStringReais = (floatNumber) => {
    let string = floatNumber.toFixed(2).toString();
    return "R$ " + string.replace(".", ",");
}

const dinheiroEspecie = (transacoes: Financeiro[]) => {
    return transacoes
        .filter((transacao) => transacao.formaPgto === "especie")
        .map((transacao) => transacao.tipo === "ENTRADA" ? (transacao.valor * transacao.quantidade) :
         - (transacao.valor * transacao.quantidade))
        .reduce((a, b) => a + b, 0);
}

const caixaEletronico = (transacoes: Financeiro[]) => {
    return transacoes
        .filter((transacao) => transacao.formaPgto !== "especie")
        .map((transacao) => transacao.tipo === "ENTRADA" ?
            (transacao.valor * transacao.quantidade) : (-transacao.valor * transacao.quantidade))
        .reduce((a, b) => a + b, 0);
}

const fluxoDia = (transacoes: Financeiro[]) => {
    return transacoes
        .filter((transacao) => moment(new Date(transacao.data)).isSame(new Date(), 'day'))
        .map(({tipo, valor, quantidade}) => tipo === "ENTRADA" ?
        (valor * quantidade) : (-valor * quantidade))
        .reduce((a, b) => a + b, 0)
}

const IndicatorCard = ({name, value}) => (
    <div className={"indicator_card"}>
        <h2>{name}</h2>
        <h1>{transformFloatToStringReais(value)}</h1>
    </div>
)

const TituloComponent = ({name, value, setShowModal, setModalType, setEditing, titulo}) => {

    const dispatch = useDispatch();

    return (<div className={'titulo'}>
        <h2>{name}</h2>
        <p>{transformFloatToStringReais(value)}</p>
        <button className={'edit'} onClick={() => {
            setShowModal(true);
            setModalType('TITULO');
            setEditing(true);
            dispatch(ActionsFn.selectTitulo(titulo))
        }}>
            <i className='fa fa-edit'/>
        </button>
        <button className={'remove'}><i className={'fas fa-trash'}/></button>
    </div>)
}

export const adms = ["Brian Ito de Oliveira Moura", "Igor Santiago Martins", "Marcela de Souza Lobo"];

const FinanceiroPage = () => {

    const [showModal, setShowModal] = React.useState(false);
    const [modalType, setModalType] = React.useState<"ENTRADA"|"SAIDA"|"TITULO">("ENTRADA");
    const [showCredito, setShowCredito] = React.useState(false);
    const [showTransferir, setShowTransferir] = React.useState(false);
    const [pages, setPages] = React.useState<Array<any>>([])
    const [selectedPage, setPage] = React.useState(1)
    const [editing, setEditing]  = React.useState(false);
    const [paginated, setPaginated] = React.useState(true);

    const [filtered, setFiltered] = React.useState<Array<any>>([]);

    const dispatch = useDispatch();

    const { titulos, transacoes } = useSelector((state: RootState) => state.financeiro);
    const { userLogged, database } = useSelector((state: RootState) => state.general);

    const logsPerPage = 110;

    React.useEffect(() => {
        setPages(Array.from(Array(Math.ceil(filtered.length / logsPerPage)), (_, i) => i + 1))
    }, [filtered])

    React.useEffect(() => {
        if (FinanceiroTitulosDAO.db) {
            FinanceiroTitulosDAO.findAll().then((result: Titulo[]) => { dispatch(ActionsFn.setTitulos(result)) })
            FinanceiroDAO.findAll().then((result: Financeiro[]) => { dispatch(ActionsFn.setTransacoes(result)) })
        }
    }, [database])

    if ('nome' in userLogged) {
        return (
            <div className='financeiro_page'>
                <ModalAddFinanceiro key={"modalFinan"}
                    editing={editing}
                    show={showModal}
                    close={() => {
                        setEditing(false)
                        setShowModal(false)
                    }}
                    type={modalType} />

                <ModalAddCredito show={showCredito}
                    close={() => setShowCredito(false)} />

                <ModalTransferir show={showTransferir}
                    close={() => setShowTransferir(false)}/>

                <AdministradorTopbar pageSelected={'financeiro'} />
                <div className={'container_adms'}>
                    <div className={'indicators'}>
                        <IndicatorCard name={"Caixa em Espécie"} value={dinheiroEspecie(transacoes)} />
                        <IndicatorCard name={"Caixa Eletrônico"} value={caixaEletronico(transacoes)} />
                        <IndicatorCard name={"Fluxo do Dia"} value={fluxoDia(transacoes)} />
                    </div>
                    <div style={{display: 'flex'}}>
                        <div className={'titulos'}>
                            <h2>Títulos Cadastrados</h2>
                            {
                                titulos.length > 0 ?
                                    titulos.map((title: Titulo, index) => (
                                        <TituloComponent
                                        setEditing={setEditing}
                                        setModalType={setModalType}
                                        setShowModal={setShowModal}
                                        key={"title-" + index}
                                        titulo={title}
                                        value={title.valor}
                                        name={title.titulo} />
                                    ))
                                : <h2>Ainda não há Títulos Cadastrados...</h2>
                            }
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {
                                adms.includes(userLogged.nome) ?
                                    <Button
                                        onClick={() => setShowCredito(true)}
                                        text="Adicionar Créditos"
                                        className="finances"/>
                                    : <></>

                            }
                            <Button
                                onClick={() => setShowTransferir(true)}
                                text="Transferir para Caixa Eletrônico"
                                className="finances"/>
                        </div>
                    </div>
                    <div style={{marginTop: 20}}>
                    <Button
                        width={'30%'}
                        onClick={() => setPaginated(!paginated)}
                        text={'Ver '+ (paginated ? 'sem ' : 'com ') + 'Paginação'}/>
                    </div>
                    <Filtros setFilter={(filtered) => setFiltered(filtered)} />
                    <h2>Fluxo de Caixa <span
                        className="export">
                        <a
                            href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                JSON.stringify(transacoes.map(transacao => (
                                    {...transacao, data: moment(transacao.data).format('DD/MM/YYYY HH:mm')}))
                                )
                            )}`}
                            download="filename.json"
                        >
                            {`Exportar JSON`}
                        </a> &nbsp;
                        <CSVLink
                            data={transacoes.map(transacao => {
                                return {...transacao, data: moment(new Date(transacao.data)).format('DD/MM/YYYY HH:mm')}
                            })} filename={"Financeiro" + moment().format('YYYY-MM-DD') + ".csv"}>Exportar CSV </CSVLink>
                    </span></h2>
                    <div className={'header'}>
                        <div>Nome do Título</div>
                        <div>Nota Fiscal</div>
                        <div>Pagador/Recebedor</div>
                        <div>Método</div>
                        <div>Quantidade</div>
                        <div>Valor Un.</div>
                        <div>Horário</div>
                        {
                            adms.includes(userLogged.nome) ? <div> &nbsp; </div> : <></>
                        }
                    </div>
                    <div className='rows'>
                    {
                        paginated ?
                            <React.Fragment>
                                {filtered
                            .sort((a, b) => { if (moment(new Date(a.data)).isBefore(new Date(b.data))) { return 1 } else { return -1 }})
                            .map((transacao, index) => (
                                index < (logsPerPage * selectedPage) && (index >= (logsPerPage * (selectedPage - 1))) ?
                                <EntradaSaida data={transacao} /> : <></>)) }
                                <div className={'pages'}>
                                    {pages.map((page, index) => ((index < selectedPage + 5 && (index > selectedPage - 5)) || index === pages.length - 1) ? <div
                                        className={page === selectedPage ? 'page selected' : 'page'}
                                        onClick={() => {
                                            setPage(page)
                                        }}>
                                        {page}
                                    </div> : <></>)}
                                </div>
                            </React.Fragment>
                            : filtered
                                .sort((a, b) => {
                                    if (moment(new Date(a.data))
                                        .isBefore(new Date(b.data))) { return 1 }
                                    else { return -1 }
                                })
                                .map((transacao, index) => <EntradaSaida data={transacao} key={"index-" + index} />)
                    }
                    </div>
                    <Fab setModalType={setModalType} showModal={setShowModal} />
                </div>
            </div>
        )
    } else {
        return <Redirect to='/'/>
    }

}

export default FinanceiroPage;
