import * as React from 'react';
import FinanceiroDAO, { Financeiro } from '../../../../DAO/financeiroDAO';
import moment from 'moment';
import InputText from '../../../../assets/component/inputText/input';
import {toast, ToastOptions} from 'react-toastify';
import {useSelector, useDispatch} from "react-redux";
import {ActionsFn} from "../../../../redux/actions/actions";
import {RootState} from "../../../../redux/storeTypes";
import {adms} from "../Financeiro";

const transformFloatToStringReais = (floatNumber) => {
    let string = floatNumber.toFixed(2).toString();
    return "R$ " + string.replace(".", ",");
}

const EntradaSaida = (props: { data: Financeiro }) => {

    const notaRef = React.useRef();

    const showToastError = (text) => {
        toast.error(text, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showToastLoading = (text) => {
        toast.warn(text, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showToastSuccess = (text) => {
        toast.success(text, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const formaPgtoString = {
        especie: "Espécie",
        debito: "Débito",
        transf: "Transferência",
        credito: "Crédito",
        cortesia: "Cortesia",
    }

    const dispatch = useDispatch()

    const { userLogged } = useSelector((state : RootState) => state.general);

    const onSubmitNota = async e => {

        const toastOptions : ToastOptions = {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        }

        const showToastLoading = (text) => {
            toast.warn(text, toastOptions);
        };

        const showToastSuccess = (text) => {
            toast.success(text, toastOptions);
        };

        e.preventDefault();
        const form = e.target;
        try {
            showToastLoading("Adicionando NF a transação de "+props.data.pagadorRecebedor);
            await FinanceiroDAO.editFinanceiro(props.data._id, { nf: form.nota.value })
            // @ts-ignore
            notaRef.current.value = "";
            let financeiro = await FinanceiroDAO.findAll()
            dispatch(ActionsFn.setTransacoes(financeiro))
            showToastSuccess("NF a transação de " + props.data.pagadorRecebedor+" adicionada!");
        } catch(e) {
            showToastError("Erro ao adicionar NF a transação de " + props.data.pagadorRecebedor);
        }
    }

    return (
        <div className={'row'}>
            <div className={'date_written'}>
                <p>{moment(new Date(props.data.data)).format("DD MMM YYYY")}</p>
            </div>
            <div className={'date'}>
                <div className={'bar'}></div>
            </div>
            <div className={'row_container'}>
                <div className={props.data.tipo === "SAIDA" ? 'type saida' : (props.data.quantidade === 0 ? 'type neutral' : 'type')} />
                <p>{props.data.titulo}</p>
                <div style={{ flex: '0.3'}}><form onSubmit={onSubmitNota}>
                    {/*@ts-ignore*/}
                    <InputText key={props.data.nf} ref={notaRef} defaultValue={props.data.nf} label='Nota Fiscal' name='nota'/>
                </form></div>
                <p>{props.data.pagadorRecebedor}</p>
                <p>{formaPgtoString[props.data.formaPgto]}</p>
                <p>{props.data.quantidade}</p>
                <p>{transformFloatToStringReais(props.data.valor)}</p>
                <p>{moment(new Date(props.data.data)).format("HH:mm")}</p>
                {
                    adms.includes(userLogged.nome) ? <p><i
                        onClick={async () => {
                            if (window.confirm("Você tem certeza que deseja remover esse registro?\nEssa ação é irreversível.")) {
                                showToastLoading("Carregando: Remover registro do financeiro de "+props.data.pagadorRecebedor);
                                await FinanceiroDAO.deleteFinanceiro(props.data._id);
                                let financeiro = await FinanceiroDAO.findAll();
                                dispatch(ActionsFn.setTransacoes(financeiro));
                                showToastSuccess("Registro financeiro de "+props.data.pagadorRecebedor + " removido com sucesso.");
                            }
                        }}
                        className={'fas fa-trash'} /></p> : <></>
                }
            </div>
        </div>
    )
}

export default EntradaSaida;
