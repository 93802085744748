import {Reserva} from "../../../../../../../DAO/reservaDAO";
import { Valor } from "./Pagamento";

export const checkHasCredits = (agendamentoSelected : Reserva) : boolean | undefined | number => {
    return agendamentoSelected.profissional &&
        agendamentoSelected.profissional.creditos &&
        (agendamentoSelected.profissional.creditos > 0);
}

export const checkHasSaldo = (agendamentoSelected : Reserva) : boolean | undefined | number => {
    return agendamentoSelected?.profissional?.saldo &&
        agendamentoSelected.profissional.saldo > 0;
}

export const transformFloatToStringReais = (floatNumber : number) => {
    let string = floatNumber.toFixed(2).toString();
    return "R$ " + string.replace(".", ",");
}

export const calculaTroco = (valor : Valor, valorTotal: number) => {
    if (valor.especie > 0) {
        return valor.totalAcumulado - valorTotal;
    }
    return 0;
}