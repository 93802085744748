import React from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import ModalParent from "../../../../../assets/component/modals/modal_parent/modal";
import FileInput from "../../../../../assets/component/file_input/FileInput";
import Button from "../../../../../assets/component/button/button";
import axios, {post} from "axios";
import {checkIfURLIsImage} from "../../../../../assets/AuxFunctions";
import clienteDAO from "../../../../../DAO/clienteDAO";
import Actions, {ActionsFn} from "../../../../../redux/actions/actions";
import {ObjectId} from "mongodb-stitch-browser-sdk/dist/browser/stitch";

const ModalTrocarFoto = ({ show, close }) => {

    const doUpload = async (file) => {
        let body = new FormData();
        body.append("key", "be99f9363711cd1670a38be926bb26f8")
        body.append("image", file)
        let { data } = await axios.post("https://api.imgbb.com/1/upload", body);
        return data
    }


    const [loading, setLoading] = React.useState(false);
    const [file, setFile] = React.useState(null);
    const [fileURL, setFileURL] = React.useState('');

    const dispatch = useDispatch();

    const fileUpload = async (file) => {
        const url = 'https://app.integracps.com.br/imageUpload.php';
        const formData = new FormData();
        formData.append('userfile', file);
        const config = {headers: {'content-type': 'multipart/form-data'}};
        return post(url, formData, config);
    }

    const { userLogged } = useSelector((state ) => state.general);
    const { profissionalSelected } = useSelector(state => state.profissionais);
    return (
        <ModalParent show={show} onSubmit={async e => {
            e.preventDefault();
            if (checkIfURLIsImage(fileURL)) {
                setLoading(true);
                try {
                    let fileURL = (await doUpload(file)).data.url;
                    if ("nome" in profissionalSelected) {
                        await clienteDAO.update({_id: profissionalSelected._id}, {
                            foto_url: fileURL,
                        });
                        let clientes = await clienteDAO.findAll();
                        console.log(clientes)
                        dispatch(ActionsFn.setProfissionais(clientes))
                    } else {
                        await clienteDAO.update({_id: userLogged._id}, {
                            foto_url: fileURL,
                        });
                        let user = await clienteDAO.find({_id: userLogged._id});
                        alert(JSON.stringify(user));
                        dispatch(ActionsFn.setUserLogged(user[0]))
                    }
                    setLoading(false);
                    alert('Foto editada com sucesso!')
                } catch (err) {
                    alert(err);
                }
            } else {
                alert('Informe uma imagem válida acima. Caso não queira adicionar uma imagem, deixe o campo em branco.');
            }
        }} header={<header>
            <div>
                <h1>Trocar Foto</h1>
            </div>
            <div className={'close_container'} onClick={close}>
                <i className={'fa fa-times'}/>
            </div>
        </header>}
                     body={<div>
                         <FileInput onChangeFile={(file, url) => {
                             setFile(file);
                             setFileURL(url);
                         }}
                                    urlName={'foto_url'}
                                    fileName={'userfile'}/>
                     </div>}
                     footer={<div className={'footer'}>
                         <Button loading={loading} type={'submit'} text={'Confirmar'}/>
                     </div>}
        />
    )
}

ModalTrocarFoto.propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func
}

export default ModalTrocarFoto
