const Actions = {
  setDatabase: 'SET_DATABASE',
  setMongoClient: 'SET_MONGO_CLIENT',
  setUserLogged: 'SET_USER_LOGGED',
  showModal: 'SHOW_MODAL',
  closeModal: 'CLOSE_MODAL',
  setProfissionais: 'SET_PROFISSIONAIS',
  setNotifications: 'SET_NOTIFICATIONS',
  setProfissionaisHash: 'SET_PROFISSIONAIS_HASH',
  setAWSData: 'SET_AWS',
  setAdministrativo: 'SET_ADMS',
  setSalas: 'SET_SALAS',
  selectDate: 'SELECT_DATE',
  selectSala: 'SELECT_SALA',
  selectAgendamentos: 'SELECT_AGENDAMENTO',
  setAgendamentos: 'SET_AGENDAMENTOS',
  selectTitulo: 'SELECT_TITULO',
  addAgendamento: 'ADD_AGENDAMENTO',
  selectAdministrador: 'SELECT_ADM',
  selectProfissional: 'SELECT_PROF',
  setLogs: 'SET_LOGS',
  setProfissionalReservas: 'SET_PROF_RESERVAS',
  setBloqueiosSalas: 'SET_BLOQ_SALAS',
  selectNotification: 'SELECT_NOTIFICATION',
  setFetchedAgendamentos: 'SET_FETCH',
  setTitulos: 'SET_TITULOS',
  setBirthdayOK: 'SET_BDAY_OK',
  setTransacoes: 'set_transacoes',
  setLoadingPagamento: 'set_loading_pagamento',
  setPosts: "setPosts",
  setPostSelected: "setPostSelected",
};

export const ActionsFn = {
  openModal: (open) => ({ type: Actions.showModal, payload: open }),
  closeModal: () => ({ type: Actions.closeModal }),
  setTitulos: (titulos) => ({type: Actions.setTitulos, payload: titulos}),
  setProfissionais: (prof) => ({
    type: Actions.setProfissionais,
    payload: prof,
  }),
  setPosts: (posts) => ({ type: Actions.setPosts, posts: posts}),
  setPostSelected: (post) => ({type: Actions.setPostSelected, postSelected: post}),
  selectTitulo: (titulo) => ({type: Actions.selectTitulo, payload: titulo}),
  setTransacoes: (transacoes) => ({
    type: Actions.setTransacoes,
    payload: transacoes,
  }),
  setProfissionaisHash: (hash) => ({
    type: Actions.setProfissionaisHash,
    payload: hash,
  }),
  setBirthdayOK: (ok) => ({
    type: Actions.setBirthdayOK,
    payload: ok,
  }),
  setLoadingPagamento: (loadingPagamento) => ({
    type: Actions.setLoadingPagamento,
    payload: loadingPagamento,
  }),
  setSalas: (salas) => ({ type: Actions.setSalas, payload: salas }),
  selectDate: (date) => ({ type: Actions.selectDate, payload: date }),
  setAgendamentos: (agendamentos) => ({
    type: Actions.setAgendamentos,
    payload: agendamentos,
  }),
  setLogs: (logs) => ({ type: Actions.setLogs, payload: logs }),
  setBloqueiosSalas: (bloqueios) => ({
    type: Actions.setBloqueiosSalas,
    payload: bloqueios,
  }),
  setUserLogged: (user) => ({ type: Actions.setUserLogged, payload: user }),
  setProfissionalReservas: (reservas) => ({
    type: Actions.setProfissionalReservas,
    payload: reservas,
  }),
  selectSala: (sala) => ({ type: Actions.selectSala, payload: sala }),
  setAdministrativo: (adms) => ({
    type: Actions.setAdministrativo,
    payload: adms,
  }),
  selectAgendamentos: (agendamentos) => ({
    type: Actions.selectAgendamentos,
    payload: agendamentos,
  }),
  selectProfissional: (prof) => ({
    type: Actions.selectProfissional,
    payload: prof,
  }),
  addAgendamento: (agnd) => ({
    type: Actions.addAgendamento,
    payload: agnd,
  }),
  setNotifications: (notifications) => ({
    type: Actions.setNotifications,
    payload: notifications,
  }),
  selectNotification: (notification) => ({
    type: Actions.selectNotification,
    payload: notification,
  }),
  setModalType: (modalType) => ({
    type: Actions.showModal,
    payload: modalType,
  }),
  setAWSData: (awsData) => ({
    type: Actions.setAWSData,
    payload: awsData,
  })
};

export default Actions;
