import React from 'react';
import PropTypes from 'prop-types';
import './CalendarAgendamentos.sass';
import {connect, useDispatch} from 'react-redux';
import Actions from '../../../redux/actions/actions';
import ModalTypes from '../../modal_types';
import {numberIsBetween} from '../../AuxFunctions';
import reservaDAO from '../../../DAO/reservaDAO';
import Moment from 'moment';
import "moment/locale/pt-br"
import {extendMoment} from 'moment-range';
import sala_bloqueioDAO from '../../../DAO/sala_bloqueioDAO';
import NovoAgendamento from './components/NovoAgendamento';
import {
    checkBloqueado,
    setClassStringByTheData,
} from './components/AuxFunctions';
import {days, fillHoras} from "./utils";
//@ts-ignore
const moment = extendMoment(Moment);

const CalendarAgendamentos = (props) => {

    const [isSunday, setSunday] = React.useState(false);
    const [horas, setHoras] = React.useState(fillHoras(props.dateSelected.getDay() === 6, props.dateSelected));
    const dispatch = useDispatch();

    React.useEffect(() => {
        sala_bloqueioDAO.findAll().then((res) => {
            dispatch({type: Actions.setBloqueiosSalas, payload: res})
        })
    }, []);

    React.useEffect(() => {
        setHoras(fillHoras(props.dateSelected.getDay() === 6))
        setSunday(moment(props.dateSelected).weekday() === 0)
    }, [props.dateSelected]);

    const onClickAgendamento = (agnd) => {
        if ((props.isClient && props.userLogged._id.toString() === agnd.profissional_id.toString()) || !props.isClient) {
            props.openModal(ModalTypes.editarAgendamento);
            props.selectAgendamentos(agnd);
        }
    }

    return (
        <div className={'calendar_agendamentos_container'}>
            <h1 style={{display: 'flex'}}>
                {(props.isClient && moment().isSame(props.dateSelected, 'day')) ? <></> : <span
                    onClick={() => props.selectDate(moment(props.dateSelected).subtract(1, 'day').toDate())}
                    className={'chevron_date'}
                    style={{float: 'left', margin: 'auto 0'}}
                >
          <i className={'fa fa-chevron-left'}/>
        </span>}
                <span
                    style={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        cursor: 'pointer',
                    }}
                    onClick={() => props.openModal(ModalTypes.selectDay)}
                >
          <span style={{margin: 0}}>
            {moment(props.dateSelected).locale('pt-BR').format(' DD MMMM YYYY')}
          </span>
          <span
              style={{
                  fontSize: 13,
                  color: '#CCC',
                  margin: 4,
                  fontWeight: 'normal',
                  position: 'sticky',
                  top: 0,
              }}
          >
            {days[props.dateSelected.getDay()]}
          </span>
        </span>
                {(props.isClient && moment(props.dateSelected).isSame(moment().add(14, 'days'), 'day')) ? <></> : <span
                    onClick={() => props.selectDate(moment(props.dateSelected).add(1, 'day').toDate())}
                    className={'chevron_date'}
                    style={{float: 'left', margin: 'auto 0'}}
                >
          <i className={'fa fa-chevron-right'}/>
        </span>}
            </h1>
            <div className={'container_table'}>
                <table className={'calendar_table'}>
                    {/*aqui vai a numeracao das salas*/}
                    <thead>
                    <tr>
                        <th style={{width: '10%'}}> &nbsp; </th>
                        {props.salas.map((sala, index) => (
                            <th key={index}>{sala.nome}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {
                        //A tabela é composta por horas x salas;
                        horas.map((hora, index) => (
                            <tr key={index}>
                                <td>{hora.label}</td>
                                {/* Início da declaração das salas */}
                                {props.salas.map((sala, indexSala) => {
                                    // SE for domingo cancela tudo
                                    if (isSunday) {
                                        return (<td
                                            className={'alugado'}
                                            key={`${indexSala} ${index}`}
                                        >
                                            <i>Bloqueada</i>
                                        </td>)
                                    }
                                    // A função abaixo recebe todos os agendamentos da sala.
                                    let agendamentosDaSala = reservaDAO.getAgendamentosFromSala(
                                        props.agendamentos,
                                        sala
                                    );
                                    let [isOccupied, isMonthly] = [false, false];
                                    let agnd = null;
                                    // Cada agendamento é verificado em cada bloco de horas.
                                    agendamentosDaSala.forEach((agendamento) => {
                                        if ('hora_inicio' in agendamento) {
                                            if (
                                                numberIsBetween(
                                                    hora.value,
                                                    agendamento.hora_inicio,
                                                    agendamento.hora_fim
                                                ) &&
                                                moment(props.dateSelected).isSame(
                                                    new Date(agendamento.data),
                                                    'day'
                                                ) &&
                                                !agendamento.cancelado
                                            ) {
                                                isOccupied = true;
                                                agnd = agendamento;
                                            }
                                            // Verificação de aluguéis mensais.
                                        } else if (
                                            'mes' in agendamento &&
                                            moment(agendamento['mes']).isSame(
                                                props.dateSelected,
                                                'month'
                                            )
                                        ) {
                                            isMonthly = true;
                                        }
                                    });
                                    if (!isOccupied) {
                                        if (isMonthly) {
                                            return (
                                                <td
                                                    key={`${indexSala} ${index}`}
                                                    className={'alugado'}
                                                >
                                                    <i>Alugado Mensalmente</i>
                                                </td>
                                            );
                                        } else if (
                                            checkBloqueado(
                                                props.bloqueiosSalas,
                                                sala,
                                                hora.value,
                                                props.dateSelected
                                            )
                                        ) {
                                            return (
                                                <td
                                                    className={'alugado'}
                                                    key={`${indexSala} ${index}`}
                                                >
                                                    <i>Bloqueada</i>
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <NovoAgendamento
                                                    isClient={props.isClient}
                                                    key={`${indexSala} ${index}`}
                                                    sala={sala}
                                                />
                                            );
                                        }
                                    } else if (agnd) {
                                        if (isMonthly) {
                                            return (
                                                <td
                                                    key={`${indexSala} ${index}`}
                                                    className={'occupied'}
                                                > {/*@ts-ignore*/}
                                                    {agnd ? ( //@ts-ignore
                                                        agnd.profissional && !props.isClient ? (//@ts-ignore
                                                            agnd.profissional.nome
                                                        ) : (
                                                            <i>{props.isClient ?  (setClassStringByTheData(agnd, props.isClient, props.userLogged) !== 'cinza' ? 'Minha reserva' : 'Reservado') : 'Usuário Excluído'}</i>
                                                        )
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                            );
                                        } else {
                                            if ( //@ts-ignore
                                                agnd.hora_inicio === hora.value &&
                                                !('horasCanceladas' in agnd)
                                            ) {
                                                return (
                                                    <td
                                                        onClick={() => onClickAgendamento(agnd)}
                                                        key={`${indexSala} ${index}`}
                                                        rowSpan={
                                                            //@ts-ignore
                                                            agnd.hora_fim - agnd.hora_inicio}
                                                        className={
                                                            'occupied ' + setClassStringByTheData(agnd, props.isClient, props.userLogged)
                                                        }
                                                    >
                                                        {agnd ? ( /*@ts-ignore*/
                                                            agnd.profissional && !props.isClient ? (/*@ts-ignore*/
                                                                agnd.profissional.nome
                                                            ) : (
                                                                <i>{props.isClient ?  (setClassStringByTheData(agnd, props.isClient, props.userLogged) !== 'cinza' ? 'Minha reserva' : 'Reservado') : 'Usuário Excluído'}</i>
                                                            )
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                );/*@ts-ignore*/
                                            } else if (agnd.cancelado) {
                                                return (
                                                    <NovoAgendamento
                                                        isClient={props.isClient}
                                                        key={`${indexSala} ${index}`}
                                                        sala={sala}
                                                    />
                                                );
                                            }
                                            // LEGACY ----------- FAVOR NÃO EXCLUIR, apesar de parecer que precise.
                                            else if ('horasCanceladas' in agnd) {
                                                if (/*@ts-ignore*/
                                                    agnd.hora_inicio === hora.value &&/*@ts-ignore*/
                                                    agnd.hora_inicio < agnd.horasCanceladas[0]
                                                ) {
                                                    return (
                                                        <td
                                                            key={`${indexSala} ${index}`}
                                                            onClick={() => onClickAgendamento(agnd)}
                                                            rowSpan={/*@ts-ignore*/
                                                                agnd.horasCanceladas[0] - agnd.hora_inicio
                                                            }
                                                            className={
                                                                'occupied ' + setClassStringByTheData(agnd, props.isClient, props.userLogged)
                                                            }
                                                        >
                                                            {agnd ? (/*@ts-ignore*/
                                                                agnd.profissional && !props.isClient ? (/*@ts-ignore*/
                                                                    agnd.profissional.nome
                                                                ) : (
                                                                    <i>Usuário Excluído</i>
                                                                )
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                    );
                                                }
                                                if (
                                                    numberIsBetween(
                                                        hora.value,/*@ts-ignore*/
                                                        agnd.horasCanceladas[0],/*@ts-ignore*/
                                                        agnd.horasCanceladas[1]
                                                    )
                                                ) {
                                                    return (
                                                        <NovoAgendamento
                                                            key={`${indexSala} ${index}`}
                                                            sala={sala}
                                                            isClient={props.isClient}
                                                        />
                                                    );
                                                } else if ( //@ts-ignore
                                                    agnd.horasCanceladas[1] === hora.value &&//@ts-ignore
                                                    agnd.horasCanceladas[1] !== agnd.hora_fim
                                                ) {
                                                    return (
                                                        <td
                                                            key={`${indexSala} ${index}`}
                                                            onClick={() => onClickAgendamento(agnd)}
                                                            rowSpan={
                                                                //@ts-ignore
                                                                agnd.hora_fim - agnd.horasCanceladas[1]}
                                                            className={
                                                                'occupied ' + setClassStringByTheData(agnd, props.isClient, props.userLogged)
                                                            }
                                                        >
                                                            {agnd ? (
                                                                //@ts-ignore
                                                                agnd.profissional && !props.isClient ? (
                                                                    //@ts-ignore
                                                                    agnd.profissional.nome
                                                                ) : (
                                                                    <i>Usuário Excluído</i>
                                                                )
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                    );
                                                }
                                                // FIM DO LEGACY ----------- FAVOR NÃO EXCLUIR, apesar de parecer que precise.
                                            } else {
                                                return null;
                                            }
                                        }
                                    }
                                })}
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

CalendarAgendamentos.propTypes = {
    agendamentos: PropTypes.array,
    isClient: PropTypes.bool
};

const mapStateToProps = (state) => ({
    salas: state.salas.salas,
    userLogged: state.general.userLogged,
    agendamentos: state.agendamentos.agendamentos,
    dateSelected: state.general.dateSelected,
    bloqueiosSalas: state.salas.bloqueiosSalas,
});

const mapDispatchToProps = (dispatch) => ({
    selectDate: (date) => dispatch({type: Actions.selectDate, payload: date}),
    openModal: (open) => dispatch({type: Actions.showModal, payload: open}),
    setSalas: (salas) => dispatch({type: Actions.setSalas, payload: salas}),
    setBloqueiosSalas: (bloqueios) =>
        dispatch({type: Actions.setBloqueiosSalas, payload: bloqueios}),
    selectAgendamentos: (agendamento) =>
        dispatch({type: Actions.selectAgendamentos, payload: agendamento}),
    selectSala: (sala) => dispatch({type: Actions.selectSala, payload: sala}),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CalendarAgendamentos);
