import { ObjectId } from "bson";
import LeadDAO from "../../../../DAO/LeadDAO";
import {sortLeadByDateFunc} from "../utils";

export const onChangeEstado = (firstUpdate, lead, estadoAtual, setLeads) => {
    if (!firstUpdate.current) {
        LeadDAO.editLead(lead._id, {estado: estadoAtual}).then(() => {
            LeadDAO.findAll().then(leads => setLeads(leads.sort(sortLeadByDateFunc)));
        })
    }
    firstUpdate.current = false;
}

export const onChangeProfissional = (firstUpdate, lead, profissionalAssignado, setLeads) => {
    if (!firstUpdate.current) {
        LeadDAO.editLead(lead._id, {profissional_id: { $oid: profissionalAssignado }}).then(() => {
            LeadDAO.findAll().then(leads => setLeads(leads.sort(sortLeadByDateFunc)));
        })
    }
    firstUpdate.current = false;
}
