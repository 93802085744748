import * as React from 'react';
import "./Fab.sass";

const Fab = (props: 
    {setModalType: (modalType: "ENTRADA" | "SAIDA" | "TITULO") => void, 
    showModal: (show: boolean) => void}) => {

    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <div 
                onClick={() => setOpen(!open)}
                className={open ? "fab open" : "fab"}><i className='fas fa-plus' /></div>
            <div 
                onClick={() => {
                    props.setModalType("ENTRADA");
                    props.showModal(true);
                    setOpen(false)
                }}
                className={open ? 'fab-entrada' : 'fab-entrada hidden'}>
                    <i className={'fas fa-arrow-left'}/>
                <div className='hint'>Entrada</div>
            </div>
            <div 
                onClick={() => {
                    props.setModalType("SAIDA");
                    props.showModal(true);
                    setOpen(false);
                }}
                className={open ? 'fab-saida' : 'fab-saida hidden'}>
                    <i className={'fas fa-arrow-right'} />
                    <div className='hint'>Saída</div>
            </div>
            <div
                onClick={() => {
                    props.setModalType("TITULO");
                    props.showModal(true);
                    setOpen(false);
                }}
                className={open ? 'fab-lista' : 'fab-lista hidden'}>
                <i className={'fas fa-list'} />
                <div className='hint'>Título</div>
            </div>
        </React.Fragment>
        )
}

export default Fab;