import moment from "moment";
import {Sala} from "../../../../../DAO/salaDAO";

interface Dados {
    hora_inicio: number,
    hora_fim: number,
    sala_option: string,
    date: Date,
}

export const selectOptions = (userLogged, horaInicial, isHoraFinal = false, dateSelected?) => {

    let array : Array<{label: string, value: any}> = [];
    if (!dateSelected)
        return []

    if ('bloqueadoTarde' in userLogged) {
        if (userLogged.bloqueadoTarde) {
            for (
                let i = horaInicial;
                i < (isHoraFinal ?
                    (eSabado(dateSelected) ?
                        (moment(dateSelected).isAfter(new Date('2021-01-15')) ?
                            13 : 13)
                        : 13)
                    : (eSabado(dateSelected) ?
                        (moment(dateSelected).isAfter(new Date('2021-01-15')) ?
                            12 : 12)
                        : 13));
                i++)
                array.push({label: i + ':00', value: i});
        } else {
            for (
                let i = horaInicial;
                i < (isHoraFinal ?
                    (eSabado(dateSelected) ?
                        (moment(dateSelected).isAfter(new Date('2021-01-15')) ?
                            18 : 13)
                        : 21)
                    : (eSabado(dateSelected) ?
                        (moment(dateSelected).isAfter(new Date('2021-01-15')) ?
                            17 : 12)
                        : 20));
                i++)
                array.push({label: i + ':00', value: i});
        }
    } else {
        for (
            let i = horaInicial;
            i < (isHoraFinal ?
                (eSabado(dateSelected) ?
                    (moment(dateSelected).isAfter(new Date('2021-01-15')) ?
                        18 : 13)
                    : 21)
                : (eSabado(dateSelected) ?
                    (moment(dateSelected).isAfter(new Date('2021-01-15')) ?
                        17 : 12)
                    : 20));
            i++)
            array.push({label: i + ':00', value: i});
    }
    return array;
}


export const eSabado = (dateSelected) => {
    if (dateSelected)
        return dateSelected.getDay() === 6;
    else
        return false
};

export const sortSalas = (salas: Array<Sala>) => {
    return salas.sort((a , b ) => {
        if (+a.nome.split(" ")[1] > +b.nome.split(" ")[1]) {
            return 1;
        } else {
            return -1;
        }
    })
}

export const getAgendamentosFromDia = (agendamentos: Array<any>, dia: Date) =>
    agendamentos.filter((agendamento) => (moment(agendamento.data).isSame(dia, "day")))

export const getAgendamentosFromSala = (agendamentos: Array<any>, salaNome: string) =>
    agendamentos.filter((agendamento) => (agendamento.sala.nome === salaNome))

export const getArrayDeHoras = (agendamento) => {
    let intervaloHoras: Array<number> = [];
    for (let i = +agendamento.hora_inicio; i < +agendamento.hora_fim; i++) {
        intervaloHoras.push(i);
    }
    return intervaloHoras;
}

const populateArrayDiaSala = (data, agendamentos) => (
    (data.sala_option === "Todas") ? getAgendamentosFromDia(agendamentos, data.date) :
        getAgendamentosFromSala(getAgendamentosFromDia(agendamentos, data.date), data.sala_option))

const populateAndFilterArrayDiaSala = (data, agendamentos) => (
    populateArrayDiaSala(data, agendamentos).filter(agendamento =>
        !agendamento.cancelado))

const getSalasArray = (salas) => (salas.map(sala => sala.nome));

const removeSalaOcupada = (salas, agendamento) => {
    let salaIndex = salas.findIndex(sala => sala === agendamento.sala.nome);
    salas.splice(salaIndex, 1);
}

const handleSalasDisponiveis = (agendamento, salas) => removeSalaOcupada(salas, agendamento);

const handleIndisponibilidade = (data, agendamento, salas) => {
    if (data.sala_option !== "Todas") {
        return 1;
    } else {
        handleSalasDisponiveis(agendamento, salas);
    }
}

const retornoVisualDisponibilidade = (data, salas, d, setSalasDisponiveis) => {
    if (d === 1) {
        alert("A sala se encontra indisponível para uso no horário solicitado.");
        return;
    }
    if (data.sala_option !== "Todas")
        alert("O horário desta sala se encontra disponível para uso!");
    else
        setSalasDisponiveis(salas);
}

export const getDataFromForm = (form) => ({
        hora_fim: form.hora_fim.value,
        hora_inicio: form.hora_inicio.value,
        sala_option: form.sala_option.value,
        date: form.date.value
    }
)

const iterateHours = (intervaloHoras, intervaloReserva, data, agendamento, salasArr) => {
    for (let hora of intervaloHoras) {
        for (let horaReserva of intervaloReserva) {
            if (hora === horaReserva) {
                let d = handleIndisponibilidade(data, agendamento, salasArr);
                if (d === 1)
                    return d;
            }
        }
    }
}

const checaSeEstaDisponivel = (agendamentosDoDiaEDaSala, data, salasArr) => {
    let intervaloHoras = getArrayDeHoras(data);
    for (let agendamento of agendamentosDoDiaEDaSala) {
        let intervaloReserva: Array<number> = getArrayDeHoras(agendamento);
        let d = iterateHours(intervaloHoras, intervaloReserva, data, agendamento, salasArr);
        if (d === 1) {
            return d;
        }
    }
}


export const submitDisponibilidade = (e, agendamentos, salas, setSalasDisponiveis) => {
    e.preventDefault();

    const data: Dados = getDataFromForm(e.target);

    let agendamentosDoDiaEDaSala: Array<any> = populateAndFilterArrayDiaSala(data, agendamentos);
    let salasArr: Array<string> = getSalasArray(salas);
    let d = checaSeEstaDisponivel(agendamentosDoDiaEDaSala, data, salasArr);
    retornoVisualDisponibilidade(data, salasArr, d, setSalasDisponiveis);
}
