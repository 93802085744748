import React from 'react';
import CheckBox from "../../../../../checkbox/checkbox";
import Button from "../../../../../button/button";
import {populateHorasArray} from "../../utils";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../../../redux/storeTypes";
import Select from "react-select"
import {cancela, cancelaParcial, cobraTaxa, excluirReserva} from './utils';
import moment from "moment";

interface Props {
    close: () => void,
    tipo?: "cliente" | "adm",
}

const Cancelamento: React.FC<Props> = (props) => {

    const checaSeNaoEDaquiA2Horas = (reserva, horaInicio: number, reservaInteira: boolean) => {
        return !(moment(new Date()).isSame(new Date(reserva.data), 'day')
            && ((reservaInteira ? reserva.hora_inicio : horaInicio) - new Date().getHours()) <= 2);

    }

    const {agendamentos, agendamentoSelected} = useSelector((state: RootState) => state.agendamentos)
    const {userLogged} = useSelector((state: RootState) => state.general)
    const [cancelamentoData, setCancelamentoData] = React.useState({
        hora_inicio: 0,
        hora_fim: 0,
        reservaInteira: false,
    });

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (agendamentoSelected) {
            setCancelamentoData({
                ...cancelamentoData,
                hora_inicio: agendamentoSelected.hora_inicio,
                hora_fim: agendamentoSelected.hora_fim,
            });
        }
    }, [agendamentoSelected]);

    return (
        <div className={props.tipo === 'cliente' ? 'mb-8' : ''}>
            <h2>Cancelamento</h2>
            <CheckBox
                label={'Reserva Inteira'}
                onCheck={(checked) =>
                    setCancelamentoData({...cancelamentoData, reservaInteira: !checked})
                }
            />
            <div style={{display: 'flex', marginBottom: 20, marginTop: 12}}>
                <div style={{flexGrow: 1, marginRight: 20}}>
                    <h3 style={{marginTop: 0, marginBottom: 8}}>Hora Início</h3>
                    <Select
                        value={
                            cancelamentoData.hora_inicio === 0
                                ? '' : {
                                    label: cancelamentoData.hora_inicio + ':00',
                                    value: cancelamentoData.hora_inicio
                                }}
                        onChange={(e: any) => setCancelamentoData({...cancelamentoData, hora_inicio: e.value})}
                        options={populateHorasArray(agendamentoSelected.hora_inicio, agendamentoSelected.hora_fim)}
                    />
                </div>
                <div style={{flexGrow: 1}}>
                    <h3 style={{marginTop: 0, marginBottom: 8}}>Hora Fim</h3>
                    <Select
                        value={
                            cancelamentoData.hora_fim === 0
                                ? ''
                                : {label: cancelamentoData.hora_fim + ':00', value: cancelamentoData.hora_fim}
                        }
                        onChange={(e: any) =>
                            setCancelamentoData({
                                ...cancelamentoData,
                                hora_fim: e.value,
                            })
                        }
                        options={populateHorasArray(
                            cancelamentoData.hora_inicio + 1,
                            agendamentoSelected.hora_fim + 1
                        )}
                    />
                </div>
            </div>
            <Button
                text={'Cancelar Reserva'}
                type={'button'}
                width={'45%'}
                onClick={async () => {
                    if (props.tipo === 'cliente') {
                        if (!checaSeNaoEDaquiA2Horas(agendamentoSelected, cancelamentoData.hora_inicio, cancelamentoData.reservaInteira)) {
                            alert("O agendamento selecionado será em menos de 2 horas ou já passou, caso seja em menos de 2 horas, para " +
                                "realizar esse cancelamento, por favor, fale com nossos administradores.")
                            return;
                        }
                        if (cancelamentoData.reservaInteira) {
                            if (window.confirm("Tem certeza que deseja cancelar toda a reserva?")) {
                                await cancela(agendamentoSelected, dispatch, props, userLogged);
                                props.close()
                            }
                        } else {
                            if (
                                window.confirm("Tem certeza que deseja cancelar a " +
                                    "reserva das " + cancelamentoData.hora_inicio + "h " +
                                    "até às " + cancelamentoData.hora_fim + "h?")) {
                                await cancelaParcial(
                                    agendamentoSelected,
                                    cancelamentoData,
                                    agendamentos,
                                    userLogged, dispatch, true);
                                props.close()
                            }
                        }
                    } else {
                        if(cancelamentoData.reservaInteira) {
                            if (window.confirm("Tem certeza que deseja cancelar toda a reserva?")) {
                                await cobraTaxa(agendamentoSelected, dispatch);
                                await cancela(agendamentoSelected, dispatch, props, userLogged);
                            }
                        } else {
                            await cobraTaxa(agendamentoSelected, dispatch, cancelamentoData);
                            await cancelaParcial(
                                agendamentoSelected,
                                cancelamentoData,
                                agendamentos,
                                userLogged, dispatch);
                            props.close()
                        }

                    }
                }}
            />{' '}
            &nbsp;
            {
                (props.tipo === "adm") ? <Button
                    text={'Excluir Reserva'}
                    width={'45%'}
                    type={'button'}
                    onClick={async () => {
                        await excluirReserva(agendamentoSelected, userLogged)
                    }}
                /> : <></>
            }

        </div>
    );
};

export default Cancelamento;
