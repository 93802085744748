import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux/storeTypes";
import Filtro from "./Filtro/Filtro";
import "./Filtros.sass"
import moment from "moment";

interface Props {
    setFilter: (data: Array<any>) => void,
}

const Filtros : React.FC<Props> = ({setFilter}) => {

    const formasPgto = ["todos", "debito", "credito", "especie", "transf", "especie", "cortesia"];
    const entradaSaida = ["Entrada", "Saida", "Todos"]

    const { transacoes, titulos } = useSelector((state : RootState) => state.financeiro);
    const {profissionais} = useSelector((state: RootState) => state.profissionais)
    const [filtros, setFiltros] = React.useState<Record<string, (any) => any>>({})
    const [data, setData] = React.useState({inicio: moment().subtract(30, "days"), fim: moment()})

    React.useEffect(() => {
        setFiltros({...filtros, data: (transacao) => {
                return moment(transacao.data).isSameOrBefore(data.fim) && moment(transacao.data).isSameOrAfter(data.inicio);
            }})
    }, [data]);

    React.useEffect(() => {
        let data = [...transacoes]
        for (let key in filtros) {
            data = data.filter(filtros[key]);
        }
        setFilter(data)
    }, [filtros, transacoes]);

    return (
        <div>
            <h2>Filtros</h2>
            <div className={"flex_filtros"}>
                <div style={{marginRight: "10px"}}>
                    <label style={{fontSize: "1.17em", marginBottom: "1em", display: "block"}}>Data Inicial</label>
                    <input
                        onChange={(e) => {
                            if (e.target.value) {
                                setData({...data, inicio: moment(e.target.value)}) }}}
                        style={{padding: "5px"}} name={"data_inicio"} defaultValue={data.inicio.format("YYYY-MM-DD")} type={"date"} />
                </div>
                <div style={{marginRight: "10px"}}>
                    <label style={{fontSize: "1.17em", marginBottom: "1em", display: "block"}}>Data Final</label>
                    <input
                        onChange={(e) => {
                                setData({...data, fim: moment(e.target.value)}) }}
                        style={{padding: "5px"}} name={"data_final"} defaultValue={data.fim.format('YYYY-MM-DD')} type={"date"} />
                </div>
                <Filtro
                    name={"Forma de Pagamento"}
                    options={formasPgto}
                    onChange={(e : any) => {
                        setFiltros({...filtros, formaPgto: (transacao) => {
                                if (e.option === "todos")
                                    return true
                                return transacao.formaPgto === e.option
                            }})
                    }}/>
                <Filtro
                    name={"Entrada ou Saída"}
                    options={entradaSaida}
                    onChange={(e) => {
                        setFiltros({...filtros, entradaSaida: (transacao) => {
                                if (e.option === "Todos")
                                    return true
                                return transacao.tipo === e.option.toUpperCase()
                            }})
                    }} />
                <Filtro
                    name={"Títulos"}
                    options={["Todos", "TAXA DE ATRASO", "TAXA DE CANCELAMENTO", ...titulos.map(titulo => titulo.titulo)]}
                    onChange={(e) => {
                        setFiltros({...filtros, titulo: (transacao) => {
                                if (e.option === "Todos")
                                    return true
                                return transacao.titulo === e.option
                            }})
                    }} />
                <Filtro
                    name={"Profissional"}
                    options={[...profissionais.map(prof => prof.nome), "Todos"]}
                    onChange={(e) => {
                        setFiltros({...filtros, nome: (transacao) => {
                            if (e.option === "Todos") { return true }
                            return (transacao.pagadorRecebedor === e.option)
                        }})
                    }} />
            </div>
        </div>
    );
};

export default Filtros;
