import React from 'react';
import Button from "../../../../../button/button";
import reservaDAO from "../../../../../../../DAO/reservaDAO";
import Select from "react-select";
import Actions, {ActionsFn} from "../../../../../../../redux/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../../../redux/storeTypes";
import {populateSalas, showToastLoading, showToastSuccess} from '../../utils';

const TrocarSala = () => {

    const { agendamentos, agendamentoSelected } = useSelector((state : RootState) => state.agendamentos)
    const { salas } = useSelector((state : RootState) => state.salas)
    const { userLogged } = useSelector((state: RootState) => state.general)
    const [loading, setLoading] = React.useState(false);
    const [editarSala, setEditarSala] = React.useState<any>(null);

    const dispatch = useDispatch();

    const updateAgendamentos = async () => {
        let agendamentos = await reservaDAO.findAllInClient()
        dispatch(ActionsFn.setSalas(agendamentos[0]));
        dispatch(ActionsFn.setProfissionais(agendamentos[1]));
        dispatch(ActionsFn.setAgendamentos(agendamentos[2]));
    };

    return (
        <React.Fragment>
            <h2>Sala</h2>
            <Select
                onChange={(e: any) => setEditarSala({ id: e.value, label: e.label })}
                options={populateSalas(salas)}
            />
            <br />
            <Button
                loading={loading}
                width={'45%'}
                style={{ marginTop: 15 }}
                type={'button'}
                text={'Trocar Sala'}
                onClick={async () => {
                    await reservaDAO.trocaSala(
                        agendamentos,
                        agendamentoSelected,
                        userLogged,
                        editarSala,
                        () => {
                            showToastLoading(
                                'Carregando: Trocando ' +
                                agendamentoSelected.profissional.nome +
                                ' de sala'
                            );
                        },
                        async () => {
                            showToastSuccess(
                                'Sucesso na troca da ' +
                                agendamentoSelected.profissional.nome +
                                ' de sala'
                            );
                            await updateAgendamentos();
                        }
                    );
                }}
            />
        </React.Fragment>
    );
};

export default TrocarSala;
