import React, { RefObject } from 'react';
import PropTypes from 'prop-types';
import './style.sass';

const InputText = React.forwardRef((props : {
    style?: object,
    decoration?: any,
    placeholder?: any,
    label?: string,
    onChange?: (any) => any,
    name?: string,
    type?: string,
    step?: any,
    inputStyle?: any,
    value?: any,
    disabled?: boolean,
    required?: boolean,
    defaultValue?: any,
}, ref: string | ((instance: HTMLInputElement | null) => void) | RefObject<HTMLInputElement> | null | undefined) => {

    return (
        <div className={'input_text'} style={props.style}>
            {props.decoration ? <div className='decor'>{props.decoration}</div> : <></>}
            <input
                placeholder={props.placeholder}
                onChange={props.onChange}
                step={props.step ? props.step : '0.01'}
                style={props.inputStyle}
                value={props.value}
                ref={ref}
                required={props.required}
                className={props.decoration ? "with-decor" : ""}
                disabled={props.disabled}
                defaultValue={props.defaultValue}
                type={props.type ? props.type : 'text'}
                name={props.name ? props.name : ''} />
            <label className={'label'}>{props.label}</label>
        </div>
    )
})

InputText.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    decoration: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.string,
    ref: PropTypes.any,
    inputStyle: PropTypes.object,
    defaultValue: PropTypes.string,
    style: PropTypes.object,
    step: PropTypes.string,
}

export default InputText;
