import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import reservaDAO, { Reserva } from '../../../../DAO/reservaDAO';
import { RootState } from '../../../../redux/storeTypes';
import moment from 'moment'
import "./warnings.sass"
import { ActionsFn } from '../../../../redux/actions/actions';

const Warnings = () => {

    const { agendamentos } = useSelector((state : RootState) => state.agendamentos );
    const [agendamentosComProblemas, setAgendamentosComProblemas] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(1);

    const dispatch = useDispatch()

    React.useEffect(() => {
        const reservasComProblemas = reservaDAO.getReservasAnterioresComProblemas(agendamentos);
        setAgendamentosComProblemas(reservasComProblemas);
    }, [agendamentos])

    React.useEffect(() => {
        console.log(agendamentosComProblemas)
        setPages(Math.ceil(agendamentosComProblemas.length / 4))
    }, [agendamentosComProblemas])

    if (agendamentosComProblemas.length > 0) {
        return (
            <div className={"warnings_container"}>
                <h1>Verificar Reservas:</h1>
                {
                    agendamentosComProblemas.map((agendamento: Reserva, index: number) => {
                        if (agendamento.profissional) {
                            if ((index < page * 4) && (index >= (page - 1) * 4)) {
                                return (<div className="warning" key={index+"warning"}>
                                    		<p>{agendamento.profissional.nome}</p>
                                    		<p	onClick={() => { dispatch(ActionsFn.selectDate(new Date(agendamento.data))) }}
                                        		className={"date"}>
                                        			{moment(new Date(agendamento.data)).format('DD-MM-yyyy')} &nbsp;
                        				{agendamento.hora_inicio}h ~ {agendamento.hora_fim}h
						</p>
                                </div>)
                         }
                       }
                    })}
                <div className='pages'>
                    {
                        new Array(pages).fill(0).map((element, index) => (
                            <div
                                key={"warning"+index}
                                onClick={() => { setPage(index + 1) }}
                                className={(index + 1) === page ? 'active' : ''}>{index + 1}</div>
                        ))
                    }
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export default Warnings;
