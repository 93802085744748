import React from 'react';
import "./style.sass";

interface Props {
    onCheck: (checked: boolean) => void,
    label?: string,
    check?: boolean,
}

const CheckBox : React.FC<Props> = ({onCheck = (checked) => {}, label, check}) => {

    const [checked, setChecked] = React.useState(check ? check :  false);

    const classNameRender = () => {
        if (check === undefined) {
            return checked ? 'checkbox checked' : 'checkbox'
        } else {
            return check ? 'checkbox checked' : 'checkbox'
        }
    }

    return (
        <div className={'checkbox_container'}>
            <div style={{flexGrow: 0}} className={classNameRender()} onClick={() => {
                setChecked(!checked);
                onCheck(checked);
            }}>
            </div>
            <label>{label}</label>
        </div>
    )
};

export default CheckBox;
