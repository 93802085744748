import React from 'react';
import moment from "moment";
import "./ProximosAgendamentos.sass"
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux/storeTypes";
import {Moment} from "moment";
import ModalEditarAgendamento from "../ModalEditarAgendamento/ModalEditarAgendamento";
import {ActionsFn} from "../../../../../redux/actions/actions";

const ProximosAgendamentos = () => {

    const weekdays = ["S", "T", "Q", "Q", "S", "S"]
    const weekStart = moment().startOf("week");

    const [modalOpen, setModalOpen] = React.useState(false);
    const [dateSelected, setDateSelected] = React.useState<Date>(new Date());

    const dispatch = useDispatch();

    const {userLogged } = useSelector((state: RootState) => state.general);
    const {agendamentos} = useSelector((state: RootState) => state.agendamentos);
    const [profissionalAgendamentos, setProfissionalAgendamentos] = React.useState<Array<any>>([]);

    React.useEffect(() => {
        let profAgendamentos = agendamentos.filter(agendamento => (
            (agendamento.profissional_id.toString() === userLogged._id.toString()) &&
            !agendamento.profissional_id.cancelado));
        setProfissionalAgendamentos(profAgendamentos);
    }, [agendamentos, userLogged]);

    const getDiaFromString = (diaString) => {
        diaString += "/"+moment().year();
        return moment(diaString,"DD/MM/YYYY")
    }

    const getAgendamentosDoDia = (dia : Moment, agendamentos: Array<any>) => {
        return agendamentos.filter((agendamento) => {
            return moment(agendamento.data).isSame(dia, "day");
        });
    }

    const isMobile = () => (window.innerWidth < 700);

    return (
        <div className={"proximos_agendamentos"}>
            <h2>Seus próximos agendamentos</h2>
            <p>Para editar sua reserva {isMobile() ? "toque" : "clique" } sobre ela.</p>
            <ModalEditarAgendamento dateSelected={dateSelected} close={() => setModalOpen(false)} show={modalOpen} />
            <div className={"week_calendar"}>
                {
                    weekdays.map((weekday, index) => (<div className={"day"}>
                        <h3>{weekday}</h3>
                        <h5>{weekStart.clone().add(index + 1, "days").format("DD/MM")}</h5>
                        <div className={"agendamentos"}>
                            {
                                getAgendamentosDoDia(
                                    getDiaFromString(
                                        weekStart
                                            .clone()
                                            .add(index + 1, "days")
                                            .format("DD/MM")),
                                    profissionalAgendamentos,
                                )
                                    .filter(agendamento => !agendamento.cancelado)
                                    .length > 0 ?
                                    getAgendamentosDoDia(
                                        getDiaFromString(
                                            weekStart
                                                .clone()
                                                .add(index + 1, "days")
                                                .format("DD/MM")),
                                        profissionalAgendamentos,
                                    ).filter(agendamento => !agendamento.cancelado).map(agendamento => (
                                        <div className={"agendamento_box"} onClick={() => {
                                            setDateSelected(moment(
                                                new Date(agendamento.data))
                                                .subtract(4, "hours").toDate())
                                            dispatch(ActionsFn.selectAgendamentos({...agendamento, profissional: userLogged }))
                                            setModalOpen(true)
                                        }}>
                                            <p>{agendamento.sala.nome}</p>
                                            <p>{agendamento.hora_inicio}h ~ {agendamento.hora_fim}h</p>
                                        </div>
                                    ))
                                    :
                                    <p style={{fontSize: 11, color: "#AAA", fontStyle: "italic"}}>não há reservas</p>
                            }
                        </div>
                    </div>))
                }
            </div>
        </div>
    );
};

export default ProximosAgendamentos;
