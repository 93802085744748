import {Reserva} from "../../../DAO/reservaDAO";
import moment from "moment";

export const transformToChartType = (dictFreq : { [prof: string] : number }) => {
    let arr : Array<{professional: string, value: number}> = [];
    for (let key in dictFreq) { arr.push({ professional: key, value: dictFreq[key] })}
    //Limpa o NaN do Array.
    for (let i = 0; i < arr.length; i++) { if (isNaN(arr[i].value)) { arr.splice(i, 1) }}
    return arr;
}

export const getProfessionalsQuantity = (reservas : Array<Reserva>, inicio, fim) => {
    let allData = {};
    let agendamentos = [...reservas].filter((reserva) => filtroDeReservaRealmenteFeita(reserva, inicio, fim));
    for (let agendamento of agendamentos) {
        if (agendamento.profissional) {
            let nome = agendamento.profissional.nome;
            let horasUtilizadas = agendamento.hora_fim - agendamento.hora_inicio
            if (!(nome in allData)) { allData[nome] = horasUtilizadas } else { allData[nome] += horasUtilizadas }}}
    return transformToChartType(allData);
}

export const filtroDeReservaRealmenteFeita = (reserva, inicio, fim) => {
    return (!reserva.cancelado && reserva.pago && reserva.executado &&
        moment(reserva.data).isAfter(inicio, "day") && moment(reserva.data).isSameOrBefore(fim, "day"));
}

export const createChartData = (array, agendamentos) => {
    let date = moment("2020/03/01", "YYYY/MM/DD");
    while (!moment(new Date()).isSame(date, 'day')) {
        array.push({date: date.toDate().getTime(), value: 0});
        date.add(1, 'day');
    }

    //Cria Frequency Table
    let tabelaFreq = {};
    agendamentos.forEach(agendamento => {
        if (!agendamento.cancelado && agendamento.pago && agendamento.executado) {
            if (moment(new Date(agendamento.data)).format('DD/MM/YYYY') in tabelaFreq) {
                tabelaFreq[moment(new Date(agendamento.data)).format('DD/MM/YYYY')] += 1;
            } else {
                tabelaFreq[moment(new Date(agendamento.data)).format('DD/MM/YYYY')] = 0;
            }
        }
    });

    //Criar o objeto de verdade {date: moment().toDate(), value: quantidadeDeAgendamentosDoDia }
    Object.keys(tabelaFreq).forEach(key => {
        for (let i = 0; i < array.length; i++) {
            if (moment(array[i].date).isSame(moment(key, 'DD/MM/YYYY'), 'day')) {
                array[i].value = tabelaFreq[key];
                break;
            }
        }
    });
    let aux : Array<{value: number, date: number}> = []
    for (let el of array) {
        if (el.value > 0) { aux.push(el) }
    }
    return aux;
}


