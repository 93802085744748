import React from 'react';
import ModalParent from "../../../../../../modal_parent/modal";
import "./ModalPagarPendencia.sass"
import Pagamento from "../../../../../modal_add_financeiro/components/Pagamento";
import {Valor} from "../../Pagamento";
import Button from "../../../../../../../button/button";
import FinanceiroDAO from "../../../../../../../../../DAO/financeiroDAO";
import clienteDAO from "../../../../../../../../../DAO/clienteDAO";
import {ActionsFn} from "../../../../../../../../../redux/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../../../../../redux/storeTypes";
import reservaDAO from "../../../../../../../../../DAO/reservaDAO";
import {VALOR_1_ATRASO} from "../../../../../../../../../constants";

interface Props {
    open: boolean,
    close: () => void,
    pendenciaSelected: any,
    pendenciaIndex: number | null,
}

// @ts-ignore
const ModalPagarPendencia: React.FC<Props> = ({open, close, pendenciaSelected, pendenciaIndex}) => {

    const [valor, setValor] = React.useState<Valor>({
        debito: 0,
        credito: 0,
        especie: 0,
        transf: 0,
        totalAcumulado: 0,
        cortesia: 0,
        saldo: 0,
    })
    const [loading, setLoading] = React.useState(false);
    const [valorTotal, setValorTotal] = React.useState(0);
    const { userLogged } = useSelector((state : RootState) => state.general)
    const {profissionais} = useSelector((state: RootState) => state.profissionais);

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (pendenciaSelected) {
            let valt = pendenciaSelected.tipo === "atraso" ?
                pendenciaSelected.quantidade : VALOR_1_ATRASO;
            setValorTotal(valt);
        }
    }, [pendenciaSelected])

    const getSomaOfValor = (valor: Valor) => {
        let soma = 0
        for (let val in valor)
            if (val !== "totalAcumulado")
                soma += valor[val]
        return soma
    }

    const createFinanceiro = async () => {
        if (valor.debito > 0) {
            await FinanceiroDAO.create({
                data: new Date(),
                formaPgto: 'debito',
                pagadorRecebedor: pendenciaSelected.agendamento.profissional.nome,
                quantidade: 1,
                tipo: 'ENTRADA',
                titulo: pendenciaSelected.tipo === "atraso" ? "TAXA DE ATRASO" : "TAXA DE CANCELAMENTO",
                valor: valor.debito,
            })
        }
        if (valor.credito > 0) {
            await FinanceiroDAO.create({
                data: new Date(),
                formaPgto: 'credito',
                pagadorRecebedor: pendenciaSelected.agendamento.profissional.nome,
                quantidade: 1,
                tipo: 'ENTRADA',
                titulo: pendenciaSelected.tipo === "atraso" ? "TAXA DE ATRASO" : "TAXA DE CANCELAMENTO",
                valor: valor.credito,
            })
        }
        if (valor.transf > 0) {
            await FinanceiroDAO.create({
                data: new Date(),
                formaPgto: 'transf',
                pagadorRecebedor: pendenciaSelected.agendamento.profissional.nome,
                quantidade: 1,
                tipo: 'ENTRADA',
                titulo: pendenciaSelected.tipo === "atraso" ? "TAXA DE ATRASO" : "TAXA DE CANCELAMENTO",
                valor: valor.transf,
            })
        }
        if (valor.cortesia > 0) {
            await FinanceiroDAO.create({
                data: new Date(),
                formaPgto: 'cortesia',
                pagadorRecebedor: pendenciaSelected.agendamento.profissional.nome,
                quantidade: 0,
                tipo: 'ENTRADA',
                titulo: pendenciaSelected.tipo === "atraso" ? "TAXA DE ATRASO" : "TAXA DE CANCELAMENTO",
                valor: valor.cortesia,
            })
        }
        //verifica o que foi pro caixa (mais simples)
        if (valor.especie > 0) {
            await FinanceiroDAO.create({
                data: new Date(),
                formaPgto: 'especie',
                pagadorRecebedor: pendenciaSelected.agendamento.profissional.nome,
                quantidade: 1,
                tipo: 'ENTRADA',
                titulo: pendenciaSelected.tipo === "atraso" ? "TAXA DE ATRASO" : "TAXA DE CANCELAMENTO",
                valor: valor.especie,
            })
        }

        if (valor.saldo > 0) {
            await clienteDAO.editCliente(pendenciaSelected.agendamento.profissional._id, {
                saldo: 0
            })
            let profs = await clienteDAO.findAll()
            dispatch(ActionsFn.setProfissionais(profs))
        }
    }

    const updateAgendamentos = async () => {
        let agendamentos = await reservaDAO.findAllInClient()
        dispatch(ActionsFn.setSalas(agendamentos[0]));
        dispatch(ActionsFn.setProfissionais(agendamentos[1]));
        // dispatch(ActionsFn.setAgendamentos(agendamentos[2]));
    }

    const onSubmit = async e => {
        e.preventDefault()
        setLoading(true)
        if (getSomaOfValor(valor) !== valorTotal) {
            alert("O valor total não coincide com o pendente.");
            return;
        } else {
            await createFinanceiro()
            let profissional = await clienteDAO.getProfissionalById(profissionais, pendenciaSelected.agendamento.profissional_id)
            let pendenciasProfissional = [...profissional.pendencias]
            pendenciasProfissional.splice(pendenciaIndex as number, 1);
            await reservaDAO.update({_id: pendenciaSelected.agendamento._id}, { pendencia: null }, userLogged)
            await clienteDAO.update({_id: profissional._id}, {pendencias: pendenciasProfissional})
            await updateAgendamentos()
        }
        setLoading(false)
        close()
    }

    if (pendenciaSelected) {
        return (
            <React.Fragment>
                <div className={open ? "overlay" : "overlay hidden"}>
                </div>
                <ModalParent
                    close={close}
                    show={open}
                    onSubmit={onSubmit}
                    header={<header>
                        <div>
                            <h1>Pendência de Pagamento</h1>
                            <h3>Taxa de {pendenciaSelected.tipo}</h3>
                        </div>
                        <div className={'close_container'} onClick={close}>
                            <i className={'fa fa-times'}/>
                        </div>
                    </header>}
                    body={<div>
                        <Pagamento
                            allowCortesia={
                                userLogged.nome === "Brian Ito de Oliveira Moura" ||
                                userLogged.nome === "Marcela de Souza Lobo" ||
                                userLogged.nome === "Igor Santiago Martins"
                            }
                            setValor={e => setValor(e)}
                            valorTotal={valorTotal}/>
                    </div>}
                    footer={<div className={'footer'}>
                        <Button type={'submit'} loading={loading} text={loading ? 'Carregando...' : 'Pagar'}/>
                    </div>}
                />
            </React.Fragment>
        );
    }
    return <></>
};

export default ModalPagarPendencia;
