import clienteDAO from "../../../DAO/clienteDAO";
import {ActionsFn} from "../../../redux/actions/actions";

export const checkIfAdm = (userLogged) => {
    const adms = ["Igor Santiago Martins", "Brian Ito de Oliveira Moura", "Marcela de Souza Lobo"];
    return adms.includes(userLogged.nome);
}

export const apagarPendencia = (index, prof, dispatch) => {
    if (window.confirm("Tem certeza que deseja apagar a pendência?")) {
        const pendenciasAux = [...prof.pendencias];
        pendenciasAux.splice(index, 1);
        clienteDAO.editCliente(prof._id, {
            pendencias: pendenciasAux
        }).then(() => {
            clienteDAO
                .findAll()
                .then(res => {
                    dispatch(ActionsFn.setProfissionais(res))
                    alert("Apagado com sucesso!");
                })
        });
    }
}
