import React from 'react';
import "./Creditos.sass"
import moment from "moment";
import FinanceiroDAO, {TipoPgto} from "../../../../../DAO/financeiroDAO";
import { transformFloatToStringReais } from '../../../../../assets/component/modals/administrativo/modal_edit_agendamento/components/Pagamento/utils';
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/storeTypes";
import {Redirect} from "react-router";

const Creditos = () => {

    const [data, setData] = React.useState<Array<any>>([])

    const tiposPgto : Array<TipoPgto> = ["debito", "credito", "especie", "transf", "cortesia"];
    const tiposPgtoLegivel : Array<string> = ["Débito", "Crédito", "Em Espécie", "Transferência (PIX)", "Cortesia"];

    const transformaInputEmLegivel = (input: TipoPgto) : string =>
        tiposPgtoLegivel[tiposPgto.findIndex((tipo) => tipo === input)];

    const {userLogged} = useSelector((state: RootState) => state.general);

    React.useEffect(() => {
        if (userLogged){
            FinanceiroDAO.findFromClient(userLogged.nome).then(res => {
                res.sort((a, b) => {
                    if (moment(a.data).isBefore(b.data)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
                setData(res);
            });
        }

    }, [userLogged]);

    if (!("nome" in userLogged)) {
        return <Redirect to={"/"} />
    }
    return (
        <div>

            <div className={"creditos"}>
                <h2>Seus Créditos</h2>
                <h1>{userLogged.creditos} Horas Disponíveis</h1>
                <p>Última atualização em: {moment().format("DD/MM/YYYY HH:mm")}</p>
            </div>

            <h2>Últimas transações financeiras</h2>
            <div className={"transactions"}>
                <table cellSpacing={0}>
                    <thead>
                        <th>Data</th>
                        <th>Título de Pagamento</th>
                        <th>Método de Pgto</th>
                        <th>Valor Bruto</th>
                    </thead>
                    {
                        data.map((pgto, index) => index < 10 ? (
                            <tr>
                                <td>{moment(pgto.data).format("DD/MM/YYYY")}</td>
                                <td>{pgto.titulo}</td>
                                <td>{transformaInputEmLegivel(pgto.formaPgto as TipoPgto)}</td>
                                <td>{transformFloatToStringReais(pgto.valor)}</td>
                            </tr>
                        ) : <></>)
                    }
                </table>
            </div>
        </div>
    );
};

export default Creditos;
