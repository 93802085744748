import {showToastError, showToastLoading, showToastSuccess} from "../../utils";
import {ActionsFn} from "../../../../../../../redux/actions/actions";
import reservaDAO from "../../../../../../../DAO/reservaDAO";
import moment from "moment";
import logDAO from "../../../../../../../DAO/logDAO";

export const cancela = async (agendamentoSelected, dispatch, props, userLogged) => {
    let aux = {...agendamentoSelected};
    try {
        if ("profissional" in agendamentoSelected) {
            showToastLoading(
                'Carregando: Cancelamento da Reserva de: ' +
                aux.profissional.nome
            );
        }
        props.close();
        await reservaDAO.cancelaReserva(
            agendamentoSelected,
            userLogged
        );
        showToastSuccess(
            "profissional" in agendamentoSelected ?
            'Cancelamento da Reserva de ' +
            aux.profissional.nome +
            ' efetuada!' : 'Cancelamento de Reserva efetuada!'
        );
        dispatch(ActionsFn.selectAgendamentos({}))
    } catch(e) {
        showToastError("Erro ao realizar cancelamento da reserva de " + aux.profissional.nome);
    }
}

export const adicionaPendenciaCancelamento = async (agendamentoSelected, dispatch, quantidade) => {
    await reservaDAO
        .criaPendencia(
            agendamentoSelected,
            agendamentoSelected.profissional._id,
            quantidade,
            "cancelamento",
            dispatch);
}

const isBefore2HoursAndSameDay = (data, horaInicial) => {
    if (moment().isAfter(new Date(data).setHours(horaInicial))) { return true }
    return moment().isSame(new Date(data), "day") && ((horaInicial - moment().hours()) <= 2);

}

export const cobraTaxa = async (agendamentoSelected, dispatch, cancelamentoData?) => {
    let horaInicial;
    if (cancelamentoData) { horaInicial = cancelamentoData.hora_inicio }
    else { horaInicial = agendamentoSelected.hora_inicio }

    try {
        const isBefore2Hours = isBefore2HoursAndSameDay(agendamentoSelected.data, horaInicial)
        if (isBefore2Hours) {
            if (window.confirm("O cancelamento acarretará em taxa de cancelamento para o profissional.")) {
                if (horaInicial === moment().hours())
                    await adicionaPendenciaCancelamento(agendamentoSelected, dispatch, 2);
                else
                    await adicionaPendenciaCancelamento(agendamentoSelected, dispatch, 1);
            }
        }
    } catch (e) { showToastError("Erro ao adicionar taxa para profissional.")}
}

export const cancelaParcial = async (agendamentoSelected, cancelamentoData, agendamentos, userLogged, dispatch, isClient  = false) => {
    try {
        if (!isClient) {
            showToastLoading(
                'Carregando: Cancelamento Parcial da Reserva de: ' +
                agendamentoSelected.profissional.nome
            );
        } else {
            showToastLoading('Carregando: Cancelamento Parcial da Reserva')
        }

        let aux = { ...agendamentoSelected, profissional: userLogged };
        await reservaDAO.cancelaParteDaReserva(
            aux,
            cancelamentoData.hora_inicio,
            cancelamentoData.hora_fim,
            agendamentos,
            userLogged
        );
        dispatch(ActionsFn.selectAgendamentos({}))
        if (isClient) {
            showToastSuccess('Cancelamento Parcial da Reserva efetuada!');
        } else {
            showToastSuccess(
                'Cancelamento Parcial da Reserva de ' +
                aux.profissional.nome +
                ' efetuada!'
            );
        }
    } catch (e) {
        alert(e);
    }
}

export const excluirReserva = async (agendamentoSelected, userLogged) => {
    if (
        window.confirm(
            'Tem certeza que deseja excluir a reserva?'
        )
    ) {
        showToastLoading(
            'Carregando: Excluindo Reserva de: ' +
            agendamentoSelected.profissional.nome
        );
        await logDAO.create({
            usuario: userLogged,
            log: `Excluiu reserva ${
                agendamentoSelected.profissional.nome
            } ${moment(agendamentoSelected.data).format(
                'DD-MM-YYYY'
            )} ${agendamentoSelected.hora_inicio}h-${
                agendamentoSelected.hora_fim
            }h ${agendamentoSelected.sala.nome}`,
            data_hora: new Date(),
        });
        await reservaDAO.delete({
            _id: agendamentoSelected._id,
        });
        showToastSuccess(
            'Reserva de: ' +
            agendamentoSelected.profissional.nome +
            ' excluída!'
        );
    }
}
