export const checkHasPendencias = (agendamentoSelected) => {
    if (agendamentoSelected) {
        if (agendamentoSelected.profissional.pendencias) {
            console.log(agendamentoSelected.profissional.pendencias)
            return (agendamentoSelected.profissional.pendencias.length > 0)
        }
        return false;
    }
    return false;
}

export const checaSeFaltaPagamento = (agendamentoSelected, reservasNaoPagas) => {
    let faltando : Array<any> = []
    reservasNaoPagas.forEach(reserva => {
        if (agendamentoSelected.profissional_id.toString() === reserva.profissional_id.toString()) {
            faltando.push(reserva);
        }
    })
    return faltando;
}