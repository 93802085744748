import {showToastLoading, showToastSuccess} from "../../utils";
import reservaDAO from "../../../../../../../DAO/reservaDAO";
import {ActionsFn} from "../../../../../../../redux/actions/actions";
import moment from "moment";

const updateAgendamentos = async (dispatch) => {
    let agendamentos = await reservaDAO.findAllInClient();
    dispatch(ActionsFn.setSalas(agendamentos[0]))
    dispatch(ActionsFn.setProfissionais(agendamentos[1]))
    // dispatch(ActionsFn.setAgendamentos(agendamentos[2]))
};

export const getDataString = (agendamentoSelected, executing: boolean, finalizado : boolean) => {
    return finalizado
        ? `Reserva já foi finalizada. Início às ${moment(
            agendamentoSelected.execucao_inicio
        ).format('HH:mm')}, e fim às ${moment(
            agendamentoSelected.execucao_fim
        ).format('HH:mm')}`
        : executing
            ? 'Reserva está sendo executada'
            : 'Reserva ainda não executada'
}

export const onClickIniciarFinalizar = async (executing : boolean, agendamentoSelected, dispatch) => {
    if (executing) {
        showToastLoading(
            'Carregando: Finalizando reserva de ' +
            agendamentoSelected.profissional.nome
        );
        await reservaDAO.executaReserva(
            agendamentoSelected._id
        );
        await updateAgendamentos(dispatch);
        showToastSuccess(
            'Reserva de ' +
            agendamentoSelected.profissional.nome +
            ' finalizada!'
        );
    } else {
        showToastLoading(
            'Carregando: Iniciando reserva de ' +
            agendamentoSelected.profissional.nome
        );
        await reservaDAO.comecaReserva(
            agendamentoSelected._id
        );
        await updateAgendamentos(dispatch);
        showToastSuccess(
            'Reserva de ' +
            agendamentoSelected.profissional.nome +
            ' iniciada!'
        );
    }
}
