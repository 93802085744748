import moment from "moment";
import clienteDAO from "../../../../../DAO/clienteDAO";
import {ActionsFn} from "../../../../../redux/actions/actions";

export const onSubmitDados = async (e, setLoading, profissional, isUserLogged, dispatch) => {
    e.preventDefault();
    const form = e.target;
    console.log(form.dataNascimento.value)
    const data = {
        nome: form.nome.value,
        dataNascimento: form.dataNascimento.value === "" ? "" : moment(new Date(form.dataNascimento.value))
            .add(12, 'hours')
            .toDate(),
        nomeProfissional: form.nomeProfissional.value,
        registro: form.registro.value,
        cpf: form.cpf.value,
        telefone: form.telefone.value,
        endereco: form.endereco.value,
        cep: form.cep.value,
        descricao: form.descricao.value,
    };

    let socialMediaData = {};

    if (
        form.instagram.value !== '' ||
        form.facebook.value !== '' ||
        form.whatsapp.value !== '' ||
        form.url.value !== ''
    ) {
        socialMediaData = {
            instagram: form.instagram.value !== '' ? form.instagram.value : null,
            facebook: form.facebook.value !== '' ? form.facebook.value : null,
            whatsapp: form.whatsapp.value !== '' ? form.whatsapp.value : null,
            url_site: form.url.value !== '' ? form.url.value : null,
        };
    }

    setLoading(true);
    console.log(profissional);
    await clienteDAO.update(
        { _id: profissional._id },
        { ...data, ...socialMediaData }
    );
    if (isUserLogged) {
        dispatch(ActionsFn.setUserLogged({...profissional, ...data, ...socialMediaData}))
    }
    alert('Dados guardados com sucesso!');
    setLoading(false);
};

const options = [
    'Nutricionista',
    'Psicólogo',
    'Fonoaudiólogo',
    'Fisioterapeuta',
    'Acupunturista',
    'Psicopedagogo',
    'Psicanalista',
    'Terapeuta',
    'Farmacêutico',
    'Médico',
    'Aconselhamento Genético',
    'Psiquiatra',
];

export const generateOptions = () =>
    options.map((option) => ({ label: option, value: option }));
