import React from 'react';
import './HorarioAtendimento.sass';
import InputText from '../../../../../../assets/component/inputText/input';
import Button from '../../../../../../assets/component/button/button';
import DiaAtendimento from '../../DiaAtendimento/DiaAtendimento';
import clienteDAO from '../../../../../../DAO/clienteDAO';
import { useSelector } from 'react-redux';

interface Props {
  profissional: any,
}

const HorarioAtendimento = ({ profissional }) => {
  const dias = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

  const [atendimentos, setAtendimentos] = React.useState({
    Seg: [],
    Ter: [],
    Qua: [],
    Qui: [],
    Sex: [],
    Sab: [],
  });
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;
    let data = { horarios: atendimentos };
    if (form.valor.value !== '') { //@ts-ignore
      data = { ...data, valorAtendimento: form.valor.value };
    }
    await clienteDAO.update({ _id: profissional._id }, data);
    setLoading(false);
  };

  React.useEffect(() => {
    if (profissional) {
      if ('horarios' in profissional) {
        setAtendimentos(profissional.horarios);
      }
    }
  }, [profissional]);

  return (
    <div className={"full_container_horarios"}>
      <form onSubmit={onSubmit}>
        <InputText
          defaultValue={profissional.valorAtendimento}
          label={'Valor do Atendimento Inicial'}
          type={'number'}
          decoration={'R$'}
          name={'valor'}
          style={{width: '350px'}}
        />
        <div className={'horario_atendimento'}>
          {/* <div className={'column'}>
            {dias.map((dia, index) =>
              index < 3 ? (
                <DiaAtendimento
                  dia={dia}
                  setAtendimentos={(novosAtendimentos) =>
                    setAtendimentos(novosAtendimentos)
                  }
                  atendimentos={atendimentos}
                />
              ) : (
                <></>
              )
              )} </div>
           */}
          <div className={'column'}>
            {dias.map((dia, index) =>
              /*index >= 3 ? */(
                <DiaAtendimento
                  dia={dia}
                  setAtendimentos={(novosAtendimentos) =>
                    setAtendimentos(novosAtendimentos)
                  }
                  atendimentos={atendimentos}
                />
              ) /* : (
                <></>
              ) */
            )}
          </div>
        </div>
        <br />
        <Button loading={loading} text={'Salvar Horários'} width={'300px'} />
      </form>
    </div>
  );
};

export default HorarioAtendimento;
