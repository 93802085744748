//@ts-nocheck
const COLLECTION = 'sala_bloqueios'

export interface SalaBloqueio {
    _id: string | object,
    sala: Array<string>,
    dia: Date,
    wholeDay: boolean,
    horaInicio: string,
    horaFim: string,
}

const sala_bloqueioDAO = {
    db: null,
    AWSAcess: false,
    setAccess(access) {
        this.AWSAccess = access
    },
    setDb(db) {
        this.db = db
    },
    create(salaBloqueio){
        return this.db.collection(COLLECTION).insertOne(salaBloqueio);

    },
    delete(query){
        return this.db.collection(COLLECTION).deleteMany(query);

    },
    findAll(){
        return this.db.collection(COLLECTION).find({}).toArray();
    }
};
export default sala_bloqueioDAO;
