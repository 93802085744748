import { RemoteMongoDatabase } from 'mongodb-stitch-browser-sdk';

export type TipoPgto = "transf" | "especie" | "debito" | "credito" | "cortesia";

export interface Financeiro {
    _id?: any,
    tipo: "ENTRADA" | "SAIDA",
    valor: number,
    pagadorRecebedor?: string,
    data: Date,
    nf?: string,
    titulo: string,
    quantidade: number,
    formaPgto: TipoPgto,
}

export interface FinanceiroDAO {
    db: RemoteMongoDatabase | null,
    setDb: (db: RemoteMongoDatabase) => void,
    create: (financeiro: Financeiro) => void,
    findAll: () => any,
    findFromClient: (clientId: any) => any,
    editFinanceiro: (id_cliente: string, edits: any) => any,
    deleteFinanceiro: (id) => any
}

const COLLECTION = "financeiro";

const FinanceiroDAO : FinanceiroDAO = {
    db: null,
    setDb(db) { this.db = db},
    create(financeiro) {
        if (this.db) {
            return this.db.collection(COLLECTION).insertOne(financeiro);
        }
    },
    findAll() {
        if (this.db) {
            return this.db.collection(COLLECTION).find({}).toArray();
        }
    },
    findFromClient(nomeCliente) {
        if (this.db) {
            return this.db.collection(COLLECTION).find({pagadorRecebedor: nomeCliente}).toArray();
        }
    },
    editFinanceiro(id_cliente, edits) {
        if (this.db) {
            return this.db.collection(COLLECTION).updateMany({ _id: id_cliente }, { $set: edits });
        }
    },
    deleteFinanceiro(id) {
        if (this.db) {
            return this.db.collection(COLLECTION).deleteOne({_id: id});
        }
    }
}

export default FinanceiroDAO;
