import React from 'react';
import Button from "../../../../../button/button";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../../../redux/storeTypes";
import {getDataString, onClickIniciarFinalizar} from './utils';
import TaxaAtraso from './components/TaxaAtraso';
// import useSocket from "socket.io-client";

const Execucao = () => {

    const [loading, setLoading] = React.useState(false);
    const { agendamentoSelected } = useSelector((state : RootState) => state.agendamentos)
    const [finalizado, setFinalizado] = React.useState(false);
    const [executing, setExecuting] = React.useState(false);

    //@ts-ignore

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (agendamentoSelected) {
            setExecuting(
                'execucao_inicio' in agendamentoSelected &&
                !('execucao_fim' in agendamentoSelected)
            );
            setFinalizado(
                'execucao_inicio' in agendamentoSelected &&
                'execucao_fim' in agendamentoSelected
            );
        }
    },[agendamentoSelected])

    return (
        <React.Fragment>
            <h2>Execução</h2>
            <h3>
                {getDataString(agendamentoSelected, executing,finalizado) }
                <br />
                <br />
                {finalizado ? (
                    <></>
                ) : (
                    <div style={{ display: 'flex' }}>
                        <Button
                            onClick={async () => {
                                setLoading(true);
                                await onClickIniciarFinalizar(executing, agendamentoSelected, dispatch)
                                // socket.emit("update", JSON.stringify(agendamentoSelected))
                                setLoading(false);
                            }}
                            type={'button'}
                            text={
                                'execucao_inicio' in agendamentoSelected
                                    ? 'Finalizar'
                                    : 'Iniciar'
                            }
                            loading={loading}
                            width={'30%'}
                        />
                    </div>
                )}
            </h3>
            {finalizado ? <TaxaAtraso /> : <></>}

        </React.Fragment>
    );
};

export default Execucao;
