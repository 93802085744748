import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ActionsFn} from '../../redux/actions/actions';
import administradorDAO from '../../DAO/administradorDAO';
import './login.sass';
import InputText from '../../assets/component/inputText/input';
import Button from '../../assets/component/button/button';
import CheckBox from '../../assets/component/checkbox/checkbox';
import ModoPaisagem from '../../assets/component/modoPaisagem/modoPaisagem';
import clienteDAO from '../../DAO/clienteDAO';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import ForgotPasswordModal from '../../assets/component/modals/login/ForgotPasswordModal';
import {UserPasswordAuthProviderClient} from 'mongodb-stitch-browser-sdk';
import TipoUsuarioModal from '../../assets/component/modals/login/TipoUsuarioModal/TipoUsuarioModal';
import bcrypt from 'bcryptjs';
import AWSDataModal from '../../assets/component/modals/login/AWSDataModal/AWSDataModal';
import IntegraLogo from "../../assets/integra_logo.png"

const encrypt = async (string, salts = 10) => {
    return await bcrypt.hash(string, salts);
};

const LoginPage = () => {
    const [loading, setLoading] = React.useState(true);
    const [checked, setChecked] = React.useState(false);
    const [modalForgotPassword, setModalForgotPassword] = React.useState(false);
    const [tipoUsuarioShow, setTipoUsuario] = React.useState(false);
    const [AWSDataShow, setAWSDataShow] = React.useState(false);
    const [timesClicked, setTimesClicked] = React.useState(0);
    const [admArray, setAdmArray] = React.useState(null);
    const [clientesArray, setClientesArray] = React.useState(null);

    const d = useDispatch();
    const story = useHistory();

    const {mongoClient} = useSelector((state) => state.general);

    const saveUserLogged = (email, pwd) => {
        if (checked) {
            localStorage.setItem('email', email);
            localStorage.setItem('pwd', pwd);
        }
    };

    const performLogin = async (email, senha) => {
        let [clientes, administradores] = [[], []];
        setLoading(true);

        const savePwd = async (senha, cliente) => {
            let passwordEncrypted = await encrypt(senha, 10);
            clienteDAO.update({_id: cliente._id}, {senha: passwordEncrypted});
        };

        try {
            let x = await clienteDAO.login(mongoClient, email, senha);
            console.log(mongoClient.auth.currentUser.isLoggedIn)
            clientes = await clienteDAO.find({email: email});
            administradores = await administradorDAO.find({email: email});
            if (clientes.length > 0 && administradores.length > 0) {
                setTipoUsuario(true);
                await savePwd(senha, clientes[0]);
                setAdmArray(administradores);
                setClientesArray(clientes);
                if (checked) saveUserLogged(email, senha);
            } else if (clientes.length > 0) {
                await savePwd(senha, clientes[0]);
                d(ActionsFn.setUserLogged(clientes[0]));
                story.push('/reserva_dia');
                if (checked) saveUserLogged(email, senha);
            } else if (administradores.length > 0) {
                d(ActionsFn.setUserLogged(administradores[0]));
                story.push('/agendamento_adm');
                if (checked) saveUserLogged(email, senha);
            }
            if (administradores.length <= 0 && clientes.length <= 0) {
                alert('Erro interno. Por favor, contate os desenvolvedores.');
            }
        } catch (err) {
            console.log(err);
            if (err.errorCode === 46) {
                alert('Usuário ou senha inválidos.');
            } else {
                alert('Erro desconhecido! Log do erro ' + err);
            }
        }
        setLoading(false);
    };

    React.useEffect(() => {
        let [email, senha] = [
            localStorage.getItem('email'),
            localStorage.getItem('pwd'),
        ];
        if (mongoClient) {
            if (email && senha) {
                setLoading(true);
                performLogin(email, senha)
                    .then(() => {
                        setLoading(false);
                    })
                    .catch(() => setLoading(false));
            } else {
                setLoading(false);
            }
        }
    }, [mongoClient]);

    let matrix = new Array(5);
    for (let i = 0; i < 5; i++) {
        matrix[i] = new Array(7).fill(0);
    }
    let iterador = moment(new Date()).startOf('month');
    let dataInicial = moment(new Date());

    for (let i = 0; i < 5; i++) {
        for (let j = 0; j < 7; j++) {
            if (
                iterador.toDate().getDay() === j &&
                iterador.isSame(dataInicial, 'month')
            ) {
                matrix[i][j] = iterador.date();
                iterador.add(1, 'day');
            } else {
                matrix[i][j] = 0;
            }
        }
    }

    React.useEffect(() => setAWSDataShow(timesClicked >= 10), [timesClicked])

    return (
        <div className={'login_container'}>
            <ModoPaisagem/>
            <TipoUsuarioModal
                show={tipoUsuarioShow}
                onClose={() => {
                    setTipoUsuario(false);
                    setLoading(false);
                }}
                onClickAdm={() => {
                    d(ActionsFn.setUserLogged(admArray[0]));
                    story.push('/agendamento_adm');
                }}
                onClickProfissional={() => {
                    d(ActionsFn.setUserLogged(clientesArray[0]));
                    story.push('/reserva_dia');
                }}
            />
            <AWSDataModal
                show={AWSDataShow}
                onClose={() => {
                    setTimesClicked(0);
                    setAWSDataShow(false)
                }}
            />
            <ForgotPasswordModal
                onSubmit={async (e) => {
                    e.preventDefault();
                    const form = e.target;
                    setLoading(true);
                    try {
                        await mongoClient.auth
                            .getProviderClient(UserPasswordAuthProviderClient.factory)
                            .sendResetPasswordEmail(form.email.value.toLowerCase());
                        alert(
                            'Por favor, cheque seu e-mail, enviamos um link para redefinição de senha.'
                        );
                    } catch (e) {
                        alert(e);
                    }
                    setModalForgotPassword(false);
                    setLoading(false);
                }}
                loading={loading}
                onClose={() => {
                    setModalForgotPassword(false);
                }}
                show={modalForgotPassword}
            />
            <div className={'ball'}/>
            <div className={'logo_container'}>
                <img
                    onClick={() => {
                        console.log("Times clicked: " + timesClicked);
                        setTimesClicked(timesClicked + 1);
                    }}
                    alt={'integra_logo'}
                    src={IntegraLogo}
                />
                <p>Sistema de Gerenciamento</p>
            </div>
            <div className={'login'}>
                <h1>Login</h1>
                <div className={'card'}>
                    <form
                        onSubmit={async (e) => {
                            e.preventDefault();
                            setLoading(true);
                            await performLogin(e.target.email.value.toLowerCase(), e.target.senha.value);
                            setLoading(false);
                        }}
                    >
                        <InputText
                            name={'email'}
                            label={'E-mail'}
                            type={"email"}
                            required={true}
                            placeholder={'Ex: joao@example.com'}
                        />
                        <InputText
                            required={true}
                            name={'senha'}
                            label={'Senha'}
                            type={'password'}
                            placeholder={'Informe sua senha'}
                        />
                        <div style={{marginTop: 0, display: "inline-block", marginBottom: 12}}>
                            <CheckBox
                                onCheck={(checked) => {
                                    setChecked(!checked);
                                }}
                                label={'Manter-me Conectado'}
                            />
                        </div>
                        <Button loading={loading} type={'submit'} text={'Confirmar'}/>
                    </form>
                </div>
                <p
                    onClick={() => setModalForgotPassword(true)}
                    className={'forgot_pwd'}
                >
                    Esqueceu sua senha?
                </p>
            </div>
        </div>
    );
};

export default LoginPage;
