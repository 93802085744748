import React from 'react';
import InputText from "../../../../../../inputText/input";
import Button from "../../../../../../button/button";
import "./TaxaAtraso.sass"
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../../../../redux/storeTypes";
import reservaDAO from "../../../../../../../../DAO/reservaDAO";
import {ActionsFn} from "../../../../../../../../redux/actions/actions";
import {transformStringToReais} from "../../../../../../../AuxFunctions";
const TaxaAtraso = () => {

    const { agendamentoSelected } = useSelector((state: RootState) => state.agendamentos)
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch()

    const updateAgendamentos = async () => {
        let agendamentos = await reservaDAO.findAllInClient()
        dispatch(ActionsFn.setSalas(agendamentos[0]));
        dispatch(ActionsFn.setProfissionais(agendamentos[1]));
        // dispatch(ActionsFn.setAgendamentos(agendamentos[2]));
    }

    const onSubmit = async e => {
        setLoading(true);

        e.preventDefault();
        const form = e.target;

        await reservaDAO.criaPendencia(
            agendamentoSelected,
            agendamentoSelected.profissional._id,
            +form.quantidade.value, "atraso");
        await updateAgendamentos()
        setLoading(false)
    }


    return (
        <React.Fragment>
            <h2>Taxa de Atraso</h2>
            <form onSubmit={onSubmit}>
                <div className={"atraso_flex"}>
                    <InputText
                        name={"quantidade"}
                        label={"Quantidade de Minutos Atrasados"}
                        step={1}
                        type={"number"} />
                    <Button
                        loading={loading}
                        text={"Criar Pendência"}/>
                </div>
            </form>
            {
                (agendamentoSelected["pendencia"]) ?
                    <p style={{color: "red"}}>
                        Taxa pendente de: &nbsp;
                        {transformStringToReais(""+agendamentoSelected.pendencia.quantidade)}
                    </p>
                    : <></>
            }
        </React.Fragment>
    );
};

export default TaxaAtraso;
