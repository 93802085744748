import React from 'react';
import ModalParent from '../../modal_parent/modal';
import PropTypes from 'prop-types';
import Button from '../../../button/button';
import {useSelector} from 'react-redux';
import { RootState } from '../../../../../redux/storeTypes';
import moment from 'moment';
import Cookies from 'universal-cookie';

const ModalBirthday = (props) => {

    const cookies = new Cookies();

    const { profissionais } = useSelector((state: RootState) => state.profissionais);
    const [aniversariantes, setAniversariantes] = React.useState([]);
    
    React.useEffect(() => {
        if (profissionais) {
            let thisYear = moment().year()
            setAniversariantes(profissionais.filter(profissional => {
                if (profissional.dataNascimento) {
                    return moment()
                        .isSame(
                            moment(new Date(profissional.dataNascimento).setFullYear(thisYear)).add(4, 'hours'), 'day');
                }
                return false;
            }));
        }
    }, [profissionais]);

    React.useEffect(() => {
        console.log(aniversariantes);
        console.log(cookies.get('birthday'))
    }, [aniversariantes])

    if (aniversariantes.length === 0 || cookies.get('birthday') === "done")
        return <></>
    
    return (
        <ModalParent
            onSubmit={(e) => e.preventDefault()}
            show={props.show}
            header={
                <header>
                    <div>
                        <h1>Dia de Festa!</h1>
                    </div>
                    <div className={'close_container'} onClick={props.close}>
                        <i className={'fa fa-times'} />
                    </div>
                </header>
            }
            body={
                <div className={'body'}>
                    <p>Olá, hoje é o <b>aniversário</b> de alguns dos nossos parceiros!</p>
                    <p>São eles(as):</p>
                    <ul>
                        {
                            aniversariantes.map((profissional : any) => (
                                <li key={profissional._id}>{profissional.nome}</li>
                            ))
                        }
                    </ul>
                </div>
            }
            footer={
                <div className='footer'>
                    <Button onClick={() => {
                        cookies.set('birthday', 'done', {
                            expires: moment().add(1, 'day').hours(0).toDate(),
                            path: '/'
                        })
                        console.log('Birthday cookie');
                        props.close();
                    }} text='Obrigado por avisar!' width="80%"/>
                </div>
            }
        />
    );
};

ModalBirthday.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func.isRequired,
};

export default ModalBirthday;
