import React from 'react';
import PropTypes from 'prop-types';
import ModalParent from '../../modal_parent/modal';
import '../TipoUsuarioModal/TipoUsuarioModal.sass';
import { useDispatch, useSelector } from 'react-redux';
import { ActionsFn } from '../../../../../redux/actions/actions';
import clienteDAO from '../../../../../DAO/clienteDAO';
import administradorDAO from '../../../../../DAO/administradorDAO';
import salaDAO from '../../../../../DAO/salaDAO';
import reservaDAO from '../../../../../DAO/reservaDAO';
import logDAO from '../../../../../DAO/logDAO';
import NotificacaoDAO from "../../../../../DAO/NotificacaoDAO";
import sala_bloqueioDAO from "../../../../../DAO/sala_bloqueioDAO";
import { RootState } from '../../../../../redux/storeTypes';

const AWSDataModal = (props : any) => {

    const dispatch = useDispatch();
    const { awsData } = useSelector((state : RootState) => state.general);

    const setDatabaseGetIntoDAOs = (accessType) => {
        console.log(accessType ? "Changing to AWS" : "Changing to MongoDB Realm");
        clienteDAO.setAccess(accessType);
        administradorDAO.setAccess(accessType);
        salaDAO.setAccess(accessType);
        reservaDAO.setAccess(accessType);
        logDAO.setAccess(accessType);
        NotificacaoDAO.setAccess(accessType);
        sala_bloqueioDAO.setAccess(accessType);
    };


    React.useEffect(() => {
            console.log("Getting from AWS");
            setDatabaseGetIntoDAOs(awsData);
    }, [awsData])

    return (
        <ModalParent
            show={props.show}
            close={props.onClose}
            header={
                <header>
                    <div>
                        <h1>Tipo de Ponte ao Banco de Dados</h1>
                    </div>
                    <div className={'close_container'} onClick={props.onClose}>
                        <i className={'fa fa-times'} />
                    </div>
                </header>
            }
            body={
                <div className={'tipos_usuarios'}>
                    <p>Use dados da AWS <b>apenas</b> quando o servidor do MongoDB Realm estiver inoperante ou com falhas.</p>
                    <div onClick={() => dispatch(ActionsFn.setAWSData(true))}>
                        <h1>Usar Dados AWS</h1>
                    </div>
                    <div onClick={() => dispatch(ActionsFn.setAWSData(false))}>
                        <h1>Usar dados MongoDB Realm</h1>
                    </div>
                </div>
            }
        />
    );
};

AWSDataModal.propTypes = {
    loading: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
};

export default AWSDataModal;
