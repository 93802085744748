import axios from 'axios';
import {RemoteMongoDatabase, UserPasswordAuthProviderClient, UserPasswordCredential,} from 'mongodb-stitch-browser-sdk';
import logDAO from './logDAO';
import {ObjectId} from "mongodb-stitch-browser-sdk/dist/browser/stitch";

const COLLECTION = 'clientes';

export interface Profissional {
  nome: string;
  _id?: string;
  email: string;
  foto_url?: string;
  nomeProfissional?: string;
  creditos: number;
  dataNascimento?: Date | string;
  saldo?: number;
  bloqueadoTarde?: boolean;
  bloquearVisualizacao?: boolean;
  pendencias?: any,
  registro?: string,
  cpf?: string,
  telefone?: string,
  endereco?: string,
  cep: string,
  instagram?: string,
  facebook?: string,
  whatsapp?: string,
  url_site?: string,
  descricao?: string,
}

interface clienteDAO {
  db: RemoteMongoDatabase | null;
  AWSAccess: boolean;
  setDb: any;
  setAccess: any;
  delete: any;
  create: any;
  update: (query: any, changes: any) => any;
  editCliente: any;
  registerAuth: any;
  addUser: any;
  login: any;
  findAll: any;
  updateInc: any;
  find: any;
  fixarSalaNoTopo: any;
  makeProfissionaisAHash: any;
  getProfissionalById: (
      profissionais: Array<Profissional>,
      profissional_id: ObjectId | string,
  ) => Profissional;
}

export const profissionaisAtivos = (profissionais, agendamentos) => {
  return profissionais.filter(profissional => {
    if (profissional.bloquearVisualizacao) { return false }
    if (profissional.nome === "Júlio Nakano") { return true }
    if (profissional.nome === "Flávio Carlos Souza Ribeiro") { return true }
    for (const agendamento of agendamentos) {
      if (agendamento.profissional_id.toString() === profissional._id.toString())
        return true;
    }
    return false;
  })
}

const clienteDAO: clienteDAO = {
  db: null,
  AWSAccess: false,
  setDb(db) {
    this.db = db;
  },
  setAccess(access: boolean) {
    //@ts-ignore
    this.AWSAccess = access
  },
  async delete(query) {
    if (this.AWSAccess) {
      return axios.post(process.env.REACT_APP_AWS_SIDEBRIDGE + "cliente/delete", {query: query})
    } else {
      if (this.db) {
        return this.db.collection(COLLECTION).deleteOne(query);
      }
    }
  },
  create(cliente) {
    if (this.AWSAccess) {
      return axios.post(process.env.REACT_APP_AWS_SIDEBRIDGE + "cliente/create", {cliente: cliente});
    } else {
      if (this.db) {
        return this.db.collection(COLLECTION).insertOne(cliente);
      }
    }
  },
  update(query, changes) {
    if (this.AWSAccess) {
      return axios.post(process.env.REACT_APP_AWS_SIDEBRIDGE + "cliente/update", {
        query: query,
        changes: changes
      });
    } else {
      if (this.db) {
        return this.db
            .collection(COLLECTION)
            .updateMany(query, {$set: changes});
      }
    }
  },
  updateInc(id_cliente, edits) {
    if (this.db)
      return this.db.collection(COLLECTION).updateMany({_id: id_cliente}, {$inc: edits})
  },
  editCliente(id_cliente, edits) {
    console.log('edit cliente');
    return this.update({_id: id_cliente}, edits);
  },
  async registerAuth(client, email, password) {
    if (this.AWSAccess) {
      throw Error("Não é possível registrar um novo profissional utilizando AWS. Por favor, volte com MongoDB Realm para realizar o cadastro");
    } else {
      try {
        const emailPasswordClient = client.auth.getProviderClient(
            UserPasswordAuthProviderClient.factory
        );
        await emailPasswordClient.registerWithEmail(email, password);
      } catch (err) {
        alert(
            'O usuário de e-mail já foi definido e talvez deletado anteriormente. Para redefinir ' +
            'senha, por favor, peça ao usuário que vá em "Esqueceu sua Senha?" na tela de Login.'
        );
      }
    }
  },
  async addUser(client, email, password, clienteData, userLogged) {
    if (this.AWSAccess) {
      throw Error("Não é possível registrar um novo profissional utilizando AWS. Por favor, volte com MongoDB Realm para realizar o cadastro");
    } else {
      console.log('add cliente user');
      if (userLogged) {
        logDAO.create({
          usuario: userLogged,
          log: 'Adicionou ' + clienteData.nome + ' a lista de profissionais.',
          data_hora: new Date(),
        });
      }
      await this.registerAuth(client, email, password);
      return this.create({...clienteData, email});
    }
  },
  async login(client, email, password) {
    if (this.AWSAccess) {
      const { data } = await axios.post(process.env.REACT_APP_AWS_SIDEBRIDGE + "login", {
        email: email,
        senha: password,
      });
      if ('error' in data) { throw Error(data.error); }
      return data;
    } else {
      return client.auth.loginWithCredential(
          new UserPasswordCredential(email, password),
      );
    }
  },
  async findAll() {
    if (this.AWSAccess) {
      const {data} = await axios.post(process.env.REACT_APP_AWS_SIDEBRIDGE + "cliente/find_all");
      return data;
    } else {
      if (this.db) {
        return this.db
            .collection(COLLECTION)
            .find({}, {sort: {nome: 1}})
            .toArray();
      }
    }
  },
  async find(query) {
    if (this.AWSAccess) {
      const {data} = await axios.post(process.env.REACT_APP_AWS_SIDEBRIDGE + "cliente/find", {query: query});
      return data;
    } else {
      if (this.db) {
        return this.db.collection(COLLECTION).find(query).toArray();
      }
    }
  },
  fixarSalaNoTopo(sala, user) {
    if (this.db) {
      if (user.sala_fixa) {
        if (user.sala_fixa.toString() === sala._id.toString()) {
          return this.db
              .collection(COLLECTION)
              .updateOne({_id: user._id}, {$set: {sala_fixa: null}});
        }
      }
      return this.db
          .collection(COLLECTION)
          .updateOne({_id: user._id}, {$set: {sala_fixa: sala._id}});
    }
  },
  makeProfissionaisAHash(profissionaisArray) {
    const dict = {};
    for (const profissional of profissionaisArray) {
      dict[profissional._id.toString()] = profissional;
    }
    return dict;
  },
  getProfissionalById(
      profissionais: Array<Profissional>,
      profissional_id: any
  ): Profissional {
    let profissionalSelected: Profissional | any = null;
    profissionais.forEach((profissional) => {
      if (profissional_id) {
        if (profissional._id) {
          if (profissional._id.toString() === profissional_id.toString()) {
            profissionalSelected = profissional;
          }
        }
      }
    });
    return profissionalSelected;
  },
};

export default clienteDAO;
