import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import './dash.sass';
import AdministradorTopbar from '../../../assets/component/adm_topbar/adm_topbar';
import Chart from './components/Chart';
import Indicators from './components/Indicators';
import axios from "axios";
import moment from "moment";
import {useSelector} from "react-redux";
import {getProfessionalsQuantity} from "./utils";
import QuickDetails from "./components/QuickDetails";
import ChartProfessionals from "./components/ChartProfessionals";

const DashboardPage = (props) => {

  const [agendamentos, setAgendamentos] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const agendamentosTotais = useSelector((state) => state.agendamentos.agendamentos);

  const [agendamentosPendentes] = React.useState([]);

  const deserializeEJSON = (arr) => {
      arr.forEach(ejson => {
          for (let key in ejson) {
              for (let keyInside in ejson[key]) {
                  switch (keyInside) {
                      case "$date":
                          ejson[key] = moment.utc(+ejson[key][keyInside]["$numberLong"]).toDate();
                          break;
                      case "$numberInt":
                          ejson[key] = +ejson[key]["$numberInt"];
                          break;
                      case "$numberDouble":
                           ejson[key] = +ejson[key]["$numberDouble"];
                           break;
                      case "$oid":
                          ejson[key] = ejson[key][keyInside];
                          break;
                  }
              }
          }
      })
  }

  React.useEffect(() => {
    axios.post('https://webhooks.mongodb-realm.com/api/client/v2.0/app/integra-ggmpa/service/requestReservas/incoming_webhook/getReservas')
        .then(r => {
            deserializeEJSON(r.data);
            setAgendamentos(r.data);
            console.log(r.data);
            setLoading(false);
        })
  },  []);


  return 'nome' in props.userLogged ? (
    <div>
      <AdministradorTopbar pageSelected={'dashboard'} />
      <Indicators />
      <div className={'bottom_column'}>
        {
          loading ? <p>Carregando...</p> :
              <React.Fragment>
                  <Chart agendamentos={agendamentos} />
                  <QuickDetails
                      agendamentos={props.agendamentos}
                      agendamentosTotais={agendamentosTotais}
                      agendamentosPendentes={agendamentosPendentes}
                      administradores={props.administradores} profissionais={props.profissionais} />
              </React.Fragment>
        }
      </div>
        <ChartProfessionals />
    </div>
  ) : (
    <Redirect to={'/'} />
  );
};

const mapStateToProps = (state) => ({
  profissionais: state.profissionais.profissionais,
  agendamentos: state.agendamentos.agendamentos,
  userLogged: state.general.userLogged,
  administradores: state.administradores.administradores,
});

export default connect(mapStateToProps)(DashboardPage);
