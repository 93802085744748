import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import moment from "moment";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/storeTypes";
import "./ChartAgendamentos.sass"

const ChartAgendamentos = () => {

    const {userLogged } = useSelector((state: RootState) => state.general);
    const {agendamentos} = useSelector((state: RootState) => state.agendamentos);

    React.useEffect(() => {

        let profAgendamentos = agendamentos.filter(agendamento => agendamento.profissional_id.toString() === userLogged._id.toString());

        profAgendamentos = profAgendamentos.filter(agendamento =>
            moment(agendamento.data).isAfter(moment(new Date()).subtract(30, "days")))

        let array : Array<any> = [];
        let date = moment(moment().subtract(30, "days")).add(1, "days");
        while (moment(new Date()).add(15, "days").isAfter(date, 'day')) {
            array.push({date: date.toDate(), value: 0});
            date.add(1, 'day');
        }

        //Cria Frequency Table
        let tabelaFreq = {};
        profAgendamentos.forEach(agendamento => {
            if (moment(new Date(agendamento.data)).format('DD/MM/YYYY') in tabelaFreq) {
                tabelaFreq[moment(new Date(agendamento.data)).format('DD/MM/YYYY')] += 1;
            } else {
                tabelaFreq[moment(new Date(agendamento.data)).format('DD/MM/YYYY')] = 1;
            }
        });

        Object.keys(tabelaFreq).forEach(key => {
            for (let i = 0; i < array.length; i++) {
                if (moment(array[i].date).isSame(moment(key, 'DD/MM/YYYY'), 'day')) {
                    array[i].value = tabelaFreq[key];
                    break;
                }
            }
        });

        let chart = am4core.create('chart_client', am4charts.XYChart);

        chart.paddingRight = 20;

        chart.data = array;

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        //@ts-ignore
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.minWidth = 35;

        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.dateX = "date";
        series.dataFields.valueY = "value";

        series.tooltipText = "{valueY.value}";
        chart.cursor = new am4charts.XYCursor();

        let scrollbarX = new am4charts.XYChartScrollbar();
        scrollbarX.series.push(series);
        chart.scrollbarX = scrollbarX;

    }, [agendamentos]);


    return (
        <div>
            <h2>Seu uso nos últimos 30 dias</h2>
            <div id={"chart_client"} />
        </div>
    );
};

export default ChartAgendamentos;
