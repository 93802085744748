import React, {useRef} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/storeTypes";
import Select from  "react-select";
import moment from "moment";
import "./BuscaDisponibilidade.sass"
import Button from "../../../../../assets/component/button/button";
import {eSabado, getDataFromForm, selectOptions, sortSalas, submitDisponibilidade} from './utils';

const BuscaDisponibildade = () => {

    const {salas} = useSelector((state: RootState) => state.salas);
    const {userLogged} = useSelector((state: RootState) => state.general);
    const [dateSelected, setDateSelected] = React.useState<Date | null>(null);
    const {agendamentos} = useSelector((state: RootState) => state.agendamentos);
    const [salasDisponiveis, setSalasDisponiveis]  = React.useState([]);
    const [horaSelected, setHoraSelected] = React.useState("");

    const clickedRef = useRef(false);

    const verificaDisponibilidadeDasSalas = e => {
        submitDisponibilidade(e, agendamentos, salas, setSalasDisponiveis);
        clickedRef.current = true;
        setHoraSelected(getDataFromForm(e.target).hora_inicio + "h às "+getDataFromForm(e.target).hora_fim+"h")
    }

    return (
        <div className={"disponibilidade_container"}>
            <h2>Buscar Disponibilidade</h2>
            <form onSubmit={verificaDisponibilidadeDasSalas}>
            <div className={"campos"}>
                <div>
                <p>Sala:</p>
                <Select
                    name={"sala_option"}
                    className={"sala_option"}
                    options={[{label: "Todas", value: "Todas"},
                        ...sortSalas(salas).map((sala) => ({label: sala.nome, value: sala.nome}))]}/>
                </div>
                <div>
                    <p>Data:</p>
                    <input
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        name={"date"}
                        type={"date"} onChange={e => {
                        setDateSelected(moment(e.target.value).hours(16).toDate())
                    }}/>
                </div>
                <div>
                <p>Horário:</p>
                <div className={"options_horas"}>
                    <Select
                        name={"hora_inicio"}
                        className={"option"}
                        options={selectOptions(userLogged, eSabado(dateSelected) ? 8 : 9, false, dateSelected)}/>
                    às
                    <Select
                        name={"hora_fim"}
                        className={"option"}
                        options={selectOptions(userLogged, eSabado(dateSelected) ? 8 : 9, true, dateSelected)} />
                </div>
                </div>
                <div>
                    <Button text={"Buscar"}/>
                </div>
            </div></form>
            {
                ((salasDisponiveis.length > 0) && clickedRef.current) ? salasDisponiveis
                    .map((sala) => (<p className={"sala_disponivel"} key={sala}>
                    {sala} <span>{horaSelected}</span>
                </p>)) : <p>Não há Salas Disponíveis para esse horário neste dia.</p>
            }
        </div>
    );
};

export default BuscaDisponibildade;
