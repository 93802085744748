import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

const Indicators = () => {
  const [agendamentosPendentes, setAgendamentosPendentes] = React.useState([]);
  const [agendamentosCancelados, setAgendamentosCancelados] = React.useState([]);
  const [agendamentosMes, setAgendamentosMes] = React.useState([]);
  const [agendamentosMesAnterior, setAgendamentosMesAnterior] = React.useState([]);
  
  const { agendamentos } = useSelector((state) => state.agendamentos);

  React.useEffect(() => {
    if (agendamentos.length > 0) {
      const agendamentosDoMes = agendamentos.filter(agendamento => moment(agendamento.data).isSame(new Date(), 'month'))
      setAgendamentosMes(agendamentosDoMes);
      setAgendamentosPendentes(agendamentosDoMes.filter(agendamento => !agendamento.cancelado));
      setAgendamentosCancelados(agendamentosDoMes.filter(agendamento => agendamento.cancelado));
      const agendamentosDoMesAnterior = (agendamentos.filter(agendamento => 
        moment(new Date(agendamento.data)).add(1, 'month').isSame(new Date(), 'month')));
      setAgendamentosMesAnterior(agendamentosDoMesAnterior);
    }
  }, [agendamentos]);

  const calculaPorcentagemRelacionada = (valorComparado, valorTotal) => {
    return valorComparado * 100 / valorTotal;
  }

  const porcentagemParaString = (valorComparado, valorTotal, porcentagem) => {
    if (porcentagem) {
      return porcentagem.toFixed(2).toString().replace('.', ',');
    }
    return calculaPorcentagemRelacionada(valorComparado, valorTotal).toFixed(2).toString().replace('.', ',')
  }

  const porcentagemUpOrDown = (valorComparado, valorTotal) => {
    const porcentagem = calculaPorcentagemRelacionada(valorComparado, valorTotal);
    return { valor: porcentagemParaString(null, null, porcentagem), maior: porcentagem > 100 };
  }

  const agendamentosMesAnteriorCancelados = (agendamentosMesAnterior) => {
    return agendamentosMesAnterior.filter(agendamento => agendamento.cancelado);
  }

  return (
    <div className={'indicators_container'}>
      <div>
        <h5>
          {porcentagemUpOrDown(agendamentosMes.length, agendamentosMesAnterior.length).valor}% &nbsp;
          <i className={porcentagemUpOrDown(agendamentosMes.length, agendamentosMesAnterior.length).maior ? 'fa fa-arrow-up' : 'fa fa-arrow-down'} /> &nbsp; que no mês anterior
        </h5>
        <h2>{agendamentosPendentes.length} reservas no mês</h2>
      </div>
      <div>
        <h2>{agendamentosCancelados.length} reservas canceladas</h2>
        <h5>
          {porcentagemUpOrDown(agendamentosCancelados.length, agendamentosMesAnteriorCancelados(agendamentosMesAnterior).length).valor}% &nbsp;
          <i className={porcentagemUpOrDown(agendamentosCancelados.length, agendamentosMesAnteriorCancelados(agendamentosMesAnterior).length).maior ? 'fa fa-arrow-up' : 'fa fa-arrow-down  '} /> &nbsp; que no mês anterior
        </h5>
      </div>
    </div>
  );
};

export default Indicators;
