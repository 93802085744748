import Actions from "../actions/actions";

const initialState = {
    titulos: [],
    transacoes: [],
    tituloSelected: null,
    loadingPagamento: [],
}

const FinanceiroReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case Actions.setTitulos:
            return { ...state, titulos: payload };
        case Actions.setTransacoes:
            return { ...state, transacoes: payload };
        case Actions.selectTitulo:
            return {...state, tituloSelected: payload };
        case Actions.setLoadingPagamento:
            return {...state, loadingPagamento: payload };
        default:
            return state;
    }
}

export default FinanceiroReducer;
