type tipoLead = "paciente" | "profissional"

export interface Lead {
    _id?: any,
    nome: string,
    telefone: string,
    email: string,
    tipo: tipoLead,
    profissional_id?: string,
    data?: Date,
    estado: "pendente" | "Em atendimento" | "convertido" | "abandonado"
}

const LeadDAO = {
    db: null,
    setDb(db: any) {
        this.db = db
    },
    findAll() {
        if (this.db) {
            //@ts-ignore
            return this.db.collection("leads").find({}).toArray()
        }
    },
    editLead(_id, changes) {
        if (this.db) {
            //@ts-ignore
            return this.db.collection("leads").updateMany({_id: _id}, {$set: changes})
        }
    },
    removeLead(_id) {
        if (this.db) {
            //@ts-ignore
            return this.db.collection("leads").deleteMany({_id: _id})
        }
    }
}

export default LeadDAO;

