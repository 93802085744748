import React from 'react';
import ClienteTopbar from "../../../assets/component/cliente_topbar/topbar";
import AlternatingTab from "../../../assets/component/alternating_tab/alt_tab";
import Snack from "../../../assets/component/Snack/snack";
import CalendarAgendamentos from "../../../assets/component/calendar_agendamentos/CalendarAgendamentos";
import ModalAgendamento from "../../../assets/component/modals/cliente/modal_agendamento/modalAgendamento";
import ModalTypes from "../../../assets/modal_types";
import {useDispatch, useSelector} from "react-redux";
import Actions, {ActionsFn} from "../../../redux/actions/actions";
import {RootState} from "../../../redux/storeTypes";
import ModalSelectDay from "../../../assets/component/modals/administrativo/modal_select_day/ModalSelectDay";
import ModalEditAgendamento
    from "../../../assets/component/modals/administrativo/modal_edit_agendamento/EditAgendamento";
import moment from "moment/moment";
import reservaDAO from "../../../DAO/reservaDAO";
import salaDAO from "../../../DAO/salaDAO";
import clienteDAO from "../../../DAO/clienteDAO";
import {sortSalas} from "../agendamentos";
import sala_bloqueioDAO from "../../../DAO/sala_bloqueioDAO";
import NotificacaoDAO from "../../../DAO/NotificacaoDAO";

const ReservaDia : React.FC<any> = () => {

    const [selectedTab, selectTab] = React.useState(0);
    const d = useDispatch();

    const { showModal, modalType, database, userLogged, mongoClient } = useSelector((state: RootState) => state.general);
    const { agendamentos } = useSelector((state: RootState) => state.agendamentos)
    const { salas } = useSelector((state: RootState) => state.salas);
    const [stt, setState] = React.useState(false)

    React.useEffect(() => {
        console.log("Current User", mongoClient.auth.currentUser.isLoggedIn);
        if (clienteDAO.db && 'nome' in userLogged) {
            salaDAO.findAll().then((res) => {
                if (userLogged.sala_fixa) {
                    res.sort(sortSalas);
                    // coloca no topo. Função Swap
                    for (let i = 0; i < res.length; i++) {
                        if (
                            userLogged.sala_fixa.toString() === res[i]._id.toString()
                        ) {
                            let aux = res[0];
                            res[0] = res[i];
                            res[i] = aux;
                        }
                    }
                } else {
                    res.sort(sortSalas);
                }
                d(ActionsFn.setSalas(res));
            });
            sala_bloqueioDAO.findAll().then((res) => {
                d(ActionsFn.setBloqueiosSalas(res));
            });
            getReservasFromDb();
            NotificacaoDAO.findAll()
                .then((res) => {
                    d(ActionsFn.setNotifications(res));
                })
                .catch((e) => {
                    alert(e);
                });
        }
    }, [userLogged]);

    const watchItems = () => {
        const streamPromise = database.collection('reservas').watch();
        const getStream = () => streamPromise;
        const closeStream = () => streamPromise.then((stream) => stream.close);
        return [getStream, closeStream];
    }

    React.useEffect(() => {
        if (salas[0] && salas[0].nome !== 'Sala 1') {
            d(ActionsFn.setSalas(salas.sort((a, b) => {
                return (+a.nome.split(' ')[1] < +b.nome.split(' ')[1] ? -1 : 1)
            })))
        }
        setState(!stt);
    }, [salas, agendamentos]);

    React.useEffect(() => {
        if (database) {
            const [getStream, closeStream] = watchItems();
            getStream().then((stream) => { stream.onNext(() => { getReservasFromDb() })});
            return closeStream;
        }
    }, [database]);

    const getReservasFromDb = () => {
        let today = moment();
        console.time("Get in client");
        Promise.all([
            reservaDAO
                .findQuery({data: {$gt: today.subtract(20, "days").toDate()}}),
            salaDAO.findAll()])
            .then(([reservas, salas]) => {
                d({type: Actions.setFetchedAgendamentos, payload: true});
                for (let i = 0; i < reservas.length; i++) {
                    reservas[i]["sala"] = salas.find(sala => sala._id.toString() === reservas[i].sala_id.toString());
                }
                d(ActionsFn.setAgendamentos(reservas))
                d(ActionsFn.setProfissionalReservas(reservaDAO.findReservaDeCliente(userLogged._id, reservas)))
                console.timeEnd("Get in client");
            })
            .catch((e) => {
                alert(e);
            });
    }

    return (
        <div>
            <ClienteTopbar/>
            <ModalEditAgendamento
                isClient
                close={() => d(ActionsFn.closeModal())}
                show={showModal && modalType === ModalTypes.editarAgendamento}></ModalEditAgendamento>
            <ModalSelectDay
                isClient={true}
                show={showModal && modalType === ModalTypes.selectDay}
                close={() => d(ActionsFn.closeModal())}
                onChangeDay={(day) => {
                    if (day) d(ActionsFn.selectDate(day));
                }}
            />
            <ModalAgendamento
                close={() => d(ActionsFn.closeModal())}
                show={showModal && modalType === ModalTypes.reservaCliente}
            />
            <div className={'info_container'}>
                <AlternatingTab selectedIndex={selectedTab} elements={[
                    { name: 'Reserva por dia',
                        onClick: () => selectTab(0)},
                    {
                        name: 'Minhas Reservas',
                        onClick: () => selectTab(1),
                    }, {
                        name: "Relatórios",
                        onClick: () => selectTab(2),
                    }]}/>
                <Snack/>
            </div>
            <CalendarAgendamentos isClient={true} />
        </div>
    );
};

export default ReservaDia;
