import * as React from 'react';
import InputText from '../../../inputText/input';
import ModalParent from '../../modal_parent/modal';
import Button from '../../../button/button'
import FinanceiroTitulosDAO, { Titulo } from '../../../../../DAO/financeiroTitulosDAO';
import {useDispatch, useSelector } from 'react-redux';
import { ActionsFn } from '../../../../../redux/actions/actions';
import Creatable from 'react-select/creatable'
import { RootState } from '../../../../../redux/storeTypes';
import "./ModalAddFinanceiro.sass";
import FinanceiroDAO, { Financeiro } from '../../../../../DAO/financeiroDAO';
import Select from 'react-select';
import clienteDAO from '../../../../../DAO/clienteDAO';
import Pagamento from "./components/Pagamento";
import logDAO from "../../../../../DAO/logDAO";
import axios from "axios"
import moment from 'moment';
import "moment/locale/pt-br";

interface ReqBody {
    email: string,
    name: string,
    date: string,
    credits: number,
    value: number,
}

interface propsType {
    show: boolean;
    close: () => void;
    type: 'ENTRADA' | 'SAIDA' | 'TITULO';
    editing?: boolean,
    editData?: {
        titulo: string,
        qntCreditos: number,
        valor: number,
    }
}


const transformFloatToStringReais = (floatNumber) => {
    let string = floatNumber.toFixed(2).toString();
    return "R$ " + string.replace(".", ",");
}

const ModalAddFinanceiro = (props : propsType) => {

    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    const { userLogged } = useSelector((state: RootState) => state.general);
    const { titulos, tituloSelected } = useSelector((state: RootState) => state.financeiro);
    const { profissionais } = useSelector((state: RootState) => state.profissionais);

    const [optionSelected, setOptionSelected] = React.useState<Titulo | null>(null);
    const [quantidade, setQuantidade] = React.useState<number>(0);
    const [menuOpenHelper, setMenuOpenHelper] = React.useState(false);
    const [tipoPgtoSelected, setTipoPgtoSelected] =
        React.useState<"transf" | "especie" | "debito" | "credito" | "cortesia">("transf");
    const optionsSaida = ["debito", "transf", "especie"];
    const optionsSaidaLabel = ["Débito", "Transferência Bancária", "Espécie"];

    const initialValor = {
        debito: 0,
        credito: 0,
        especie: 0,
        transf: 0,
        cortesia: 0,
        totalAcumulado: 0,
        saldo: 0,
    }

    const [valor, setValor] = React.useState(initialValor);

    const [pagadorSelected, setPagadorSelected] = React.useState<any>(null);

    const optionsSaidaSelect = optionsSaida.map((option, index) =>
        ({label: optionsSaidaLabel[index], value: option}));

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const form = e.target;
        switch (props.type) {
            case 'TITULO':
                const titulo : Titulo = {
                    titulo: form.titulo.value,
                    valor: Number(form.valor.value),
                    creditos: Number(form.creditos.value),
                }
                try {
                    if (props.editing) {
                        await FinanceiroTitulosDAO.edit(tituloSelected._id, titulo);
                    } else {
                        await FinanceiroTitulosDAO.create(titulo);
                    }
                    const titulos = await FinanceiroTitulosDAO.findAll();
                    dispatch(ActionsFn.setTitulos(titulos));
                } catch(e) {
                    alert("Erro");
                }
                break;
            case 'SAIDA':
                const saida : Financeiro = {
                    data: new Date(),
                    formaPgto: tipoPgtoSelected,
                    quantidade: 1,
                    pagadorRecebedor: form.recebedor.value,
                    valor: Number(form.valor.value),
                    tipo: "SAIDA",
                    titulo: form.titulo.value,
                }
                await FinanceiroDAO.create(saida);
                setLoading(false);
                break;
            case 'ENTRADA':
                if (optionSelected) {

                    /* Caso o Valor seja Inválido, diferente do valor proposto a pagar */

                    if (valor.totalAcumulado !== (optionSelected?.valor * quantidade)) {
                        alert("O valor a ser recebido e o valor informado não conferem");
                        setLoading(false);
                        return;
                    }

                    /* Cada tipo de transação gera um balanço no caixa */

                    setLoading(true);
                    const entrada = {
                        data: new Date(),
                        pagadorRecebedor: pagadorSelected.nome,
                        quantidade: Number(form.quantidade.value),
                        tipo: "ENTRADA",
                        titulo: optionSelected ? optionSelected?.titulo : '',
                    }
                    if (valor.debito > 0)
                        await FinanceiroDAO
                            .create({...entrada, valor: valor.debito  / entrada.quantidade, formaPgto: "debito", tipo: 'ENTRADA'});
                    if (valor.transf > 0)
                        await FinanceiroDAO
                            .create({...entrada, valor: valor.transf  / entrada.quantidade, formaPgto: 'transf', tipo: 'ENTRADA'});
                    if (valor.especie > 0)
                        await FinanceiroDAO
                            .create({...entrada, valor: valor.especie  / entrada.quantidade, formaPgto: 'especie', tipo:'ENTRADA'});
                    if (valor.cortesia > 0)
                        await FinanceiroDAO
                            .create({...entrada, valor: valor.cortesia / entrada.quantidade, formaPgto: 'cortesia', tipo: 'ENTRADA'});
                    if (valor.credito > 0)
                        await FinanceiroDAO
                            .create({...entrada, valor: valor.credito / entrada.quantidade, formaPgto: 'credito', tipo: 'ENTRADA'});

                    if (optionSelected?.creditos && pagadorSelected) {
                        if (optionSelected.creditos > 0) {
                            await clienteDAO.updateInc(pagadorSelected?._id,
                                {creditos: optionSelected.creditos * entrada.quantidade })
                            await logDAO.create({
                                usuario: userLogged,
                                log: `Adicionado ${Math.abs(optionSelected.creditos * entrada.quantidade)} créditos para ${pagadorSelected.nome}, Novo saldo: ${pagadorSelected.creditos + optionSelected.creditos * entrada.quantidade}`,
                                data_hora: new Date(),
                            });
                            let data : ReqBody = {
                                name: userLogged.nome,
                                value: valor.totalAcumulado,
                                credits: optionSelected.creditos * entrada.quantidade,
                                date: moment().format("DD [de] MMMM [de] YYYY HH:mm"),
                                email: pagadorSelected.email,
                            }
                            await axios.post("https://integra-website.vercel.app/api/send_mail", data);
                        }
                    }
                }
                break;
            default:
                break;
        }
        try {
            let transacoes = await FinanceiroDAO.findAll();
            dispatch(ActionsFn.setTransacoes(transacoes));
        } catch (e) {
            alert("Falha ao buscar as transações, por favor, atualize a página");
        }
        setOptionSelected(null);
        setValor(initialValor);
        setLoading(false);
        props.close();
    }

    const stringsHelper = {
        entrada: 'Entrada',
        saida: "Saída",
        titulo: 'Título',
    }

    React.useEffect(() => {
        console.log(tituloSelected);
    }, [tituloSelected]);

    React.useEffect(() => {
        setValor(initialValor);
        // setPagadorSelected(null);
    }, [props.show]);

    // @ts-ignore
    return (<ModalParent
    show={props.show}
    close={props.close}
    onSubmit={onSubmit}
    header={
        <header>
            <div>
                <h1>{!props.editing ? 'Adicionar' : 'Editar'} {stringsHelper[props.type.toLowerCase()]}</h1>
            </div>
            <div className={'close_container'} onClick={() => {
                props.close();
            }}>
                <i className={'fa fa-times'}/>
            </div>
        </header>
    }
    body={
        <div className='modal_add_financeiro'>
            {props.type === "TITULO" ?
                <div>
                    <div style={{display: 'flex'}}>
                        <InputText
                            key={props.editing ? tituloSelected.titulo : 'key'}
                            defaultValue={props.editing ? tituloSelected.titulo : ''}
                            style={{flex: 1, marginRight: '30px'}}
                            label={"Título"}
                            name={'titulo'}/>
                        <InputText
                            key={props.editing ? tituloSelected.valor : 'key1'}
                            defaultValue={props.editing ? "" + tituloSelected.valor : ''}
                            style={{flex: 1}}
                            label={"Valor"}
                            decoration={'R$'}
                            name={"valor"}
                            type="number"
                            step={"0.01"}/>
                    </div>
                    <div>
                        <InputText
                            key={props.editing ? tituloSelected.creditos : 'key2'}
                            defaultValue={props.editing ? tituloSelected.creditos : ''}
                            style={{flex: 1}}
                            label={"Quantidade de Créditos"}
                            type={"number"}
                            step={"1"}
                            name={"creditos"}
                            placeholder={"Informe 0 caso não tenha relação com adição de créditos"}
                        />
                    </div>
                </div>
                :
                <div>
                    {props.type === "SAIDA" ?
                        <InputText
                            name={"recebedor"}
                            label={"Recebedor"}
                            required={true}
                            style={{marginRight: '10px', flex: 1}}/> : <></>}
                    <div style={{width: '100%'}}>
                        {props.type === "ENTRADA" ?
                            <React.Fragment>
                                <div className={'select_container'} style={{flex: 1, marginBottom: '10px'}}>
                                    <label>Pagador</label>
                                    <Select
                                        options={profissionais.map(
                                            profissional =>
                                                ({label: profissional.nome, value: profissional}))}
                                        onChange={(e : any) => setPagadorSelected(e.value)}/>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div className={'select_container'} style={{flex: 1, marginRight: '30px'}}>
                                        <label>Título</label>
                                        <Creatable
                                            className={"select"}
                                            name={"titulo_select"}
                                            onChange={(e : any) =>
                                                setOptionSelected(e.value)}
                                            options={
                                                titulos.map((titulo) =>
                                                    ({label: titulo.titulo, value: titulo}))}
                                        />
                                    </div>
                                    <div>
                                        <InputText name={"quantidade"} label={"Quantidade"} type={"number"} step={'1'}
                                                   onChange={e => setQuantidade(Number(e.target.value))}/>
                                    </div>
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <InputText
                                    name={"titulo"}
                                    label={"Título"}
                                    style={{marginRight: '10px', flex: 1}}/>
                            </React.Fragment>
                        }
                        {props.type === "ENTRADA" ? <></>
                            : <InputText label="Valor" type="number" step="0.01" name="valor" decoration="R$"/>}
                    </div>
                    {
                        props.type === "ENTRADA" ?
                            <div>
                                <h2 style={{margin: 0}}>Valor Total:</h2>
                                <h1 style={{
                                    margin: 0,
                                    marginBottom: 5
                                }}>{(optionSelected) ? transformFloatToStringReais(optionSelected?.valor * quantidade) : "R$ 0,00"}</h1>
                                <Pagamento
                                    setValor={(valor) => {
                                        console.log(valor);
                                        setValor(valor)
                                    }}
                                    valorTotal={optionSelected ? optionSelected?.valor * quantidade : 0}/>
                            </div>
                            : <></>
                    }
                    {props.type === 'SAIDA' ?
                        <div className='select_container' style={
                            {marginBottom: menuOpenHelper ? 130 : 20}
                        }>
                        <label>Forma de Pagamento</label>
                        <Select options={optionsSaidaSelect}
                                onMenuOpen={() => setMenuOpenHelper(true)}
                                onMenuClose={() => setMenuOpenHelper(false)}
                                onChange={(e: any) => {
                                    setTipoPgtoSelected(e.value);
                                }}/>
                    </div>
                        : <></> }
                </div>}

        </div>
    }
    footer={
        <div className={'footer'}>
            <Button type={'submit'} loading={loading} text={'Confirmar'}/>
        </div>
    }/>)
}

export default ModalAddFinanceiro;
