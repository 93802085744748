import React from "react";
import ModalParent from "../../modal_parent/modal";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/pt-br";
import "./ModalSelectDay.sass";
import moment from "moment";

const ModalSelectDay: React.FC<{
  onChangeDay: (v: null | Date) => void;
  show: boolean;
  close: () => void;
  isClient?: boolean;
}> = (props) => {
  // @ts-ignore
    return (
    <ModalParent
      show={props.show}
      header={
        <header>
          <div>
            <h1>Alternar Data</h1>
          </div>
          <div className={"close_container"} onClick={props.close}>
            <i className={"fa fa-times"} />
          </div>
        </header>
      }
      body={
        <div className={"body_modal_select_day"}>
            {/*@ts-ignore*/}
          <DayPicker
            localeUtils={MomentLocaleUtils}
            locale={"pt-br"}
            disabledDays={
              props.isClient
                ? [
                    {
                      from: moment().add(15, "days").toDate(),
                      to: moment().add(10, "years").toDate(),
                    },
                    {
                      from: moment().subtract(10, "years").toDate(),
                      to: moment().subtract(1, "day").toDate(),
                    },
                  ]
                : []
            }
            numberOfMonths={2}
            onDayClick={(day, { selected }) => {
              if (props.isClient) {
                if (
                  moment(day).isBetween(
                    moment().subtract(1, 'day').toDate(),
                    moment().add(15, "days").toDate(),
                    "days"
                  )
                ) {
                  props.onChangeDay(selected ? null : day);
                }
              } else {
                props.onChangeDay(selected ? null : day);
              }
            }}
          />
        </div>
      }
    />
  );
};

export default ModalSelectDay;
